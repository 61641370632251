import React from 'react';
import { Datagrid } from 'react-admin';
import { PropTypes } from 'prop-types';
import { withStyles } from '@material-ui/core';

import styles from './styles';

function CustomDatagrid({ classes, ...props }) {
  return <Datagrid classes={classes} {...props} />;
}

CustomDatagrid.propTypes = {
  classes: PropTypes.shape()
};

export default withStyles(styles)(CustomDatagrid);
