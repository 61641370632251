import { ALTO } from '~constants/colors';

const styles = {
  borderBottom: {
    borderBottom: `2px solid ${ALTO}`,
    marginBottom: '50px',
    paddingBottom: '50px'
  },
  inlineChildren: {
    display: 'flex',
    width: '30%'
  },
  epigraph: {
    'word-break': 'break-all'
  }
};

export default styles;
