import React, {Component, Fragment} from 'react';
import { 
  List, 
  TextField, 
  translate, 
  Filter,
  TextInput,
  SelectInput,
  Pagination,
  downloadCSV,
  BulkDeleteButton,
  AutocompleteArrayInput
} from 'react-admin';
import { parse } from 'json2csv';

import CustomDatagrid from '~components/CustomDatagrid';
import ProjectsCharts from '../components/ProjectsCharts';
import { statusChoices, infraestructureTypeChoices } from '~constants/fieldGroups';
import { CountriesService } from '~config/services/countries.service';
import DateBetweenInput from '../components/DateBetweenInput';
import NumberBetweenInput from '../components/NumberBetweenInput';
import { financingChoices } from '../../../../constants/fieldGroups';

class ProjectFilter extends Component {

  state = {
    countries: []
  }
  
  constructor() {
    super();
    const cs = new CountriesService();
    cs.get_la().then(r => this.setState({countries: r.data}));
  }

  render() {
    return (
      <Filter {...this.props}>
        <TextInput source="id" />
        <TextInput source="backTitle" />

        <AutocompleteArrayInput label="Paises" source="countries" choices={this.state.countries} sorteable={false} />
        <SelectInput source="status" choices={statusChoices} />
        <SelectInput source="infrastructure" choices={infraestructureTypeChoices} />

        <DateBetweenInput source="createdDate"/>

        <DateBetweenInput source="updatedDate"/>

        <NumberBetweenInput source="totalInvestment"/>
      </Filter>
    );
  }

}

const PostPagination = props => <Pagination rowsPerPageOptions={[50, 100]} {...props} />

function formatProject(p) {
  delete p.starredAt;
  delete p.kmlSimplified;
  delete p.dynamicMapZoom; 
  delete p.staticMapZoom;
  delete p.dynamicCenter; 
  delete p.staticCenter;
  delete p.projectType;
  delete p.sources;
  delete p.editable;
  delete p.createdDate;
  delete p.updatedDate;
  p.content = p.content.replace(/[\r\n]+/gm, "");
  p.financing = financingChoices.filter(f=>f.id == p.financing)[0].name;
}

const exporter = proj => {
  proj.forEach(p => {
    formatProject(p);
  });
  let formattedCsv = parse(proj);
  downloadCSV(formattedCsv, 'Proyectos');
};

const BulkActions = props => (
  <Fragment>
      <BulkDeleteButton undoable={false} {...props} />
  </Fragment>
);

function ProjectList(props) {
  return (
    
    <>

      <ProjectsCharts/>

      <List {...props} bulkActionButtons={<BulkActions/>} exporter={exporter} filters={<ProjectFilter/>} pagination={<PostPagination/>} perPage={50} >
        <CustomDatagrid rowClick="show">
          <TextField source="id" />
          <TextField source="title" />
          <TextField source="countries" />
          <TextField source="status" />
          <TextField source="infrastructure" />
          <TextField source="createdDate"/>
          <TextField source="updatedDate"/>
          <TextField style={{textAlign: 'right'}} source="totalInvestment"/>
        </CustomDatagrid>
      </List>
 
    </>

  );
}

export default translate(ProjectList);
