import React, {Component} from 'react';
import {
  SimpleForm,
  TextInput,
  LongTextInput,
  SelectInput,
  translate,
  ImageField,
  ImageInput,
  FileInput,
  FormDataConsumer,
  BooleanInput,
  ArrayInput,
  SimpleFormIterator,
  NumberInput,
  REDUX_FORM_NAME
} from 'react-admin';
import { change } from 'redux-form';
import { connect } from 'react-redux';
import RichTextInput from 'ra-input-rich-text';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import {
  topicChoices,
  infraestructureChoices,
  contentTypeChoices,
  countryChoices,
  textArticleTypes,
  articleTypes,
  sortByChoices
} from '~constants/fieldGroups';
import { DATE_FORMAT_PLACEHOLDER } from '~constants/dates';
import { isRequired, email, isValidDate } from '~utils/inputValidations';
import { normalizeDate } from '~utils/normalize';
import FieldGroup from '~components/FieldGroup';
import { CountriesService } from '~config/services/countries.service';
import CustomAutocompleteInput from '../../../components/CustomAutocompleteInput';
import ArticleTopicsInput from '../../../components/ArticleTopicsInput';
import { TopicsService } from '~config/services/topics.service';
import { SlugsService } from '~config/services/slugs.service';

import styles from './styles';

const H2 = 2;
const H3 = 3;
const NORMAL = false;
const COLORS = {
  'mine-shaft': '#3E3E3E',
  gray: '#8F8F8F',
  silver: '#BEBEBE',
  'wild-sand': '#F0F0F0',
  alabaster: '#F9F9F9',
  'mountain-meadow': '#18BC5C',
  'mountain-meadow-two': '#28DE73',
  tara: '#E2F6EA',
  black: '#000',
  white: '#FFF'
};

class ArticleForm extends Component {

  state = { 
    cuntries: [],
    topics: [],
    slugs: []
  };

  constructor() {
    super();

    const cs = new CountriesService();
    cs.get().then(r => {
      let data = r.data;
      data.sort((a, b) => (a.name > b.name) ? 1 : -1);
      this.setState({ countries: data })
    });

    const ts = new TopicsService();
    ts.get().then(r => {
      this.setState({ topics:r.data });
    });

    const ss = new SlugsService();
    ss.get_from_article().then(r => {
      this.setState({ slugs: r.data });
    });
  }

  handleTypeChange = (event, value, previousValue) => {
    if (!(textArticleTypes.includes(previousValue) && textArticleTypes.includes(value))) {
      this.props.change(REDUX_FORM_NAME, 'content', null);
    }
  }

  validateArticleForm = (values) => {
    const errors = {};
    if(values['sortBy'] == 'material_date' 
      && (values['materialDate'] == null || values['materialDate'] == "")) {
      errors.materialDate = 'Debe ingresar una fecha';
    }

    if(values['slug'] && this.state.slugs?.indexOf(values.slug) > -1) {
      errors.slug = 'Este slug ya se encuentra en uso';
    }

    if(!values['image'] && values['articleType'] != 'video') {
      errors.image = 'Este campo es obligatorio';
    }

    if(values['image'] && values['image'].rawFile && values['image'].rawFile.size > 15000000) {
      errors.image = 'El tamaño de la imagen no puede ser mayor a 15MB';
    }

    if(!values['infrastructure'] || values['infrastructure'].length == 0) {
      errors.infrastructure = 'Debe seleccionar por lo menos un tipo de infraestructura';
    }

    if(!values['countries'] || values['countries'].length == 0) {
      errors.countries = 'Debe seleccionar por lo menos un país';
    }

    return errors
  };


  render() {
    return (
      <SimpleForm {...this.props} validate={this.validateArticleForm}>
        {this.children}
        <FieldGroup label="fieldGroups.contentInformation" formClassName={this.props.classes.borderBottom}>
          <TextInput source="title" validate={isRequired} label="resources.articles.formFields.title" />
          <TextInput source="slug"/>
          <LongTextInput source="description" validate={isRequired} label="resources.articles.formFields.description" />
          
          <FormDataConsumer>
            {({formData}) => (
              <CustomAutocompleteInput
                choices={infraestructureChoices}
                value={formData.infrastructure}
                change={this.props.change}
                title={'Tipos de Infraestructura *'}
                source={'infrastructure'}
                error={'Debe seleccionar por lo menos un tipo de infraestructura'}
                required={true}/>
            )}
          </FormDataConsumer>

          <div>
            <FormDataConsumer>
              {({formData}) => (
                <CustomAutocompleteInput
                  choices={this.state.countries}
                  value={formData?.countries}
                  change={this.props.change}
                  title={'Paises *'}
                  source={'countries'}
                  required={true}/>
              )}
            </FormDataConsumer>
          </div>

          <FormDataConsumer>
            {({formData}) => (
              <ArticleTopicsInput 
                value={formData?.topics} 
                choices={this.state.topics} 
                change={this.props.change}
                source={'topics'}/>
            )}
          </FormDataConsumer>

          <FormDataConsumer>
            {({formData}) => {
              let label = formData['articleType'] != 'video' ? 'image' : 'imageNotRequired';
              return (
                <ImageInput
                  source="image"
                  accept="image/*"
                  placeholder={<p>{this.props.translate('fieldGroups.dropImage')}</p>}
                  label={"resources.articles.formFields."+label}
                >
                  <ImageField source="file" title="title" />
                </ImageInput>
              )
            }}
          </FormDataConsumer>

          
          <TextInput source="epigraph" label="common.image.epigraph" />
        </FieldGroup>
        <FieldGroup formClassName={this.props.classes.borderBottom} label="fieldGroups.mainContent">
          <SelectInput
            source="articleType"
            choices={contentTypeChoices}
            validate={isRequired}
            onChange={this.handleTypeChange}
            label="resources.articles.formFields.articleType"
          />
          <FormDataConsumer>
            {({ formData, ...rest }) =>
              textArticleTypes.includes(formData.articleType) && (
                <RichTextInput
                  source="content"
                  validate={isRequired}
                  toolbar={[
                    [{ header: [H2, H3, NORMAL] }],
                    ['bold', 'italic', 'underline'],
                    [{ size: ['small', false, 'large'] }],
                    [{ color: Object.values(COLORS) }, { background: Object.values(COLORS) }],
                    [{ list: 'ordered' }, { list: 'bullet' }],
                    ['link', 'image']
                  ]}
                  {...rest}
                />
              )
            }
          </FormDataConsumer>
          <FormDataConsumer>
            {({ formData, ...rest }) =>
              formData.articleType === articleTypes.video && (
                <TextInput source="videoUrl" validate={isRequired}/>
              )
            }
          </FormDataConsumer>
          <FormDataConsumer>
            {({ formData, ...rest }) =>
              formData.articleType === articleTypes.infographic && (
                <>
                  <ImageInput
                    source="content"
                    accept="image/*"
                    placeholder={<p>{this.props.translate('fieldGroups.dropImage')}</p>}
                    validate={isRequired}
                    label="resources.articles.types.infographic"
                    {...rest}
                  >
                    <ImageField source="file" title="title" />
                  </ImageInput>
                  {formData.content && !formData.content.rawFile && (
                    <ImageField source="content" title="title" {...rest} />
                  )}
                </>
              )
            }
          </FormDataConsumer>
        </FieldGroup>

        <FieldGroup label="fieldGroups.authorInfo">
          <TextInput source="author.firstName" validate={isRequired} label="resources.articles.formFields.firstName" />
          <TextInput source="author.lastName" />
          <TextInput source="author.email" validate={email} />
          <TextInput source="author.institution" />
          <TextInput source="author.role" />
        </FieldGroup>
        <FieldGroup label="fieldGroups.additionalInformation">
          
          <SelectInput 
            source="sortBy"
            choices={sortByChoices} 
            validate={isRequired} 
            defaultValue={'material_date'} />

          <FormDataConsumer>
            {({formData}) => {
              let label = formData['sortBy'] != 'material_date' ? 'materialDate' : 'materialDateRequired';
              return (
                <TextInput
                  source="materialDate"
                  label="fieldGroups.date"
                  normalize={normalizeDate}
                  placeholder={DATE_FORMAT_PLACEHOLDER}
                  validate={isValidDate}
                  label={"resources.articles.fields."+label}
                />      
              )
            }}
          </FormDataConsumer>

          <TextInput source="originalSource" label="fieldGroups.originalSource" />
          <TextInput source="sourceUrl" label="fieldGroups.sourceUrl" />
        </FieldGroup>

        <FieldGroup label="fieldGroups.contentConfiguration">
          <BooleanInput source="starredAt" label="fieldGroups.starred" />
        </FieldGroup>

      </SimpleForm>
    );
  }

}

ArticleForm.propTypes = {
  classes: PropTypes.shape({
    borderBottom: PropTypes.string.isRequired
  }),
  translate: PropTypes.func
};

const mapDispatchToProps = {change};

export default (withStyles(styles)(connect(state => ({}),mapDispatchToProps)(translate(ArticleForm))));
