const styles = {
  fieldContainer: {
    display: 'flex'
  },
  infoContainer: {
    width: '30%'
  },
  h2: {
    fontSize: '1.5em'
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1
  }
};

export default styles;
