import React from 'react';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { MenuItemLink, getResources } from 'react-admin';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import cx from 'classnames';

import { styles } from './styles';
import style from './styles.module.scss';

function Section({ classes, ...props }) {
  function handleClick() {
    props.action(props.sectionName);
  }

  return (
    <div className={style.group}>
      <div onClick={handleClick} className={style.container}>
        {props.items.map(item => (
          <MenuItemLink key={item} classes={classes} to={`/${item}`} primaryText={t(`customMenu:${item}`)} />
        ))}
      </div>
      <span
        className={cx(style.lineSeparator, style.category, {
          [style.selected]: props.active
        })}
      >
        {t(`customMenu:${props.sectionName}`)}
      </span>
    </div>
  );
}

Section.propTypes = {
  classes: PropTypes.shape({
    active: PropTypes.shape({
      color: PropTypes.string.isRequired,
      fontWeight: PropTypes.string.isRequired
    }).isRequired,
    root: PropTypes.shape({
      color: PropTypes.string.isRequired,
      fontSize: PropTypes.string.isRequired,
      fontWeight: PropTypes.string.isRequired,
      height: PropTypes.string.isRequired,
      lineHeight: PropTypes.string.isRequired,
      marginLeft: PropTypes.string.isRequired
    }).isRequired
  }).isRequired,
  action: PropTypes.func,
  active: PropTypes.bool,
  items: PropTypes.arrayOf(PropTypes.string),
  sectionName: PropTypes.string
};

const mapStateToProps = state => ({
  resources: getResources(state)
});

export default withRouter(connect(mapStateToProps)(withStyles(styles)(Section)));
