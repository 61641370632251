import React from 'react';
import { Create } from 'react-admin';

import ProjectForm from '../IirsaProjectForm';

function IirsaProjectCreate(props) {
  return (
    <Create {...props}>
      <ProjectForm />
    </Create>
  );
}

export default IirsaProjectCreate;
