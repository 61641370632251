const styles = {
    toolbar: {
        width: 'fit-content',
        float: 'right'
    },
    mostrarSvg: {
        width: '16px',
        marginRight: '5px',
        fill: '#3f50b6'
    }
}

export default styles;
