import { CREATE, UPDATE } from 'react-admin';
import { create } from 'apisauce';

import { dataURItoBlob } from '~utils/images';
import { RESOURCES } from '~constants/config';
import { articleTypes } from '~constants/fieldGroups';
import api from '~config/api';

const uploadApi = create({ baseURL: '' });

const IMAGE_TYPES = {
  [RESOURCES.articles]: 'article',
  [RESOURCES.events]: 'event'
};

const uploadFile = ({ name, data, type, resource }) =>
  api.get(`/backoffice/signed_url?file_name=${name}&type=${IMAGE_TYPES[resource]}`).then(response => {
    if (!response.ok) {
      throw Error('errors.fileUpload');
    }
    const headers = {
      'Content-Type': type
    };
    return uploadApi.put(response.data.url, data, { headers }).then(uploadResponse => {
      if (!uploadResponse.ok) {
        throw Error('errors.fileUpload');
      }
      return response.data.url.split('?')[0];
    });
  });

const uploadFromFile = (file, resource) =>
  uploadFile({ name: file.rawFile.name, data: file.rawFile, type: file.rawFile.type, resource });

const uploadImageFromBase64 = (src, resource, index) => {
  const blob = dataURItoBlob(src);
  return uploadFile({
    name: `content_image_${index}.${blob.type.split('/')[1]}`,
    data: blob,
    type: blob.type,
    resource
  });
};

const uploadContentImages = async (params, resource) => {
  const contentDocument = new DOMParser().parseFromString(params.data.content, 'text/html');
  await Promise.all(
    [...contentDocument.images]
      .filter(image => image.src.includes('base64'))
      .map(async (image, index) => {
        const imageUrl = await uploadImageFromBase64(image.src, resource, index);
        image.setAttribute('src', imageUrl);
      })
  );
  return contentDocument.body.innerHTML;
};

const uploadArticleFiles = async (params, newData, resource) => {
  switch (params.data.articleType) {
    case articleTypes.infographic:
      if (params.data.content && params.data.content.rawFile) {
        newData.content = await uploadFromFile(params.data.content, resource);
      }
      break;
    default:
      newData.content = await uploadContentImages(params, resource);
  }
};

const addImageUpload = requestHandler => async (type, resource, params) => {
  if ([UPDATE, CREATE].includes(type) && [RESOURCES.articles, RESOURCES.events].includes(resource)) {
    const newData = {};

    if (resource === RESOURCES.articles) {
      await uploadArticleFiles(params, newData, resource);
    }

    return requestHandler(type, resource, {
      ...params,
      data: {
        ...params.data,
        ...newData
      }
    });
  }
  // for other request types and resources, fall back to the default request handler
  return requestHandler(type, resource, params);
};

export default addImageUpload;
