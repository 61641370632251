import React, { Component } from 'react';
import togeojson from "@mapbox/togeojson";
import { compose, withProps } from 'recompose';
import { withScriptjs, withGoogleMap, GoogleMap } from 'react-google-maps';
import cx from 'classnames';
import { Marker, Polyline, Polygon } from 'react-google-maps';
import { REDUX_FORM_NAME } from 'react-admin'

import { GOOGLE_MAPS_API_KEY, DEFAULT_ZOOM, CONTROL_SIZE } from './constants';
import styles from './styles.module.scss';
import mapStyles from './map-styles';
import genericIcon from './assets/generic_icon.svg';
import boundaryIcon from './assets/boundary_icon.svg';
import telecommunicationsIcon from './assets/telecommunications_icon.svg';
import energyIcon from './assets/energy_icon.svg';
import transportIcon from './assets/transport_icon.svg';

class DynamicMap extends Component {

    state = { 
        polylines: [],
        markers: [],
        polygons: []
    };

    mapRef;
    latField;
    lngField;
    zoomField;

    ICONS = {
        ['generic']: genericIcon,
        ['boundary']: boundaryIcon,
        ['telecommunications']: telecommunicationsIcon,
        ['energy']: energyIcon,
        ['transport']: transportIcon,
    }
    
    constructor(props) {
        super(props);
        if(props.kml) {
            const gjsonPromise = this.getGeoJSON(props.kml);
            this.setPolylines(gjsonPromise);
        }
        if(props.dynamic) {
            this.latField = 'dynamic_center_latitude';
            this.lngField = 'dynamic_center_longitude';
            this.zoomField = 'dynamic_map_zoom';
        } else {
            this.latField = 'static_center_latitude';
            this.lngField = 'static_center_longitude';
            this.zoomField = 'static_map_zoom';
        }
    }

    componentWillReceiveProps(props) {
        if(props.kml) {
            const gjsonPromise = this.getGeoJSON(props.kml);
            this.setPolylines(gjsonPromise);
        }
    }

    getGeoJSON = async (kml) => {
        let path = null;
        if(kml['rawFile']) {
            path = kml.rawFile.preview;
        } else {
            path = kml;
        }
        const response = await fetch(path);
        const kmlText = await response.text();
        let domObj = (new DOMParser()).parseFromString(kmlText, 'text/xml')
        let gjson = togeojson.kml(domObj);
        return gjson;
    }

    setPolylines = (gjsonPromise) => {
        const polylines = [];
        gjsonPromise.then(gjson => {
            gjson.features.forEach(f => {
                if(f.geometry.type == 'LineString') {
                    const line = [];
                    f.geometry.coordinates.forEach(c => {
                        line.push({lng: c[0], lat: c[1]});
                    });
                    polylines.push(line);
                } else if (f.geometry.hasOwnProperty('geometries')) {
                    f.geometry.geometries.forEach(g => {
                        if(g.type == 'LineString') {
                            const line = [];
                            g.coordinates.forEach(c => {
                                line.push({lng: c[0], lat: c[1]});
                            });
                            polylines.push(line);
                        }
                    });
                }
            });
            this.setState({polylines: polylines});
            this.setMarkers(gjsonPromise);
        });
    };
    
    setMarkers = (gjsonPromise) => {
        const markers = [];
        gjsonPromise.then(gjson => {
            gjson.features.forEach(f => {
                if(f.geometry.type == 'Point') {
                    const coor = f.geometry.coordinates;
                    markers.push({lng: coor[0], lat: coor[1]});
                } else if(f.geometry.hasOwnProperty('geometries')) {
                    f.geometry.geometries.forEach(g => {
                        if(g.type == 'Point') {
                            const coor = g.coordinates;
                            markers.push({lng: coor[0], lat: coor[1]});
                        }
                    });
                }
            });
            if(markers.length == 0 && this.state.polylines[0]) {
                let firstLine = this.state.polylines[0][0];
                markers.push(firstLine);
            }
            this.setState({markers: markers});
            this.setPolygons(gjsonPromise);
        });
    }

    setPolygons = (gjsonPromise) => {
        const polygons = [];
        gjsonPromise.then(gjson => {
            gjson.features.forEach(f => {
                if(f.geometry.type == 'Polygon') {
                    const pol = [];
                    f.geometry.coordinates[0].forEach(c => {
                        pol.push({lng: c[0], lat: c[1]});
                    });
                    polygons.push(pol);
                } else if(f.geometry.hasOwnProperty('geometries')) {
                    f.geometry.geometries.forEach(g => {
                        if(g.type == 'Polygon') {
                            const pol = [];
                            f.geometry.coordinates[0].forEach(c => {
                                pol.push({lng: c[0], lat: c[1]});
                            });
                            polygons.push(pol);
                        }
                    });
                }
            });
            this.setState({polygons: polygons});
        })
    }

    renderPolylines = (lines) => {
        return (
          <Polyline
            path={lines}
            options={{
                strokeColor: '#FB552E',
                strokeWeight: 2
            }}
          />
        );
    };

    renderMarkers = (marker) => {
        let icon= this.ICONS.generic;
        return (
            <Marker 
                position={marker} 
                icon={icon}
            />
        )
    }

    renderPolygons = (polygon) => {
        return (
            <Polygon
                path={polygon}
                options={{
                    strokeColor: '#FB552E',
                    strokeWeight: 2,
                    fillColor: '#FB552E',
                    fillOpacity: 0.3
                }}
            />
        )
    }

    setField = (field, value) => {
        if(!this.props.modal) {
            this.props.change(REDUX_FORM_NAME, field, value);
        }
    }

    setCenter = () => {
        if(this.mapRef && !this.props.modal) {
            let center = this.mapRef.getCenter();
            this.setField(this.latField, center.lat());
            this.setField(this.lngField, center.lng());
        }
    }

    setZoom = () => {
        if(this.mapRef) {
            this.setField(this.zoomField, this.mapRef.getZoom())
        }
    }

    render() {
        const { zoom } = this.props;
        const defaultCenter = this.props.centerLat && this.props.centerLng ? 
            { lat: this.props.centerLat, lng: this.props.centerLng }
            : 
            { lat: -26, lng: -66 };
        return (
            <GoogleMap
                ref={(mapRef) => this.mapRef = mapRef}
                onDragEnd={this.setCenter}
                onZoomChanged={this.setZoom}
                zoom={zoom || DEFAULT_ZOOM}
                center={defaultCenter}
                defaultOptions={{
                    styles: mapStyles,
                    streetViewControl: false,
                    scaleControl: false,
                    mapTypeControl: false,
                    panControl: false,
                    rotateControl: false,
                    fullscreenControl: false,
                    controlSize: CONTROL_SIZE
                }}
            >
                {this.state.polylines.length > 0 && this.state.polylines.map(this.renderPolylines)}
                {this.state.markers.length > 0 && this.state.markers.map(this.renderMarkers)}
                {this.state.polygons.length > 0 && this.state.polygons.map(this.renderPolygons)}
            </GoogleMap>
        );
      }


}

export default compose(
    withProps(ownerProps => ({
      googleMapURL: `https://maps.googleapis.com/maps/api/js?v=3.exp&key=${GOOGLE_MAPS_API_KEY}&libraries=geometry,drawing,places`,
      loadingElement: <div className={styles.loading} />,
      containerElement: (
        <div
            className={cx('relative', ownerProps.modal ? styles.mapContainer : styles.container)}
        />
      ),
      mapElement: <div className={styles.map} />
    })),
    withScriptjs,
    withGoogleMap
  )(DynamicMap);
