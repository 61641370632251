import React, {Component} from 'react';
import { SimpleForm, TextInput, translate } from 'react-admin';
import FieldGroup from '~components/FieldGroup';

class TopicForm extends Component {

    render() {
        return (
            <SimpleForm {...this.props}>
                <FieldGroup label="fieldGroups.topic">
                    <TextInput source="title"/>
                </FieldGroup>
            </SimpleForm>
        )
    }
    
}

export default translate(TopicForm);
