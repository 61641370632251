import React from 'react';
import { translate, SimpleShowLayout } from 'react-admin';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import styles from './styles';

// Disabled to allow passing only the necessary ...props
// eslint-disable-next-line no-unused-vars
function FieldGroup({ translate: t, label, children, classes, className, formClassName, show, titleColumn=true, flex=true, ...props }) {
  const childrenWithProps = React.Children.map(children, child => React.cloneElement(child, props));
  return (
    <div className={titleColumn ? classes.fieldContainer : ""}>
      <div className={titleColumn ? classes.infoContainer : classes.h2}>
        <h2>{t(label)}</h2>
      </div>
      <div className={flex ? classes.contentContainer : ""}>
        {show ? (
          <SimpleShowLayout {...props}> {childrenWithProps} </SimpleShowLayout>
        ) : (
          <> {childrenWithProps} </>
        )}
      </div>
    </div>
  );
}

FieldGroup.propTypes = {
  classes: PropTypes.shape({
    contentContainer: PropTypes.string.isRequired,
    fieldContainer: PropTypes.string.isRequired,
    infoContainer: PropTypes.string.isRequired
  }).isRequired,
  className: PropTypes.string.isRequired,
  formClassName: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  show: PropTypes.bool,
  translate: PropTypes.func
};

export default withStyles(styles)(translate(FieldGroup));
