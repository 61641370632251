import i18next from 'i18next';

i18next.addResources('es', 'customMenu', {
  statistics: 'Estadística',
  searchsSummary: 'Detalle de Búsquedas',
  searchs: 'Búsquedas',
  sections: 'Secciones',
  events: 'Eventos',
  articles: 'Contenidos',
  projects: 'Proyectos',
  iirsaProjects: 'Portfolio de Proyectos',
  settings: 'Configuración',
  topics: 'Temas'
});
