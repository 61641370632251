import React from 'react';
import PropTypes from 'prop-types';
import { FunctionField, SimpleShowLayout } from 'react-admin';
import moment from 'moment';
import { withStyles } from '@material-ui/core/styles';

import { DATE_FORMAT } from '~constants/dates';

import styles from './styles';

function CustomDateField({ classes, show, record = {}, source, ...props }) {
  
  function renderField() {
    let date = record[source];
    if(source == 'materialDate' && date) {
      date = getMaterialDate(date);
    }
    return record[source] && moment.utc(date).format(DATE_FORMAT);
  }

  const field = <FunctionField source="startMoment" render={renderField} {...props} />;

  if (show) {
    return (
      <SimpleShowLayout {...props} className={classes.container}>
        {field}
      </SimpleShowLayout>
    );
  }

  return field;
}

function getMaterialDate(date) {
  let slice = date.split('-');
  let month = slice[0];
  let day = slice[1];
  let year = slice[2];
  return day + '-' + month + '-' + year;
}

CustomDateField.propTypes = {
  classes: PropTypes.shape({
    container: PropTypes.string
  }),
  record: PropTypes.shape({
    starredAt: PropTypes.bool
  }),
  show: PropTypes.bool,
  source: PropTypes.string
};

export default withStyles(styles)(CustomDateField);
