import { gray } from '~utils/colors';

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'row'
  },
  button: {
    color: gray,
    opacity: 0.5,
    '&:hover': {
      opacity: 1,
      backgroundColor: 'transparent'
    }
  }
};

export default styles;
