export const requiredHeaders = ['access-token', 'token-type', 'client', 'expiry', 'uid'];
export const SEARCHS_SUMMARY = 'searchsSummary';
export const RESOURCES = {
  articles: 'articles',
  events: 'events',
  searchs: 'search_logs',
  projects: 'projects',
  iirsaProjects: 'iirsa_projects',
  topics: 'topics',
  [SEARCHS_SUMMARY]: 'search_logs/summary'
};

export const defaultListProps = { bulkActionButtons: false };
