import React, { Component } from 'react';
import { NumberInput } from 'react-admin';
import { addField } from 'ra-core';

class NumberBetweenInput extends Component {
  render() {
    const styles = {
      row: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        justifyContent: 'space-between',
      },
    };

    const {resource, source } = this.props

    return (
     <span style={styles.row}>
        <NumberInput 
          source={`${source}.${source}_min`}
          label={`resources.${resource}.fields.${source}_min`}
        />
        &nbsp;
        <NumberInput 
          source={`${source}.${source}_max`}
          label={`resources.${resource}.fields.${source}_max`}
        />
      </span>
    );
  }
}

NumberBetweenInput.defaultProps = {
  addLabel: true,
  label: 'totalInvestment',
};

export default addField(NumberBetweenInput);
