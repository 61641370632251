/* eslint-disable no-lonely-if */
/* eslint-disable react/prop-types */
/* eslint-disable consistent-return */
/* eslint-disable no-prototype-builtins */
/* eslint-disable eqeqeq */
/* eslint-disable react/jsx-pascal-case */
/* eslint-disable react-native/no-inline-styles */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/jsx-handler-names */
/* eslint-disable react/forbid-dom-props */
/* eslint-disable react/jsx-max-depth */
import React, { Component } from 'react';
import { REDUX_FORM_NAME } from 'react-admin';
import RLDD from 'react-list-drag-and-drop/lib/RLDD';
import Modal from 'react-modal';
import Button from '@material-ui/core/Button';
import Dropzone from 'react-dropzone';

import { Document, Image, Link } from './classes';
import styles from './styles';

class GalleryItemsInput extends Component {
  state = {
    items: [],
    docModalOpen: false,
    docFileError: false,
    docFile: null,
    imgModalOpen: false,
    imgFileError: false,
    imgFile: null,
    linkModalOpne: false,
    linkTitleError: false,
    linkError: false,
    editing: null
  };

  type = 'document';
  docNameInput;
  linkTitleInput;
  linkInput;
  parsedItems;
  originalLength;

  componentWillReceiveProps(props) {
    if (this.originalLength != props.value?.length) {
      this.setItemsFromProps(props);
    }
    if (!this.originalLength) {
      this.originalLength = props.value?.length;
    }
  }

  setItemsFromProps = props => {
    if (this.state.items.length == 0) {
      const items = props.value?.filter(i => i == i);
      let parsedItems = [];
      if (items) {
        items.forEach(it => {
          if (it.type == 'Document') {
            const newDoc = new Document();
            newDoc.populate(it);
            parsedItems.push(newDoc);
          } else if (it.type == 'Image') {
            const newImg = new Image();
            newImg.populate(it);
            parsedItems.push(newImg);
          } else if (it.type == 'Link') {
            const newLink = new Link();
            newLink.populate(it);
            parsedItems.push(newLink);
          }
        });
      }
      parsedItems = parsedItems.sort((a, b) => b.order - a.order);
      parsedItems.reverse();
      this.parsedItems = parsedItems;
      this.setState({ items: parsedItems });
      this.props.change(REDUX_FORM_NAME, 'gallery_items', parsedItems);
    }
  };

  updateType = ev => {
    this.type = ev.target.value;
  };

  addObject = () => {
    if (this.type == 'document') {
      this.setState({ docModalOpen: true });
    } else if (this.type == 'image') {
      this.setState({ imgModalOpen: true });
    } else if (this.type == 'link') {
      this.setState({ linkModalOpen: true });
    }
  };

  renderItem = item => {
    if (Document.prototype.isPrototypeOf(item)) {
      return this.renderDocument(item);
    } else if (Image.prototype.isPrototypeOf(item)) {
      return this.renderImage(item);
    } else if (Link.prototype.isPrototypeOf(item)) {
      return this.renderLink(item);
    }
  };

  handleDragAndDrop = items => {
    items.forEach((it, idx) => {
      it.order = idx;
      it.id = idx;
    });
    this.setState({ items });
    this.props.change(REDUX_FORM_NAME, 'gallery_items', items);
  };

  closeModal = type => {
    this[`${type}Reset`]();
    const newState = {};
    newState[`${type}ModalOpen`] = false;
    newState[`${type}FileError`] = false;
    this.setState(newState);
  };

  deleteItem = id => {
    const newItems = this.state.items.filter(i => i.id != id);
    this.handleDragAndDrop(newItems);
  };

  doSaveItem = (items, type) => {
    items.forEach((it, idx) => {
      it.order = idx;
      it.id = idx;
    });
    this.props.change(REDUX_FORM_NAME, 'gallery_items', items);
    this.setState({ items, editing: null });
    this.closeModal(type);
  };

  // eslint-disable-next-line class-methods-use-this
  getFileName(url) {
    if (url) {
      return decodeURIComponent(url)
        .split('/')
        .pop();
    }
    return '';
  }

  renderDocument = doc => (
    <div style={styles.item}>
      <div style={styles.itemColumn}>
        <b>Documento</b>
        <br />
        <label style={styles.fieldLabel}>File</label>
        <br />
        <input
          type="text"
          value={doc.file.name ? doc.file.name : this.getFileName(doc.file)}
          style={styles.itemField}
          disabled
        />

        <br />
        <label style={styles.fieldLabel}>Nombre</label>
        <br />
        <input type="text" value={doc.name} style={styles.itemField} disabled />
      </div>
      <div style={styles.itemColumn}>
        <div style={styles.actionButtonContainer}>
          <Button
            style={styles.actionButton}
            onClick={() => {
              this.deleteItem(doc.id);
            }}
          >
            <span style={styles.addIcon}>
              <svg style={styles.buttonSvg} focusable="false" viewBox="0 0 24 24" aria-hidden="true">
                <g>
                  <path d="M7 11v2h10v-2H7zm5-9C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z" />
                </g>
              </svg>
            </span>
            <span style={styles.buttonText}>ELIMINAR</span>
          </Button>
          <br />
          <Button style={styles.actionButton} onClick={() => this.editDoc(doc)}>
            <span style={styles.addIcon}>
              <svg style={styles.buttonSvg} focusable="false" viewBox="0 0 24 24" aria-hidden="true">
                <g>
                  <path d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.39-.39-1.02-.39-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z" />
                </g>
              </svg>
            </span>
            <span style={styles.buttonText}>EDITAR</span>
          </Button>
        </div>
      </div>
    </div>
  );

  onOpenDocModal = () => {
    const { editing } = this.state;
    if (this.state.editing) {
      this.docNameInput.value = editing.name;
    } else {
      this.docNameInput.value = null;
    }
  };

  saveDoc = () => {
    const file = this.state.docFile;
    const name = this.docNameInput.value;
    if (file) {
      const newItems = this.state.items.filter(i => i == i);
      if (this.state.editing) {
        newItems.forEach(i => {
          if (i.id == this.state.editing.id) {
            i.file = file;
            i.name = name;
          }
        });
      } else {
        const order = newItems.length;
        newItems.push(new Document(order, file, name));
      }
      this.doSaveItem(newItems, 'doc');
    } else {
      this.setState({ docFileError: true });
    }
  };

  editDoc = doc => {
    this.setState({
      docModalOpen: true,
      editing: doc,
      docFile: doc.file
    });
  };

  docReset = () => {
    this.setState({ docFile: null });
  };

  renderImage = img => (
    <div style={styles.item}>
      <div style={styles.itemColumn}>
        <b>Imagen</b>

        <br />
        <label style={styles.fieldLabel}>File</label>
        <br />
        <input
          type="text"
          value={img.file.name ? img.file.name : 'Imagen'}
          style={styles.itemField}
          disabled
        />
      </div>
      <div style={styles.itemColumn}>
        <div style={styles.actionButtonContainer}>
          <Button
            style={styles.actionButton}
            onClick={() => {
              this.deleteItem(img.id);
            }}
          >
            <span style={styles.addIcon}>
              <svg style={styles.buttonSvg} focusable="false" viewBox="0 0 24 24" aria-hidden="true">
                <g>
                  <path d="M7 11v2h10v-2H7zm5-9C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z" />
                </g>
              </svg>
            </span>
            <span style={styles.buttonText}>ELIMINAR</span>
          </Button>
          <br />
          <Button style={styles.actionButton} onClick={() => this.editImg(img)}>
            <span style={styles.addIcon}>
              <svg style={styles.buttonSvg} focusable="false" viewBox="0 0 24 24" aria-hidden="true">
                <g>
                  <path d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.39-.39-1.02-.39-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z" />
                </g>
              </svg>
            </span>
            <span style={styles.buttonText}>EDITAR</span>
          </Button>
        </div>
      </div>
    </div>
  );

  saveImg = () => {
    const file = this.state.imgFile;
    if (file) {
      const newItems = this.state.items.filter(i => i == i);
      if (this.state.editing) {
        newItems.forEach(i => {
          if (i.id == this.state.editing.id) {
            i.file = file;
          }
        });
      } else {
        const order = newItems.length;
        newItems.push(new Image(order, file));
      }
      this.doSaveItem(newItems, 'img');
    } else {
      this.setState({ imgFileError: true });
    }
  };

  editImg = img => {
    this.setState({
      imgModalOpen: true,
      editing: img,
      imgFile: img.file
    });
  };

  imgReset = () => {
    this.setState({ imgFile: null });
  };

  renderLink = link => (
    <div style={styles.item}>
      <div style={styles.itemColumn}>
        <b>Enlace</b>
        <br />
        <label style={styles.fieldLabel}>Título</label>
        <br />
        <input type="text" value={link.title} style={styles.itemField} disabled />

        <br />
        <label style={styles.fieldLabel}>Enlace</label>
        <br />
        <input type="text" value={link.link} style={styles.itemField} disabled />
      </div>
      <div style={styles.itemColumn}>
        <div style={styles.actionButtonContainer}>
          <Button style={styles.actionButton} onClick={() => this.deleteItem(link.id)}>
            <span style={styles.addIcon}>
              <svg style={styles.buttonSvg} focusable="false" viewBox="0 0 24 24" aria-hidden="true">
                <g>
                  <path d="M7 11v2h10v-2H7zm5-9C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z" />
                </g>
              </svg>
            </span>
            <span style={styles.buttonText}>ELIMINAR</span>
          </Button>
          <br />
          <Button style={styles.actionButton} onClick={() => this.editLink(link)}>
            <span style={styles.addIcon}>
              <svg style={styles.buttonSvg} focusable="false" viewBox="0 0 24 24" aria-hidden="true">
                <g>
                  <path d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.39-.39-1.02-.39-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z" />
                </g>
              </svg>
            </span>
            <span style={styles.buttonText}>EDITAR</span>
          </Button>
        </div>
      </div>
    </div>
  );

  onOpenLinkModal = () => {
    const { editing } = this.state;
    if (this.state.editing) {
      this.linkTitleInput.value = editing.title;
      this.linkInput.value = editing.link;
    } else {
      this.linkTitleInput.value = null;
      this.linkInput.value = null;
    }
  };

  saveLink = () => {
    const link = this.linkInput.value;
    const title = this.linkTitleInput.value;
    if (link && title) {
      const newItems = this.state.items.filter(i => i == i);
      if (this.state.editing) {
        newItems.forEach(i => {
          if (i.id == this.state.editing.id) {
            i.title = title;
            i.link = link;
          }
        });
      } else {
        const order = newItems.length;
        newItems.push(new Link(order, link, title));
      }
      this.doSaveItem(newItems, 'link');
    } else {
      if (!link) {
        this.setState({ linkError: true });
      } else if (!title) {
        this.setState({ linkTitleError: true });
      }
    }
  };

  editLink = link => {
    this.setState({
      linkModalOpen: true,
      editing: link
    });
  };

  linkReset = () => {
    this.setState({});
  };

  render() {
    return (
      <>
        <RLDD items={this.state.items} itemRenderer={this.renderItem} onChange={this.handleDragAndDrop} />

        <select onChange={this.updateType} style={styles.select}>
          <option value="document" selected={true}>
            Documento
          </option>
          <option value="image">Imagen</option>
          <option value="link">Enlace</option>
        </select>

        <br />
        <Button onClick={this.addObject} style={styles.addButton}>
          <span style={styles.addIcon}>
            <svg style={styles.buttonSvg} focusable="false" viewBox="0 0 24 24" aria-hidden="true">
              <g>
                <path d="M13 7h-2v4H7v2h4v4h2v-4h4v-2h-4V7zm-1-5C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z" />
              </g>
            </svg>
          </span>
          <span style={styles.buttonText}>AÑADIR</span>
        </Button>

        {/* Document modal */}
        <Modal isOpen={this.state.docModalOpen} style={styles.docModal} onAfterOpen={this.onOpenDocModal}>
          <span style={styles.closeModal} onClick={() => this.closeModal('doc')}>
            X
          </span>
          <h2>{this.state.editing ? 'Editar' : 'Nuevo'} documento</h2>
          <form>
            <label style={styles.fieldLabel}>Archivo</label> <br />
            <Dropzone onDrop={files => this.setState({ docFile: files[0] })}>
              {({ getRootProps, getInputProps }) => (
                <section>
                  <div style={styles.dragAndDrop} {...getRootProps()}>
                    <input {...getInputProps()} />
                    <p>Arrastre un archivo para subir o haga clic para seleccionarlo.</p>
                  </div>
                  {this.state.docFile
                    ? this.state.docFile.name
                      ? this.state.docFile.name
                      : this.getFileName(this.state.docFile)
                    : null}
                </section>
              )}
            </Dropzone>
            <div style={this.state.docFileError ? styles.modalError : { display: 'none' }}>
              Sleccione un archivo
            </div>
            <br />
            <br />
            <label style={styles.fieldLabel}>Nombre</label> <br />
            <input type="text" style={styles.itemField} ref={el => (this.docNameInput = el)} />
            <br />
            <br />
            <Button onClick={this.saveDoc} style={styles.saveBtn}>
              <span style={styles.addIcon}>
                <svg style={styles.buttonSvg} focusable="false" viewBox="0 0 24 24" aria-hidden="true">
                  <g>
                    <path d="M13 7h-2v4H7v2h4v4h2v-4h4v-2h-4V7zm-1-5C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z" />
                  </g>
                </svg>
              </span>
              <span style={styles.buttonText}>GUARDAR</span>
            </Button>
          </form>
        </Modal>

        {/* Image modal */}
        <Modal isOpen={this.state.imgModalOpen} style={styles.imgModal}>
          <span style={styles.closeModal} onClick={() => this.closeModal('img')}>
            X
          </span>
          <h2>{this.state.editing ? 'Editar' : 'Nueva'} imagen</h2>
          <form>
            <label style={styles.fieldLabel}>Archivo</label> <br />
            <Dropzone onDrop={files => this.setState({ imgFile: files[0] })}>
              {({ getRootProps, getInputProps }) => (
                <section>
                  <div style={styles.dragAndDrop} {...getRootProps()}>
                    <input {...getInputProps()} />
                    <p>Arrastre un archivo para subir o haga clic para seleccionarlo.</p>
                  </div>
                  {this.state.imgFile ? (
                    this.state.imgFile.name ? (
                      this.state.imgFile.name
                    ) : (
                      <a href={this.state.imgFile} target={'_blank'}>
                        Imagen
                      </a>
                    )
                  ) : null}
                </section>
              )}
            </Dropzone>
            <div style={this.state.imgFileError ? styles.modalError : { display: 'none' }}>
              Sleccione un archivo
            </div>
            <br />
            <br />
            <Button onClick={this.saveImg} style={styles.saveBtn}>
              <span style={styles.addIcon}>
                <svg style={styles.buttonSvg} focusable="false" viewBox="0 0 24 24" aria-hidden="true">
                  <g>
                    <path d="M13 7h-2v4H7v2h4v4h2v-4h4v-2h-4V7zm-1-5C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z" />
                  </g>
                </svg>
              </span>
              <span style={styles.buttonText}>GUARDAR</span>
            </Button>
          </form>
        </Modal>

        {/* Link modal */}
        <Modal
          isOpen={this.state.linkModalOpen}
          style={styles.linkModal}
          onAfterOpen={this.onOpenLinkModal}
          ariaHideApp={false}
        >
          <span style={styles.closeModal} onClick={() => this.closeModal('link')}>
            X
          </span>
          <h2>{this.state.editing ? 'Editar' : 'Nuevo'} enlace</h2>
          <form>
            <label style={styles.fieldLabel}>Titulo</label> <br />
            <input type="text" style={styles.itemField} ref={el => (this.linkTitleInput = el)} />
            <div style={this.state.linkTitleError ? styles.modalError : { display: 'none' }}>
              Ingrese un título
            </div>
            <br />
            <br />
            <label style={styles.fieldLabel}>Enlace</label> <br />
            <input type="text" style={styles.itemField} ref={el => (this.linkInput = el)} />
            <div style={this.state.linkError ? styles.modalError : { display: 'none' }}>
              Ingrese un enlace
            </div>
            <br />
            <br />
            <Button onClick={this.saveLink} style={styles.saveBtn}>
              <span style={styles.addIcon}>
                <svg style={styles.buttonSvg} focusable="false" viewBox="0 0 24 24" aria-hidden="true">
                  <g>
                    <path d="M13 7h-2v4H7v2h4v4h2v-4h4v-2h-4V7zm-1-5C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z" />
                  </g>
                </svg>
              </span>
              <span style={styles.buttonText}>GUARDAR</span>
            </Button>
          </form>
        </Modal>
      </>
    );
  }
}

export default GalleryItemsInput;
