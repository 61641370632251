import React from 'react';
import PropTypes from 'prop-types';
import Chip from '@material-ui/core/Chip';
import { withStyles } from '@material-ui/core/styles';

import { truncate } from '~constants/styles';

import styles from './styles';

function LocationField({ record = {}, source, classes, className }) {
  const location = record[source] || record;
  return (
    <Chip
      className={`${classes.chip} ${className}`}
      classes={{ label: classes.truncate }}
      label={location.city ? `${location.city}, ${location.country}` : location.country}
    />
  );
}

LocationField.propTypes = {
  classes: PropTypes.shape({
    chip: PropTypes.string.isRequired,
    truncate: PropTypes.string.isRequired
  }).isRequired,
  source: PropTypes.string.isRequired,
  className: PropTypes.string,
  record: PropTypes.shape({
    city: PropTypes.string,
    country: PropTypes.string
  })
};

export default withStyles({ ...styles, ...truncate })(LocationField);
