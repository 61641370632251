const styles = {
  item: {
    padding: '10px 0px 10px 4px',
    width: '100%',
    marginBottom: '10px',
    borderTop: 'solid 1px #e0e0e0',
    borderBottom: 'solid 1px #e0e0e0',
    backgroundColor: '#FFF'
  },
  select: {
    display: 'inline-block',
    width: '200px',
    border: '0px',
    borderBottom: 'solid 1px #949494',
    fontSize: '17px',
    paddingBottom: '7px'
  },
  addButton: {
    width: '100px'
  },
  addIcon: {
    width: '1em',
    height: '1em',
    display: 'inline-block',
    fontSize: '24px'
  },
  buttonSvg: {
    width: '24px'
  },
  buttonText: {
    display: 'inline-block',
    marginLeft: '10px'
  },
  itemColumn: {
    width: '49.5%',
    display: 'inline-block',
    minHeight: '100px',
    verticalAlign: 'top'
  },
  fieldLabel: {
    color: 'rgba(0, 0, 0, 0.54)',
    padding: '0',
    fontFamily: 'Archivo,Arial,Helvetica,sans-serif',
    lineHeight: '1',
    fontSize: '12px'
  },
  itemField: {
    width: '100%',
    border: '0px',
    borderBottom: 'solid 1px #949494',
    fontSize: '17px',
    marginTop: '5px',
    paddingBottom: '6px',
    paddingTop: '6px'
  },
  actionButton: {
    width: '100px'
  },
  actionButtonContainer: {
    float: 'right'
  },
  docModal: {
    overlay: { zIndex: '1000' },
    content: {
      top: '140px',
      left: '20%',
      right: '20%',
      bottom: '140px'
    }
  },
  imgModal: {
    overlay: { zIndex: '1000' },
    content: {
      top: '175px',
      left: '20%',
      right: '20%',
      bottom: '175px'
    }
  },
  linkModal: {
    overlay: { zIndex: '1000' },
    content: {
      top: '153px',
      left: '20%',
      right: '20%',
      bottom: '153px',
    }
  },
  closeModal: {
    float: 'right',
    cursor: 'pointer'
  },
  dragAndDrop: {
    color: '#999',
    cursor: 'pointer',
    padding: '1rem',
    background: '#efefef',
    textAlign: 'center'
  },
  modalError: {
    color: 'red'
  },
  saveBtn: {
    float: 'right'
  }
};

export default styles;
