let styles = {

    modal: {
        backgroundColor: 'rgba(0,0,0,.3)',
        height: '100%',
        left: '0',
        position: 'fixed',
        top: '0',
        zIndex: '5',
        width: '100%'
    },

    modalContainer: {
        width: '1000px',
        height: 'fit-content',
        position: 'fixed',
        backgroundColor: '#FFF',
        top: '100px',
        left: 'calc(50% - 500px)',
        zIndex: '1000'
    },

    hidden: {
        display: 'none'
    },

    modalHeader: {
        height: '50px',
        padding: '20px 20px 0px 20px',
        border: 'solid grey 1px'
    },

    title: {
        fontSize: '25px',
        float: 'left',
        marginTop: '0px'
    },

    closeBtn: {
        float: 'right',
        border: '0px',
        backgroundColor: 'transparent',
        cursor: 'pointer'
    },

    modalBody: {
        backgroundColor: '#FFF',
        border: 'solid grey 1px',
        height: '400px'
    }
}

export default styles
