import { ALTO } from '~constants/colors';

const styles = {
  borderBottom: {
    borderBottom: `2px solid ${ALTO}`,
    marginBottom: '50px',
    paddingBottom: '50px'
  },
  hiddenField: {
    display: 'none'
  },
  formCol: {
    width: '48%',
    display: 'inline-block',
    paddingRight: '20px',
    verticalAlign: 'top'
  },
  full: {
    width: '100%'
  },
  dynamicPreviewBtn: {
    width: '500px',
    margin: 'auto'
  },
  staticPreview: {
    width: '20%',
    display: 'inline-block',
    verticalAlign: 'middle',
    paddingLeft: '90px'
  },
  staticTitle: {
    marginBottom: '40px'
  },
  volverButton: {
    marginLeft: '10px',
    color: '#3f51b5',
    marginTop: '12px'
  },
  chevron: {
    width: '20px'
  },
  inputLabel: {
    color: '#7b7b7b',
    fontSize: '13px',
    marginTop: '13px',
    display: 'block'
  }
};

export default styles;
