import React, { Component } from 'react';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import { EditButton } from 'react-admin';
import dataProvider, { DRAFT, PUBLISH } from 'config/providers/dataProvider';
import PropTypes from 'prop-types';

import { ADMINISTRATOR } from '~config/providers/authProvider';

import { PUBLISHED } from './constants';

const cardActionStyle = {
  zIndex: 2,
  display: 'inline-block',
  float: 'right'
};

class ShowCustomActions extends Component {

  handlePublish = () => {
    const { data, resource } = this.props;
    dataProvider(PUBLISH, resource, data).then(this.redirect);
  };

  handleDraft = () => {
    const { data, resource } = this.props;
    dataProvider(DRAFT, resource, data).then(this.redirect);
  };

  rediretToEdit = () => {
    window.location = `/#/projects/${this.props.id}/edit`;
  }

  redirect = () => {
    location.reload();
  };

  render() {
    const { permissions, basePath, data, translate: t } = this.props;
    return (
      <CardActions style={cardActionStyle}>
        {data && data.editable && <EditButton basePath={basePath} record={data} />}
        {data && permissions === ADMINISTRATOR && data.publishable && (
            <>
              {data.publicationStatus === PUBLISHED ? (
                  <Button color="primary" onClick={this.handleDraft}>
                    {t('buttons.draft')}
                  </Button>
              ) : (
                  <Button color="primary" onClick={this.handlePublish}>
                    {t('buttons.publish')}
                  </Button>
              )}
              {/*<Button color="primary" onClick={this.rediretToEdit}>*/}
              {/*  {t('buttons.edit')}*/}
              {/*</Button>*/}
            </>
        )}
      </CardActions>
    );
  }
}

ShowCustomActions.propTypes = {
  basePath: PropTypes.string,
  data: PropTypes.shape({
    editable: PropTypes.bool,
    publicationStatus: PropTypes.string,
    publishable: PropTypes.bool
  }),
  history: PropTypes.shape({
    push: PropTypes.func
  }),
  permissions: PropTypes.string,
  resource: PropTypes.string,
  translate: PropTypes.func
};

export default ShowCustomActions;
