import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import PropTypes from 'prop-types';

import starred from '~assets/starred.svg';

import styles from './styles.scss';

function StarredField({ record }) {
  return record.starredAt ? <Avatar src={starred} className={styles.starredIcon} /> : null;
}

StarredField.defaultProps = {
  label: ''
};

StarredField.propTypes = {
  label: PropTypes.string, // eslint-disable-line react/no-unused-prop-types
  record: PropTypes.shape({
    starredAt: PropTypes.bool
  })
};

export default StarredField;
