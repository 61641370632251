class GalleryItem {
    id;
    model_id;
    order;
    type;

    constructor(order=null, type) {
        this.order = order;
        this.id = order;
        this.type = type;
    }

}

export class Document extends GalleryItem {
    file;
    name;

    constructor(order=null, file=null, name=null) {
        super(order, 'Document');
        this.file = file;
        this.name = name;
    }

    populate = (obj) => {
        this.model_id = obj.modelId || obj.model_id;
        this.order = obj.order;
        this.id = obj.order;
        this.file = obj.file;
        this.name = obj.name;
    }

}

export class Image extends GalleryItem {
    file;

    constructor(order=null, file=null) {
        super(order, 'Image');
        this.file = file;
    }

    populate = (obj) => {
        this.model_id = obj.modelId || obj.model_id;
        this.order = obj.order;
        this.id = obj.order;
        this.file = obj.file;
    }
}

export class Link extends GalleryItem {
    link;
    title;

    constructor(order=null, link=null, title=null) {
        super(order, 'Link');
        this.link = link;
        this.title = title;
    }

    populate = (obj) => {
        this.model_id = obj.modelId || obj.model_id;
        this.order = obj.order;
        this.id = obj.order;
        this.link = obj.link;
        this.title = obj.title;
    }
    
}
