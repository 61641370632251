import axios from 'axios';
import { getApiHeaders } from 'utils/helpers';

import { RESOURCES } from '~constants/config';

import { apiSetup } from '../api';

apiSetup();

export class DocumentsService {
  BASE_URL = `${process.env.REACT_APP_BASE_URL}backoffice/documents`;

  API_HEADERS = getApiHeaders();

  create(type, data, id) {
    if (type === RESOURCES.projects || type === 'iirsaProjects') {
      return this.createProject(data, id, type);
    } else if (type === RESOURCES.articles) {
      return this.createArticle(data, id);
    } else if (type === RESOURCES.events) {
      return this.createEvent(data, id);
    }
  }

  createProject(data, id, type) {
    let formData = this.createProjectFormData(data, id, type);
    return axios.post(`${this.BASE_URL}/`, formData, { headers: this.API_HEADERS });
  }

  createArticle(data, id) {
    if (!data.image || data.image.rawFile) {
      let imageData = this.createDocumentFormData('Article', data.image, id);
      return axios.post(`${this.BASE_URL}/`, imageData, { headers: this.API_HEADERS });
    }
  }

  async createEvent(data, id) {
    let currentDocs = new Array();
    await this.createEventImages(data, id, currentDocs);
    await this.createEventDocuments(data, id, currentDocs);
    return this.deleteMany(currentDocs, id, 'Event');
  }

  async createEventImages(data, id, currentDocs) {
    if (data.images) {
      data.images.forEach(async doc => {
        let newId = await this.createSingle(doc, id, 'image');
        if (newId) {
          currentDocs.push(newId);
        }
      });
    }
  }

  async createEventDocuments(data, id, currentDocs) {
    if (data.documents) {
      data.documents.forEach(async doc => {
        let newId = await this.createSingle(doc, id, 'document');
        if (newId) {
          currentDocs.push(newId);
        }
      });
    }
  }

  async createSingle(doc, id, docType) {
    if (doc.file.rawFile) {
      let docData = this.createDocumentFormData('Event', doc.file, id);
      docData.append('type', docType);
      let response = await axios.post(`${this.BASE_URL}/`, docData, { headers: this.API_HEADERS });
      return response.data.id;
    } else if (doc.id) {
      return doc.id;
    }
  }

  update(data, resource) {
    let formData = this.createProjectFormData(data, null, resource);
    return axios.put(`${this.BASE_URL}/${data.id}`, formData, {
      headers: this.API_HEADERS
    });
  }

  deleteMany(array, id, type) {
    let formData = new FormData();
    formData.append('documentable_id', id);
    formData.append('ids', array);
    formData.append('documentable_type', type);
    return axios.post(`${this.BASE_URL}/delete_many/`, formData, { headers: this.API_HEADERS });
  }

  // eslint-disable-next-line class-methods-use-this
  createProjectFormData(data, id = null, type) {
    const formData = new FormData();
    formData.append('name', data.name);
    formData.append('file', data.file);
    formData.append('order', data.order);
    formData.append('documentable_type', type === RESOURCES.projects ? 'Project' : 'iirsaProject');
    if (id) {
      formData.append('documentable_id', id);
    }
    // TODO: THIS SEEMS TO BE USELESS:
    // if (data.model_id) {
    //   formData.append('id', data.model_id);
    // }
    return formData;
  }

  createDocumentFormData(type, img, id) {
    let formData = new FormData();
    if (img) {
      formData.append('file', img.rawFile);
      formData.append('name', img.rawFile.name);
    }
    formData.append('documentable_id', id);
    formData.append('documentable_type', type);
    return formData;
  }
}
