const styles = {
    title: {
        color: "#3e3e3e",
        fontSize: "25px",
        textAlign: 'center'
    },
    
    biggerTitle: {
        paddingBottom: '30px',
        color: "#3e3e3e",
        fontSize: "25px",
        textAlign: 'center'
    },

    col: {
        display: 'inline-block',
        width: '33%',
        height: '330px',
        verticalAlign: 'middle',
        textAlign: 'center'
    },

    table: {
        display: 'table'
    },
  
    tableCell: {
        display: 'table-cell',
        height: '290px',
        verticalAlign: 'top',
        textAlign: 'center'
    },

    card: {
        width: '350px',
        margin: 'auto',
        marginTop: '20px'
    },

    cardHeader: {
        height: '45px',
        color: "#3e3e3e",
        fontSize: "25px",
        textAlign: 'center',
        display: 'block',
        paddingTop: '20px'
    },
  
    cardBody: {
        padding: '60px',
        fontSize: '40px'
    }
}

export default styles
