import React from 'react';
import { Create } from 'react-admin';

import ArticleForm from '../ArticleForm';

function ArticleCreate({ ...props }) {
  return (
    <Create {...props}>
      <ArticleForm redirect="show" />
    </Create>
  );
}

export default ArticleCreate;
