import api from '~config/api';
import { requiredHeaders } from '~constants/config';

export const USER_ATTRIBUTES = ['name', 'userType'];

export const checkAuthHeaders = () => requiredHeaders.every(header => !!window.localStorage.getItem(header));

const setTo = {
  localStorage: (name, value) => localStorage.setItem(name, value),
  api: (name, value) => api.setHeader(name, value)
};

const setHeaders = (source, name, value) => setTo[source](name, value);

export const setAuthHeaders = headers => {
  const entries = Object.entries(headers);
  return entries.forEach(([name, value]) => {
    setHeaders('api', name, value);
    setHeaders('localStorage', name, value);
  });
};

export const storeUserData = data => {
  USER_ATTRIBUTES.forEach(attribute => {
    localStorage.setItem(attribute, data[attribute]);
  });
};

export const clearUserData = () => {
  USER_ATTRIBUTES.forEach(attribute => {
    localStorage.removeItem(attribute);
  });
};

export const setApiHeaders = () =>
  requiredHeaders.forEach(header => {
    const headerValue = window.localStorage.getItem(header);
    setHeaders('api', header, headerValue);
  });

export const getApiHeaders = () => {
  let headers = {};
  requiredHeaders.forEach(header => {
    let headerValue = window.localStorage.getItem(header);
    headers[header] = headerValue;
  });
  headers['Content-Type'] = 'multipart/form-data';
  return headers;
}
