import api, { apiSetup } from '../api';

apiSetup();

export class ProjectService {

    BASE_URL = '/backoffice/projects';

    get_choices() {
        return api.get(`${this.BASE_URL}/get_choices`);
    }

    get_chart_data() {
        return api.get(`${this.BASE_URL}/get_chart_data`);
    }

}
