import React, { Component } from 'react';
import togeojson from "@mapbox/togeojson";
import { GOOGLE_MAPS_API_KEY, MAP_STYLE, ORANGE } from './constants';

import styles from './styles.module.scss';

class StaticMapPreview extends Component {

    state = { 
        paths: [],
        markers: [],
        polygons: []
    };
    
    constructor(props) {
        super(props);
    }

    componentWillReceiveProps(props) {
        if(props.kml) {
            const gjsonPromise = this.getGeoJSON(props.kml);
            this.setPaths(gjsonPromise);
            this.setMarkers(gjsonPromise);
        }
    }

    getGeoJSON = async (kml) => {
        let path = null;
        if(kml['rawFile']) {
            path = kml.rawFile.preview;
        } else {
            path = kml;
        }
        const response = await fetch(path);
        const kmlText = await response.text();
        let domObj = (new DOMParser()).parseFromString(kmlText, 'text/xml')
        let gjson = togeojson.kml(domObj);
        return gjson;
    }

    setPaths = (gjsonPromise) => {
        let paths = '';
        gjsonPromise.then(gjson => {
          gjson.features.forEach(f => {
            if(f.geometry.type == 'LineString') {
                const currentPath = this.getCurrentPath(f.geometry.coordinates);
                paths += `&path=color:${ORANGE}%7Cweight:5$%7C${currentPath}`;
            } else if(f.geometry.hasOwnProperty('geometries')) {
                f.geometry.geometries.forEach(g => {
                    if(g.type == 'LineString') {
                        const currentPath = this.getCurrentPath(g.coordinates);
                        paths += `&path=color:${ORANGE}%7Cweight:5$%7C${currentPath}`;
                    }
                });
            }
          });
          this.setState({paths: paths});
        })
    };

    setMarkers = (gjsonPromise) => {
        let markers = '';
        gjsonPromise.then(gjson => {
            gjson.features.forEach(f => {
                if(f.geometry.type == 'Point') {
                    const coor = f.geometry.coordinates;
                    markers += `%7C${coor[1]},${coor[0]}`;
                } else if(f.geometry.hasOwnProperty('geometries')) {
                    f.geometry.geometries.forEach(g => {
                        if(g.type == 'Point') {
                            const coor = g.coordinates;
                            markers += `%7C${coor[1]},${coor[0]}`;
                        }
                    })
                }
            });
            this.setState({markers: markers});
            this.setPolygons(gjsonPromise)
        });
    }

    setPolygons = (gjsonPromise) => {
        let polygons = '';
        gjsonPromise.then(gjson => {
            gjson.features.forEach(f => {
                if(f.geometry.type == 'Polygon') {
                    const currentPol = this.getCurrentPath(f.geometry.coordinates[0]);
                    polygons += `&path=color:${ORANGE}%7Cweight:5$%7Cfillcolor%3a${ORANGE}%7C${currentPol}`;
                } else if(f.geometry.hasOwnProperty('geometries')) {
                    f.geometry.geometries.forEach(g => {
                        if(g.type == 'Polygon') {
                            const currentPol = this.getCurrentPath(f.geometry.coordinates[0]);
                            polygons += `&path=color:${ORANGE}%7Cweight:5$%7Cfillcolor%3a${ORANGE}%7C${currentPol}`;
                        }
                    });
                }
            });
            this.setState({polygons: polygons});
        })
    }

    getCurrentPath = (coordinates) => {
        let currentPath = '';
        coordinates.forEach(c => {
            if(coordinates.indexOf(c) > 0) {
                currentPath += '|';
            }
            currentPath += `${c[1]},${c[0]}`;
        });
        return currentPath;
    }

    render() {
        let center = '';
        if(this.props.centerLat && this.props.centerLng) {
            center = `center=${this.props.centerLat},${this.props.centerLng}&`;
        }
        
        let zoom = '';
        if(this.props.zoom) {
            zoom = `zoom=${this.props.zoom}&`;
        }

        return ( this.props.kml ?
            <img
                className={styles.map}
                src={`https://maps.googleapis.com/maps/api/staticmap?${center}${zoom}&markers=color:${ORANGE 
                + this.state.markers}&size=316x316&${this.state.paths}${this.state.polygons}&${MAP_STYLE}&key=${GOOGLE_MAPS_API_KEY}`}
            />
            :
            <></>
        )
    }

}

export default StaticMapPreview
