const styles = {

    header: {
        display: 'block',
        padding: '10px',
        border: 'solid 1px #e0e0e0',
        marginBottom: '2px'
    },

    openBtn: {
        display: 'inline-block',
        width: '10px'
    },

    iconDown: {
        transform: 'rotate(90deg)'
    },

    dateTitle: {
        display: 'inline-block',
        marginLeft: '10px',
        color: 'rgba(0, 0, 0, 0.54)'
    },

    content: {
        display: 'block',
        padding: '10px',
        border: 'solid 1px #e0e0e0',
        marginBottom: '2px'
    },

    dataTitle: {
        color: 'rgba(0, 0, 0, 0.54)'
    },

    dataValue: {
        color: '#000'
    },

    userCard: {
        border: 'solid 1px #e0e0e0',
        padding: '10px'
    },

    userLabel: {
        color: 'rgba(0, 0, 0, 0.54)'
    }, 

}

export default styles;
