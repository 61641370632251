import { ALTO } from '~constants/colors';

const styles = {
  borderBottom: {
    borderBottom: `2px solid ${ALTO}`,
    marginBottom: '50px',
    paddingBottom: '50px'
  },
  inlineChildren: {
    display: 'flex',
    width: '30%'
  },
  volverButton: {
    marginLeft: '10px',
    color: '#3f51b5',
    marginTop: '12px',
    width: '100px',
    marginBottom: '10px'
  },
  chevron: {
    width: '20px'
  }
};

export default styles;
