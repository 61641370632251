import api, { apiSetup } from '../api';

apiSetup();

export class TopicsService {

    BASE_URL = '/backoffice/topics/all';

    get() {
        return api.get(this.BASE_URL);
    }

}
