/* eslint-disable camelcase */
import { DATE_FORMAT_PLACEHOLDER } from '~constants/dates';

export const spanishResources = {
  resources: {
    articles: {
      name: 'Contenidos',
      types: {
        video: 'Video',
        infographic: 'Infografía',
        article: 'Artículo',
        notes: 'Nota',
        news: 'Noticia',
        publishing: 'Publicación'
      },
      fields: {
        publicationStatus: 'Estado',
        title: 'Título',
        description: 'Subtítulo, copete o resumen',
        authorName: 'Autor',
        author: {
          firstName: 'Nombre',
          lastName: 'Apellido',
          email: 'Email',
          institution: 'Institución',
          role: 'Rol'
        },
        content: 'Contenido',
        topics: 'Temas',
        topic: 'Tema',
        articleType: 'Tipo',
        locations: 'Países',
        country: 'País',
        city: 'Ciudad',
        countries: 'Países',
        infrastructure: 'Infra',
        contentType: 'Tipo de publicación',
        createdAt: 'Registro',
        creationDateRange: 'Registro',
        image: 'Imagen de presentación',
        date: 'Fecha',
        materialDate: 'Fecha del material',
        materialDateRequired: 'Fecha del material (*)',
        starred_at: 'Destacado', // eslint-disable-line camelcase
        created_at: 'Fecha de creación', // eslint-disable-line camelcase
        originalSource: 'Fuente',
        sourceUrl: 'Enlace a publicación original',
        videoUrl: 'URL de video',
        sortBy: 'Ordernar por'
      },
      filters: {
        title: 'Título',
        location: 'País',
        infrastructure: 'Infraestructura',
        type: 'Tipo de contenido',
        author: 'Autor',
        createdAt: 'Creación',
        publicationStatus: 'Estado'
      },
      sortBy: {
        created_at: 'Fecha de creación',
        updated_at: 'Fecha de última modificación',
        material_date: 'Fecha material'
      },
      formFields: {
        title: 'Título (*)',
        description: 'Subtítulo, copete o resumen (*)',
        image: 'Imagen de presentación (*)',
        imageNotRequired: 'Imagen de presentación',
        firstName: 'Nombre (*)',
        articleType: 'Tipo (*)'
      }
    },
    projects: {
      fields: {
        link: 'Enlace',
        title: 'Título (*)',
        infrastructure: 'Sector (*)',
        status: 'Etapa (*)',
        area: 'Ámbito (*)',
        subsector: 'Subsector (*)',
        project_type: 'Tipo de obra',
        objectives: 'Objetivos',
        content: 'Descripción',
        financing: 'Tipo de financiamiento (*)',
        sources: 'Financiamiento',
        estimated_end_date: 'Fin estimado',
        ambiental_license: 'Licencia ambiental',
        url: 'Enlace',
        image: 'Imagen',
        image_name: 'Nombre de imagen',
        projects: 'Proyectos relacionados',
        kml: 'Archivo KML',
        dynamic_map_lat: 'Latitud del centro',
        dynamic_map_lng: 'Longitud del centro',
        dynamic_map_zoom: 'Nivel de zoom',
        kml_simplified: 'Archivo KML',
        static_map_lat: 'Latitud del centro',
        static_map_lng: 'Longitud del centro',
        static_map_zoom: 'Nivel de zoom',
        documents: 'Documentos',
        images: 'Imágenes',
        countries: 'Países (*)',
        name: 'Nombre',
        createdDate: 'Creación',
        createdDate_desde: 'Creado desde',
        createdDate_hasta: 'Creado hasta',
        totalInvestment_min: 'Inversión minima',
        totalInvestment_max: 'Inversión máxima',
        backTitle: 'Título',
        updatedDate: 'Última modificación',
        updatedDate_desde: 'Modificación desde',
        updatedDate_hasta: 'Modificación hasta',
        totalInvestment: 'Inversión total',
        institution: 'Institución',
        amount: 'Monto',
        first_name: 'Nombre',
        last_name: 'Apellido',
        email: 'Email',
        role: 'Cargo',
        phone: 'Teléfono',
        owners: 'Responsables',
        name: 'Nombre',
        description: 'Descripción',
        investment: 'Inversión',
        bank: 'Banco',
        situation: 'Situación'
      }
    },
    iirsaProjects: {
      fields: {
        image: 'Imagen',
        image_name: 'Nombre de imagen',
        kml: 'Archivo KML',
        dynamic_center_latitude: 'Latitud del centro',
        dynamic_center_longitude: 'Longitud del centro',
        dynamic_map_zoom: 'Nivel de zoom',
        kml_simplified: 'Archivo KML',
        static_map_lat: 'Latitud del centro',
        static_map_lng: 'Longitud del centro',
        static_map_zoom: 'Nivel de zoom',
        images: 'Imágenes',
        iirsa_countries: 'Países (*)',
        name: 'Nombre (*)',
        code: 'Código (*)',
        createdDate: 'Creación',
        createdDate_desde: 'Creado desde',
        createdDate_hasta: 'Creado hasta',
        totalInvestment_min: 'Inversión minima',
        totalInvestment_max: 'Inversión máxima',
        backTitle: 'Título',
        updatedDate: 'Última modificación',
        updatedDate_desde: 'Modificación desde',
        updatedDate_hasta: 'Modificación hasta',
        totalInvestmentFormatted: 'Inversión total',
        institution: 'Institución',
        first_name: 'Nombre',
        last_name: 'Apellido',
        email: 'Email',
        role: 'Cargo',
        phone: 'Teléfono',
        owners: 'Responsables',
        description: 'Descripción',
        investment: 'Inversión',
        situation: 'Situación',
        iirsa_subsector: 'Subsector (*)',
        iirsa_stage: 'Etapa (*)',
        iirsa_status: 'Estado',
        iirsa_project_type: 'Tipo de Obra (*)',
        iirsa_scope: 'Alcance (*)',
        iirsa_segments: 'Segmento',
        is_bid: 'BID',
        is_caf: 'CAF',
        is_fonplata: 'FONPLATA',
        accompaniment: 'Acompañamiento',
        is_portfolio: 'Portafolio',
        comments: 'Comentarios',
        is_anchor: 'Ancla',
        has_ambiental_license: 'Licencia ambiental',
        iirsa_studies_state: 'Estado de los estudios',
        iirsa_studies_funder: 'Financiador de los estudios',
        studies_finished: 'Estudios finalizados',
        iirsa_project_funding: 'Financiamiento',
        studies_amount: 'Monto de los estudios',
        iirsa_funding_type: 'Tipo de financiamiento',
        iirsa_funding_status: 'Estado del financiamiento',
        amount: 'Monto (*)',
        countries: 'Países',
        stage: 'Etapa',
        iirsa_total_investment_min: 'Inversión mínima',
        iirsa_total_investment_max: 'Inversión máxima'
      }
    },
    events: {
      name: 'Eventos',
      addEventSession: 'Añadir día',
      fields: {
        publicationStatus: 'Estado',
        title: 'Título (*)',
        topics: 'Temas',
        topic: 'Tema',
        city: 'Ciudad',
        country: 'País (*)',
        eventType: 'Tipo de evento (*)',
        infrastructure: 'Tipo de Infraestructura (*)',
        image: 'Imagen de presentación',
        createdAt: 'Creación',
        creationDateRange: 'Creación',
        durationDateRange: 'Fecha del evento',
        description: 'Descripción',
        endMoment: 'Fecha de finalización',
        organizer: 'Organizador (Opcional)',
        eventLink: 'Enlace del evento (Opcional)',
        documents: 'Archivos relacionados',
        document: 'Archivo',
        documentName: 'Nombre del archivo',
        images: 'Fotos (la primera será usada como portada) (*)',
        imagesFields: {
          image: 'Foto'
        },
        location: {
          country: 'País',
          city: 'Ciudad'
        },
        locations: 'Paises',
        locationTitle: 'Lugar',
        eventSessions: 'Días (*)',
        eventSessionsAttributes: 'Días',
        sessionDate: 'Fecha',
        sessionStartTime: 'Hora de inicio',
        sessionEndTime: 'Hora de fin',
        starred_at: 'Destacado', // eslint-disable-line camelcase
        created_at: 'Fecha de creación' // eslint-disable-line camelcase
      },
      types: {
        workshop: 'Taller',
        meeting: 'Webinar',
        conference: 'Conferencia',
        training: 'Capacitación',
        seminar: 'Seminario'
      }
    },
    searchs: {
      name: 'Búsquedas',
      fields: {
        searchString: 'Término',
        date: 'Fecha',
        section: 'Sección',
        contentType: 'Sección',
        location: 'País',
        country: 'País',
        createdDate: 'Fecha',
        createdDate_desde: 'Creado desde',
        createdDate_hasta: 'Creado hasta',
        investment: 'Inversión',
        totalInvestmentFormatted: 'Inversión',
        infrastructure: 'Infraestructura',
        financing: 'Financiamiento',
        stage: 'Etapa',
        topic: 'Tecnología',
        type_filter: 'Tipo'
      }
    },
    searchsSummary: {
      name: 'Detalle de búsquedas',
      fields: {
        date: 'Fecha',
        searchString: 'Término',
        count: 'Cantidad'
      }
    },
    topics: {
      name: 'Temas',
      fields: {
        title: 'Título'
      }
    }
  },
  fieldGroups: {
    contentInformation: 'Datos básicos de la publicación',
    mainContent: 'Contenido de la publicación',
    additionalInformation: 'Información adicional de la publicación',
    authorInfo: 'Autor del material',
    contentConfiguration: 'Configuración de sistema',
    date: 'Fecha del material',
    contentType: 'Tipo de contenido',
    originalSource: 'Fuente (Opcional)',
    sourceUrl: 'Enlace a publicación original (Opcional)',
    starred: 'Destacado (Opcional)',
    dropImage: 'Arrastre la imágen aquí',
    dropVideo: 'Arrastre el video aquí',
    authorName: 'Nombre (Opcional)',
    authorLastName: 'Apellido (Opcional)',
    authorEmail: 'Email (Opcional)',
    authorInstitution: 'Institución (Opcional)',
    authorRole: 'Rol (Opcional)',
    eventInformation: 'Datos básicos del evento',
    extraEventInformation: 'Información adicional del evento',
    eventAbout: '¿De qué trata el evento?',
    eventDescription: 'Descripción corta (*)',
    eventContent: 'Contenido',
    eventSessions: 'Fechas del evento',
    startMoment: 'Hora de inicio',
    endMoment: 'Hora de fin',
    publicationStatus: 'Estado de publicación',
    topics: 'Temas',
    topic: 'Tema',
    projectInformation: 'Datos básicos del proyecto',
    dynamicMap: 'Mapa dinámico',
    staticMap: 'Mapa estático',
    projectDocuments: 'Documentos',
    projectImages: 'Imágenes',
    projectLinks: 'Enlaces',
    sources: 'Financiamiento',
    owners: 'Responsables',
    auditInformation: 'Auditoría',
    projectSections: 'Tramos',
    projectFunding: 'Financiamiento',
    galleryItems: 'Items de galeria',
    infrastructure: 'Infraestructura',
    scope: 'Alcance',
    ilat: 'ILAT',
    general: 'General',
    projectStatus: 'Estado del proyecto',
    comments: 'Comentarios'
  },
  auth: {
    credentialsError: 'Credenciales erroneas'
  },
  provider: {
    invalidAction: 'Acción invalida'
  },
  common: {
    actions: 'Acciones',
    starred: 'Destacado',
    notStarred: 'No Destacado',
    draft: 'Borrador',
    published: 'Publicado',
    videoLink: 'Ver video',
    filters: {
      creationDateRangeStart: 'Creación desde',
      creationDateRangeEnd: 'Creación hasta',
      durationDateRangeStart: 'Fecha desde',
      durationDateRangeEnd: 'Fecha hasta'
    },
    contentTypes: {
      event: 'Evento',
      project: 'Proyecto',
      energy: 'Energía',
      transport: 'Transporte y logística',
      boundary: 'Fronteras',
      telecommunications: 'Telecomunicaciones',
      article: 'Artículo',
      workshop: 'Taller'
    },
    eventTypes: {
      workshop: 'Taller',
      meeting: 'Webinar',
      conference: 'Conferencia',
      training: 'Capacitación',
      seminar: 'Seminario'
    },  
    areaTypes: {
      nacional: 'Nacional',
      binacional: 'Binacional'
    },
    sections: {
      event: 'Eventos',
      project: 'Proyectos',
      all: 'Home',
      article: 'Contenidos'
    },
    topics: {
      bim: 'BIM',
      blockchain: 'Blockchain',
      artificialintelligence: 'Inteligencia Artificial',
      iot: 'Internet de las Cosas',
      bigdata: 'Big Data'
    },
    image: {
      epigraph: 'Epígrafe'
    }
  },
  buttons: {
    draft: 'Marcar como borrador',
    publish: 'Publicar',
    edit: 'Editar'
  },
  errors: {
    fileUpload: 'Hubo un error al subir los archivos, por favor intenta nuevamente.',
    maxLength: 'Máximo %{count} caracteres',
    required: 'Campo requerido',
    email: 'Ingrese un email válido',
    date: `Ingrese una fecha válida en el formato ${DATE_FORMAT_PLACEHOLDER}`,
    duplicateCountries: 'Hay países repetidos'
  },
  ra: {
    auth: {
      user_menu: 'Perfil', // eslint-disable-line camelcase
      logout: 'Cerrar sesión'
    }
  },
  login: {
    Username: 'Nombre de usuario',
    Password: 'Contraseña',
    Continue: 'Continuar'
  },
  userType: {
    administrator: 'Admin',
    editor: 'Editor'
  }
};
