import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';

import { truncate as truncateStyle } from '~constants/styles';

function ListField({ className, source, record = {}, truncate, classes, renderItem = item => item }) {
  const content = record[source] && typeof record[source] === 'object' && record[source].map(renderItem).join(', ');
  return (
    content && (
      <Typography title={content} className={`${className} ${!!truncate && classes.truncate}`}>
        {content}
      </Typography>
    )
  );
}

ListField.propTypes = {
  source: PropTypes.string.isRequired,
  classes: PropTypes.shape({
    truncate: PropTypes.string
  }),
  className: PropTypes.string,
  record: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  render: PropTypes.func,
  truncate: PropTypes.bool
};

export default withStyles(truncateStyle)(ListField);
