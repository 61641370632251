import React, { Component } from 'react';
import { AutocompleteArrayInput, REDUX_FORM_NAME } from 'react-admin';
import { Multiselect } from 'multiselect-react-dropdown';

class CustomAutocompleteInput extends Component {

    showError = null;
    rendered = false;

    multiselectStyle = { 
        chips: { 
            backgroundColor: "#e0e0e0",
            color: "#1d1d1d"
        }, 
        searchBox: { 
            border: "none", 
            borderBottom: "1px solid #949494", 
            borderRadius: "0px"
        }
    }

    CA_names = [
        'guatemala',
        'belice',
        'el salvador',
        'honduras',
        'nicaragua',
        'costa rica',
        'panama'
    ];

    SA_names = [
        'ecuador',
        'brasil',
        'argentina',
        'colombia',
        'peru',
        'bolivia',
        'paraguay',
        'chile',
        'uruguay',
        'venezuela',
        'guyana',
        'guyana francesa',
        'suriname'
    ];

    CAR_names = [
        'bahamas',
        'barbados',
        'belice',
        'haiti',
        'republica dominicana',
        'trinidad y tobago'
    ]

    onChange = (value) => {
        let ids = this.handleShortcuts(value.map(v=>v.id));
        this.props.change(REDUX_FORM_NAME, this.props.source, ids);
        if(!ids || ids.length == 0) {
            this.showError = this.props.required;
        } else {
            this.showError = false;
        }
    }

    handleShortcuts = (ids) => {
        let newIds = ids;
        if(ids.filter(i=>i==-1).length!=0) {
            newIds = [];
            this.props.choices.forEach(c => {
                if(c.id >= -5 && c.id <= -1) {
                    newIds.push(c.id)
                }
            });
        } else if (ids.filter(i=>i==-2).length!=0) {
            newIds = this.handleRegionShortcut(ids,this.SA_names,-2);
        } else if (ids.filter(i=>i==-3).length!=0) {
            newIds = this.handleRegionShortcut(ids,this.CA_names,-3);
        } else if (ids.filter(i=>i==-4).length!=0) {
            newIds = this.handleRegionShortcut(ids,this.CAR_names,-4);
        } else if (ids.filter(i=>i==-5).length!=0) {
            let namesList = this.SA_names.concat(this.CA_names,this.CAR_names);
            namesList.push('mexico');
            newIds = this.handleRegionShortcut(ids,namesList,-5);
        }
        return newIds;
    }

    handleRegionShortcut = (ids, names,regionId) => {
        let regionChoices = this.props.choices.filter(c=>names.indexOf(c.name)!=-1)
        let regionIds = regionChoices.map(c=>c.id);
        let newIds = ids.filter(i=>i!=regionId && regionIds.indexOf(i)==-1);
        newIds.push(...regionIds);
        return newIds;
    }

    render() {
        let selected = [];
        if(this.props.choices && this.props.value) {
            selected = this.props.choices.filter(c => this.props.value.indexOf(c.id) > -1)
        }
        if(!this.rendered && this.props.choices) {
            this.rendered = true;
        } else if (this.props.choices) {
            if(selected.length==0 && this.showError != null) {
                this.showError = this.props.required;
            } else {
                this.showError = false;
            }
        }
        
        if(this.props.source.includes('countries')
            && this.props.choices
            && this.props.choices.filter(c=>c.id==-1).length==0) {
            
            this.props.choices.unshift({id:-5, name:'América Latina y el Caribe (Atajo)'});
            this.props.choices.unshift({id:-4, name:'Caribe (Atajo)'});
            this.props.choices.unshift({id:-3, name:'América central (Atajo)'});
            this.props.choices.unshift({id:-2, name:'América del sur (Atajo)'});
            this.props.choices.unshift({id:-1, name:'Seleccionar todos (Atajo)'});          

        }

        return(

            this.props.choices ? 
            <>
                <br/>
                <Multiselect
                    options={this.props.choices}
                    onSelect={this.onChange}
                    onRemove={this.onChange}
                    displayValue="name"
                    selectedValues={selected}
                    placeholder={this.props.title}
                    style={this.multiselectStyle}/>

                {this.showError ?
                    <>
                        <span class="autocomplete-form-error">
                            { this.props.error ?
                                this.props.error : "Debe ingresar por lo menos un país"
                            }
                        </span>
                    </>
                    :
                    <></>
                }                

                <div style={{display: 'none'}}>
                    <AutocompleteArrayInput 
                        source={this.props.source} 
                        choices={this.props.choices}/>
                </div>
            </>
            :
            <></>
        )
    }
}

export default CustomAutocompleteInput;
