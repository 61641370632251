import React from 'react';
import { SelectInput, FormDataConsumer } from 'react-admin';
import { addField } from 'ra-core';

const NestedSelectInput = props => {
  // eslint-disable-next-line react/prop-types
  const { source, fstSource, sndSource, fstLabel, sndLabel, fstChoices, getSndChoices, validate } = props;

  const getSndChoicesFromFirstValueId = valueID => {
    // eslint-disable-next-line react/prop-types
    const fstChoice = fstChoices.find(c => c.id === valueID);
    return fstChoice ? getSndChoices(fstChoice) : [];
  };

  // const displayNestedInput = formData =>
  //   formData[source] &&
  //   formData[source][fstSource] &&
  //   getSndChoicesFromFirstValueId(formData[source][fstSource]).length > 0;

  const nestedValidation = formData => {
    if (!formData[source] || !formData[source][sndSource]) {
      return;
    }
    if (!formData[source][fstSource]) {
      // eslint-disable-next-line react/prop-types
      props.input.onChange('');
      return;
    }
    if (
      getSndChoicesFromFirstValueId(formData[source][fstSource]).findIndex(
        c => c.id === formData[source][sndSource]
      ) < 0
    ) {
      // eslint-disable-next-line react/prop-types
      props.input.onChange({ [fstSource]: formData[source][fstSource] });
    }
  };

  function controlNestedInput(formData) {
    nestedValidation(formData);
    return true;
  }

  return (
    <>
      <SelectInput
        source={`${source}.${fstSource}`}
        choices={fstChoices}
        label={fstLabel}
        className="colInput"
        validate={validate}
      />
      &nbsp;
      <FormDataConsumer>
        {({ formData, ...rest }) =>
          formData &&
          controlNestedInput(formData) && (
            <SelectInput
              source={`${source}.${sndSource}`}
              choices={
                formData[source] && formData[source][fstSource]
                  ? getSndChoicesFromFirstValueId(formData[source][fstSource])
                  : []
              }
              label={sndLabel}
              className="colInput"
              validate={validate}
              {...rest}
            />
          )
        }
      </FormDataConsumer>
    </>
  );
};

export default addField(NestedSelectInput);
