import api, { apiSetup } from '../api';

apiSetup();

export class SourcesService {

    BASE_URL = '/backoffice/sources';

    get() {
        return api.get(this.BASE_URL);
    }
}
