import React, { Component, createRef } from 'react';
import { connect } from 'react-redux';
import { userLogout, translate } from 'react-admin';
import PropTypes from 'prop-types';

import Logout from './components/LogoutButton';
import styles from './styles.module.scss';
import logo from './assets/img_general_logo.svg';
import arrow from './assets/ic_general_arrow_down.svg';

class CustomAppBar extends Component {
  state = {
    showLogout: false
  };

  dropdownRef = createRef();

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside = event => {
    if (this.dropdownRef && !this.dropdownRef.current.contains(event.target)) {
      this.setState({ showLogout: false });
    }
  };

  handleToggleDropdown = () => {
    this.setState(prevState => ({ showLogout: !prevState.showLogout }));
  };

  render() {
    const { showLogout } = this.state;
    const { translate: t } = this.props;
    const userTypeTranslation = `userType.${localStorage.getItem('userType')}`;

    return (
      <div className={styles.menu}>
        <img className={styles.logo} src={logo} />
        <div ref={this.dropdownRef}>
          <div onClick={this.handleToggleDropdown} className={styles.user}>
            <div>
              <h2 className={`${styles.name} ${styles.profile}`}>{localStorage.getItem('name')}</h2>
              <h2 className={`${styles.type} ${styles.profile}`}>{t(userTypeTranslation)}</h2>
            </div>
            <img className={styles.arrow} src={arrow} />
          </div>
          {showLogout && <Logout />}
        </div>
      </div>
    );
  }
}

CustomAppBar.propTypes = {
  translate: PropTypes.func.isRequired
};

export default connect(
  undefined,
  { userLogout }
)(translate(CustomAppBar));
