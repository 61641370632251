import React from 'react';
import { defaultListProps } from '~constants/config';
import { List, TextField } from 'react-admin';
import CustomDatagrid from '~components/CustomDatagrid';
import ActionsField from '~components/ActionsField';

function TipicList({ ...props }) {

    return (
        <List {...defaultListProps} {...props}>
            <CustomDatagrid rowClick="edit">
                <TextField source="id" />
                <TextField source="title" />
                
                <ActionsField />
            </CustomDatagrid>
        </List>
    )

}

export default TipicList;
