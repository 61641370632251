import moment from 'moment';

import { DATE_FORMAT_INPUT } from '~constants/dates';

const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // eslint-disable-line

const numericalFourDigitsRegex = /^[0-9]{4}$/;

const numericalTenDigitsRegex = /^[0-9]{10}$/;

const numericalDigitsRegex = /^([0-9]*)$/;

export const required = errorMessage => val => (val ? undefined : errorMessage);

export const minLength = (length, errorMessage) => val =>
  val && val.length >= length ? undefined : errorMessage;

export const maxLength = length => (value, allValues, { translate }) =>
  !value || value.length <= length ? undefined : translate('errors.maxLength', { count: length });

export const pattern = (patternVal, errorMessage) => (value, allValues, { translate }) =>
  !value || patternVal.test(value) ? undefined : translate(errorMessage);

export const numericalFourDigits = errorMessage => pattern(numericalFourDigitsRegex, errorMessage);

export const numericalTenDigits = errorMessage => pattern(numericalTenDigitsRegex, errorMessage);

export const numericalDigits = errorMessage => pattern(numericalDigitsRegex, errorMessage);

export const email = pattern(emailRegex, 'errors.email');

export const isValidDate = (value, allValues, { translate }) =>
  !value || moment(value, DATE_FORMAT_INPUT, true).isValid() ? undefined : translate('errors.date');

export const isRequired = (value, allValues, { translate }) =>
  value ? undefined : translate('errors.required');

export const hasNoDuplicateCountries = (value, allValues, { translate }) =>
  !value ||
  value
    .map(location => location.country)
    .every((country, index, countries) => countries.indexOf(country) === index)
    ? undefined
    : translate('errors.duplicateCountries');
