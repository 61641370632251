import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import styles from './styles';

function CustomImageField({ classes, record = '' }) {
  return <img className={classes.image} src={record.rawFile ? record.rawFile.preview : record} />;
}

CustomImageField.propTypes = {
  classes: PropTypes.shape({
    image: PropTypes.string
  }),
  record: PropTypes.oneOfType([PropTypes.shape(), PropTypes.string])
};

export default withStyles(styles)(CustomImageField);
