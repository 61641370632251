const titles = {
    title: 'Título',
    link: 'Enlace',
    title: 'Título',
    infrastructure: 'Sector',
    status: 'Etapa',
    area: 'Ámbito',
    subsector: 'Subsector',
    projectType: 'Tipo',
    objectives: 'Objetivos',
    content: 'Descripción',
    financing: 'Tipo de financiamiento',
    sources: 'Financiamiento',
    estimatedEndDate: 'Fin estimado',
    ambientalLicense: 'Licencia ambiental',
    image: 'Imagen',
    image_name: 'Nombre de imagen',
    projects: 'Proyectos relacionados',
    kml: 'Archivo KML',
    dynamicMapZoom: 'Nivel de zoom',
    kmlSimplified: 'Archivo KML',
    staticMapZoom: 'Nivel de zoom',
    documents: 'Documentos',
    images: 'Imágenes',
    countries: 'Países',
    createdDate: 'Creación',
    updatedDate: 'Última modificación',
    totalInvestment: 'Inversión total',
    institution: 'Institución',
    amount: 'Monto',
    firstName: 'Nombre',
    lastName: 'Apellido',
    email: 'Email',
    role: 'Cargo',
    phone: 'Teléfono',
    owners: 'Responsables',
    name: 'Nombre',
    file: 'Archivo',
    firstName: 'Nombre',
    lastName: 'Apellido',
}

export default titles;
