export const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

export const WHITE = '0xFFFFFF';
export const GRAY = '0x8F8F8F';
export const SILVER = '0xBEBEBE';
export const GALLERY = '0xEDEDED';
export const MINE_SHAFT = '0x3E3E3E';
export const MOUNTAIN_MEADOW = '0x18BC5C';
export const ORANGE = '0xFB552E';

export const MAP_STYLE = `style=element:geometry%7Ccolor:${WHITE}&
style=element:labels.icon%7Cvisibility:off&
style=element:labels.text.fill%7Ccolor:${MINE_SHAFT}&
style=element:labels.text.stroke%7Ccolor:${WHITE}&
style=feature:administrative.land_parcel%7Celement:labels.text.fill%7Ccolor:${SILVER}&
style=feature:poi%7Celement:geometry%7Ccolor:0xeeeeee&
style=feature:poi%7Celement:labels.text.fill%7Ccolor:0x757575&
style=feature:poi.park%7Celement:geometry%7Ccolor:0xe5e5e5&
style=feature:poi.park%7Celement:labels.text.fill%7Ccolor:0x9e9e9e&
style=feature:road%7Celement:geometry%7Ccolor:0xffffff&
style=feature:road.arterial%7Celement:labels%7Cvisibility:off&
style=feature:road.arterial%7Celement:labels.text.fill%7Ccolor:0x757575&
style=feature:road.highway%7Celement:geometry%7Ccolor:0xdadada&
style=feature:road.highway%7Celement:labels%7Cvisibility:off&
style=feature:road.highway%7Celement:labels.text.fill%7Ccolor:${MINE_SHAFT}&
style=feature:road.local%7Cvisibility:off&
style=feature:road.local%7Celement:labels.text.fill%7Ccolor:0x9e9e9e&
style=feature:transit.line%7Celement:geometry%7Ccolor:0xe5e5e5&
style=feature:transit.station%7Celement:geometry%7Ccolor:0xeeeeee&
style=feature:water%7Celement:geometry%7Ccolor:${GALLERY}&
style=feature:water%7Celement:labels.text.fill%7Ccolor:0x9e9e9e&
style=feature:administrative.country%7Celement:geometry.stroke%7Ccolor:${GRAY}&
style=feature:administrative.province%7Celement:geometry.stroke%7Ccolor:${GRAY}&
style=feature:administrative.locality%7Cvisibility:on`;

export const MAX_GOOGLE_MAPS_ZOOM = 15;
export const DEFAULT_GOOGLE_MAPS_ZOOM = 8;
export const DISTANCE_POWER = 1.7;
export const DISTANCE_MULTIPLIER = 90;
