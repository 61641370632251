import React from 'react';
import PropTypes from 'prop-types';
import { Labeled } from 'react-admin';

import { infraestructureChoices } from '~constants/fieldGroups';

const InfrastructureField = ({ source, record = {} }) => {
    return (
        <Labeled label="fieldGroups.infrastructure">
            <span style={{fontSize:'14px'}}>{translateInfra(record[source])}</span>
        </Labeled>
    );
};

function translateInfra(infrastructures) {
    let text = '';
    infrastructures?.forEach(i => {
      if(text != '') {
        text += ', ';
      }
      text += infraestructureChoices.filter(c => c.id == i)[0].name;
    });
    return text;
}

InfrastructureField.propTypes = {
    record: PropTypes.object,
    source: PropTypes.string.isRequired,
};

export default InfrastructureField;
