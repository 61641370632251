import React from 'react';
import PropTypes from 'prop-types';
import { translate } from 'ra-core';

function FileLinkAndNameField({ record, translate: t, name }) {
  if (!record) {
    return null;
  }

  return record.rawFile ? (
    <span>{record.rawFile.name}</span>
  ) : name ? (
    <a href={record} target="_blank" rel="noopener noreferrer">
      {name}
    </a>
  ) : <></>;
}

FileLinkAndNameField.propTypes = {
  translate: PropTypes.func.isRequired,
  record: PropTypes.string
};

export default translate(FileLinkAndNameField);
