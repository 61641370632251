import React from 'react';
import PropTypes from 'prop-types';
import Chip from '@material-ui/core/Chip';
import { withStyles } from '@material-ui/core/styles';
import { translate } from 'react-admin';

import styles from './styles';

function TranslatedChipField({ translate: t, record = {}, source, classes, translatePrefix }) {
  const label = record[source].toLowerCase();
  return !!label && <Chip className={classes.chip} label={t(`${translatePrefix}.${label}`)} />;
}

TranslatedChipField.propTypes = {
  classes: PropTypes.shape({
    chip: PropTypes.string.isRequired
  }).isRequired,
  source: PropTypes.string.isRequired,
  record: PropTypes.shape()
};

export default withStyles(styles)(translate(TranslatedChipField));
