/* eslint-disable max-statements */
import { statusChoices, infraestructureChoices, financingChoices } from 'constants/fieldGroups';

import moment from 'moment';
import { camelCase } from 'lodash';
import snakecase from 'lodash.snakecase';

import { RESOURCES } from '~constants/config';
import { DATE_FORMAT_INPUT, TIME_FORMAT, DATE_TIME_FORMAT } from '~constants/dates';
import { snakecaseObject } from '~utils/snakecaseObject';
import { Document, Image, Link } from '~components/GalleryItemsInput/classes';

export const camelizeKeys = obj => {
  if (Array.isArray(obj)) {
    return obj.map(v => camelizeKeys(v));
  } else if (obj !== null && obj.constructor === Object) {
    return Object.keys(obj).reduce(
      (result, key) => ({
        ...result,
        [camelCase(key)]: camelizeKeys(obj[key])
      }),
      {}
    );
  }
  return obj;
};

export const parseResponse = rawResponse => ({
  data: rawResponse.data.page,
  total: rawResponse.data.totalCount
});

const parseArticle = data => ({
  ...data,
  materialDate: data.materialDate && moment.utc(data.materialDate).format(DATE_FORMAT_INPUT)
});

export const formatArticleUpdate = data => ({
  title: data.title,
  description: data.description,
  articleType: data.articleType,
  authorAttributes: data.author,
  content: data.content,
  locations: data.locations,
  topics: JSON.stringify(data.topics),
  infrastructure: JSON.stringify(data.infrastructure),
  originalSource: data.originalSource,
  sourceUrl: data.sourceUrl,
  materialDate: data.materialDate,
  starredAt: data.starredAt,
  epigraph: data.epigraph,
  videoUrl: data.videoUrl,
  sortBy: data.sortBy,
  countries: data.countries,
  slug: data.slug
});

export const formatArticleCreate = data => ({
  ...formatArticleUpdate(data),
  publicationStatus: 'draft'
});

const parseTime = date => moment.utc(date).format(TIME_FORMAT);

const parseEventSessions = sessions =>
  sessions.map(session => ({
    date: moment.utc(session.startMoment).format(DATE_FORMAT_INPUT),
    startTime: parseTime(session.startMoment),
    endTime: parseTime(session.endMoment)
  }));

const parseEvent = data => ({
  id: data.id,
  title: data.title,
  content: data.content,
  eventType: data.eventType,
  infrastructure: data.infrastructure,
  city: data.city,
  description: data.description,
  organizer: data.organizer,
  eventLink: data.eventLink,
  topics: data.topics,
  location: data.location,
  starredAt: data.starredAt,
  eventSessions: parseEventSessions(data.eventSessions),
  images: data.images,
  documents: data.documents,
  editable: data.editable,
  publicationStatus: data.publicationStatus,
  publishable: data.publishable,
  slug: data.slug
});

const formatDateTime = (date, time) =>
  moment.utc(`${date} ${time ? time : ''}`, DATE_TIME_FORMAT).toISOString();

const formatEventSessions = sessions =>
  sessions.map(session => ({
    startMoment: formatDateTime(session.date, session.startTime),
    endMoment: formatDateTime(session.date, session.endTime)
  }));

export const formatEventUpdate = data => ({
  title: data.title,
  content: data.content,
  eventType: data.eventType,
  infrastructure: JSON.stringify(data.infrastructure),
  city: data.city,
  description: data.description,
  organizer: data.organizer,
  eventLink: data.eventLink,
  topics: data.topics,
  location: data.location,
  starredAt: data.starredAt,
  eventSessionsAttributes: formatEventSessions(data.eventSessions),
  imagesAttributes: data.images,
  documentsAttributes: data.documents,
  slug: data.slug
});

export const formatEventCreate = data => ({
  ...formatEventUpdate(data),
  publicationStatus: 'draft'
});

export const formatTopicCreate = data => ({
  title: data.title
});

const formatDate = date => {
  const formattedDate = date.split('T')[0];
  return moment.utc(formattedDate, 'YYYY-MM-DD').format('DD-MM-YYYY');
};

export const parseProject = data => ({
  ...data,
  dynamic_map_lat: getDynamicCenter(data) ? getDynamicCenter(data).latitude : null,
  dynamic_map_lng: getDynamicCenter(data) ? getDynamicCenter(data).longitude : null,
  dynamic_map_zoom: data.dynamic_map_zoom ? data.dynamic_map_zoom : data.dynamicMapZoom,
  static_map_lat: getStaticCenter(data) ? getStaticCenter(data).latitude : null,
  static_map_lng: getStaticCenter(data) ? getStaticCenter(data).longitude : null,
  static_map_zoom: data.static_map_zoom ? data.static_map_zoom : data.staticMapZoom,
  estimated_end_date: data.estimatedEndDate
    ? formatDate(data.estimatedEndDate)
    : data.estimated_end_date
    ? formatDate(data.estimated_end_date)
    : '',
  project_countries: data.projectCountries
    ? data.projectCountries
    : data.project_countries
    ? data.project_countries
    : [],
  image_name: data.imageName ? data.imageName : data.image_name,
  owners: data.owners ? parseOwners(data.owners) : [],
  ambiental_license: data.ambientalLicense ? data.ambientalLicense : data.ambiental_license,
  project_gallery_items: data.projectGalleryItems
    ? data.projectGalleryItems
    : data.project_gallery_items
    ? data.project_gallery_items
    : null
});

export const parseIirsaProject = data => {
  const snakecasedata = snakecaseObject(data);
  return {
    ...snakecasedata,
    id: snakecasedata.code,
    is_portfolio: !!snakecasedata.is_portfolio,
    is_bid: !!snakecasedata.is_bid,
    is_caf: !!snakecasedata.is_caf,
    is_fonplata: !!snakecasedata.is_fonplata,
    accompaniment: !!snakecasedata.accompaniment,
    is_anchor: !!snakecasedata.is_anchor,
    has_ambiental_license: !!snakecasedata.has_ambiental_license,
    iirsa_status: snakecasedata.iirsa_status?.id,
    iirsa_stage: snakecasedata.iirsa_stage?.id,
    iirsa_countries: snakecasedata.iirsa_countries.map(c => c.id),
    iirsa_sector: {
      id: snakecasedata.iirsa_sector?.id,
      iirsa_subsector_id: snakecasedata.iirsa_subsector?.id
    },
    iirsa_project_type: snakecasedata.iirsa_project_type?.id,
    iirsa_scope: snakecasedata.iirsa_scope?.id,
    iirsa_studies_state: snakecasedata.iirsa_studies_state?.id,
    iirsa_studies_funder: snakecasedata.iirsa_studies_funder?.id,
    iirsa_project_funding: snakecasedata.iirsa_project_funding.map(e => ({
      amount: e.amount,
      funder: e.funder?.id,
      funding_status: e.funding_status?.id,
      funding_type: e.funding_type?.id
    })),
    iirsa_segments: snakecasedata.iirsa_segments.map(({ stage, ...e }) => {
      e.iirsa_stage = stage?.id || stage;
      return e;
    })
  };
};

const parseOwners = owners => {
  owners.forEach(o => {
    (o['first_name'] = o.firstName ? o.firstName : o.first_name),
      (o['last_name'] = o.lastName ? o.lastName : o.last_name);
  });
  return owners;
};

const getDynamicCenter = data => {
  if (data.kml) {
    return data.dynamic_center ? data.dynamic_center : data.dynamicCenter;
  }
  return null;
};

const getStaticCenter = data => {
  if (data.kml_simplified) {
    return data.static_center ? data.static_center : data.staticCenter;
  }
  return null;
};

export const formatCreateProject = data => ({
  title: data.title,
  infrastructure: data.infrastructure,
  objectives: data.objectives,
  status: data.status,
  cities: data.cities,
  area: data.area,
  kml: data.kml,
  kml_simplified: data.kml_simplified,
  estimated_end_date: data.estimated_end_date ? formatDate(data.estimated_end_date) : '',
  slug: data.slug
});

export const formatCreateIirsaProject = data => ({
  title: data.title,
  // infrastructure: data.infrastructure,
  // objectives: data.objectives,
  // status: data.status,
  cities: data.cities
  // area: data.area,
  // kml: data.kml,
  // kml_simplified: data.kml_simplified,
  // estimated_end_date: data.estimated_end_date ? formatDate(data.estimated_end_date) : '',
  // slug: data.slug
});

export const formatUpdateProject = data => ({
  ...formatCreateProject(data),
  id: data.id
});

export const formatProjectFormData = data => {
  let links = [];
  if (data.gallery_items) {
    links = getLinks(data.gallery_items);
    data.gallery_items = formatGalleryItems(data.gallery_items);
  } else {
    data.gallery_items = [];
  }
  return {
    infrastructure: data.infrastructure,
    title: data.title,
    objectives: data.objectives,
    status: data.status,
    cities: data.cities,
    area: data.area,
    dynamic_map_lat: data.dynamic_map_lat,
    dynamic_map_lng: data.dynamic_map_lng,
    dynamic_map_zoom: data.dynamic_map_zoom,
    static_map_lat: data.static_map_lat,
    static_map_lng: data.static_map_lng,
    static_map_zoom: data.static_map_zoom,
    subsector: data.subsector,
    project_type: data.project_type,
    objectives: data.objectives,
    content: data.content,
    ambiental_license: data.ambiental_license,
    financing: data.financing,
    estimated_end_date: data.estimated_end_date,
    projects: data.projects,
    project_countries: data.project_countries,
    bank: data.bank,
    links: JSON.stringify(links),
    sources: data.sources ? JSON.stringify(data.sources) : null,
    owners: data.owners ? JSON.stringify(data.owners) : null,
    kml: data.kml?.rawFile ? data.kml.rawFile : data.kml,
    kml_simplified: data.kml_simplified?.rawFile ? data.kml_simplified.rawFile : data.kml_simplified,
    image: data.image?.rawFile,
    image_name: data.image?.rawFile?.name,
    situation: data.situation,
    sections: data.sections ? JSON.stringify(data.sections) : null,
    url: data.url ? data.url : '',
    slug: data.slug ? data.slug : ''
  };
};

// eslint-disable-next-line complexity
export const formatIirsaProjectFormData = data => {
  let links = [];
  if (data.gallery_items) {
    data.project_gallery_items = formatGalleryItems(data.gallery_items);
    links = getLinks(data.project_gallery_items);
  } else {
    data.project_gallery_items = [];
  }
  const request = {
    is_portfolio: !!data.is_portfolio,
    is_bid: !!data.is_bid,
    is_caf: !!data.is_caf,
    is_fonplata: !!data.is_fonplata,
    accompaniment: !!data.accompaniment,
    is_anchor: !!data.is_anchor,
    has_ambiental_license: !!data.has_ambiental_license,
    studies_finished: !!data.studies_finished,
    code: data.code,
    name: data.name,
    iirsa_sector: data.iirsa_sector.id,
    iirsa_subsector: data.iirsa_sector.iirsa_subsector_id,
    goal: data.goal,
    description: data.description,
    iirsa_stage: data.iirsa_stage,
    iirsa_countries: data.iirsa_countries,
    iirsa_project_type: data.iirsa_project_type,
    iirsa_scope: data.iirsa_scope,
    iirsa_status: data.iirsa_status,
    status_comments: data.status_comments,
    studies_amount: data.studies_amount,
    studies_status_comments: data.studies_status_comments,
    studies_funding_comments: data.studies_funding_comments,
    iirsa_studies_state: data.iirsa_studies_state,
    iirsa_studies_funder: data.iirsa_studies_funder,
    funding_comments: data.funding_comments,
    kml: data.kml?.rawFile ? data.kml.rawFile : data.kml,
    kml_simplified: data.kml_simplified?.rawFile ? data.kml_simplified.rawFile : data.kml_simplified,
    dynamic_center_latitude: data.dynamic_center_latitude,
    dynamic_center_longitude: data.dynamic_center_longitude,
    dynamic_map_zoom: data.dynamic_map_zoom,
    static_center_latitude: data.static_center_latitude,
    static_center_longitude: data.static_center_longitude,
    static_map_zoom: data.static_map_zoom,
    iirsa_segments: JSON.stringify(data.iirsa_segments),
    iirsa_project_funding: JSON.stringify(data.iirsa_project_funding),
    links: JSON.stringify(links)
  };
  for (const key in request) {
    if (request[key] === null || request[key] === undefined) {
      delete request[key];
    }
  }
  return request;
};

const formatGalleryItems = galleryItems =>
  galleryItems.map(gi => {
    let newItem = null;
    switch (gi.type) {
      case 'Document':
        newItem = new Document();
        newItem.populate(gi);
        break;
      case 'Image':
        newItem = new Image();
        newItem.populate(gi);
        break;
      case 'Link':
        newItem = new Link();
        newItem.populate(gi);
        break;
      default:
        newItem = { ...gi };
        break;
    }
    newItem.id = gi.model_id;
    newItem.model_id = null;
    return newItem;
  });

const getLinks = galleryItems => {
  const links = new Array();
  galleryItems.forEach(gi => {
    if (gi instanceof Link) {
      links.push(gi);
    }
  });
  return links;
};

export const formatProjectResponse = data =>
  data.map(item => {
    return {
      ...item,
      status: statusChoices.filter(c => c.id == item.status)[0].name,
      infrastructure: infraestructureChoices.filter(c => c.id == item.infrastructure)[0].name
    };
  });

export const formatIirsaProjectResponse = data =>
  data.map(item => {
    return {
      ...item,
      id: item.code
      // status: statusChoices.filter(c => c.id == item.status)[0].name,
      // infrastructure: infraestructureChoices.filter(c => c.id == item.infrastructure)[0].name
    };
  });

export const formatShowProject = data => ({
  ...data,
  status: statusChoices.filter(c => c.id == data.status)[0].name,
  infrastructure: infraestructureChoices.filter(c => c.id == data.infrastructure)[0].name
});

export const formatShowIirsaProject = data => {
  const parsedData = {
    is_portfolio: !!data.isPortfolio,
    is_bid: !!data.isBid,
    is_caf: !!data.isCaf,
    is_fonplata: !!data.isFonplata,
    accompaniment: !!data.accompaniment,
    is_anchor: !!data.isAnchor,
    has_ambiental_license: !!data.hasAmbientalLicense
  };
  for (const key in data) {
    parsedData[snakecase(key)] = data[key];
  }
  parsedData.id = parsedData.code;
  return parsedData;
};

export const formatSearchResponse = data =>
  data.map(item => {
    return {
      ...item,
      infrastructure: setChoicesField(item.infrastructure, infraestructureChoices),
      financing: setChoicesField(item.financing, financingChoices),
      stage: setChoicesField(item.stage, statusChoices),
      topic: setChoicesField(item.topic, topicChoices),
      type_filter: setChoicesField(item.typeFilter, contentTypeChoices)
    };
  });

const setChoicesField = (strValue, choices) => {
  if (strValue) {
    const value = strValue.split(',');
    let newValue = '';
    value.forEach(v => {
      const ch = choices.filter(c => c.id == v);
      if (ch.length > 0) {
        if (newValue != '') {
          newValue += ', ';
        }
        newValue += ch[0].name;
      }
    });
    return newValue;
  }
};

export const parseResourceDetail = {
  [RESOURCES.articles]: parseArticle,
  [RESOURCES.events]: parseEvent,
  [RESOURCES.projects]: parseProject,
  iirsaProjects: parseIirsaProject,
  [RESOURCES.topics]: data => data
};

export const formatCreateResource = {
  [RESOURCES.articles]: formatArticleCreate,
  [RESOURCES.events]: formatEventCreate,
  [RESOURCES.projects]: formatCreateProject,
  iirsaProjects: formatCreateIirsaProject,
  [RESOURCES.topics]: formatTopicCreate
};

export const formatUpdateResource = {
  [RESOURCES.articles]: formatArticleUpdate,
  [RESOURCES.events]: formatEventUpdate,
  [RESOURCES.projects]: formatUpdateProject
};

export const formatResponseData = {
  [RESOURCES.articles]: data => data,
  [RESOURCES.events]: data => data,
  [RESOURCES.projects]: formatProjectResponse,
  ['iirsaProjects']: formatIirsaProjectResponse,
  ['searchs']: formatSearchResponse,
  [RESOURCES.topics]: data => data
};

export const parseAudit = data => ({
  ...data,
  estimatedEndDate: data.estimatedEndDate ? formatDate(data.estimatedEndDate) : null,
  infrastructure: data.infrastructure
    ? infraestructureChoices.filter(c => c.id == data.infrastructure)[0].name
    : null,
  status: data.status ? statusChoices.filter(c => c.id == data.status)[0].name : null,
  ambientalLicense: data.ambientalLicense != null ? (data.ambientalLicense ? 'Sí' : 'No') : null
});

//Choices
export const topicChoices = [
  { id: 'bim', name: 'BIM' },
  { id: 'blockchain', name: 'Blockchain' },
  { id: 'artificialintelligence', name: 'Inteligencia Artificial' },
  { id: 'iot', name: 'Internet de las Cosas' },
  { id: 'bigdata', name: 'Big Data' }
];

export const contentTypeChoices = [
  { id: 'article', name: 'Artículo' },
  { id: 'infographic', name: 'Infografía' },
  { id: 'publishing', name: 'Publicación' },
  { id: 'video', name: 'Video' }
];
