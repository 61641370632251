import React, {Component} from 'react';
import {
  translate,
  SimpleForm,
  TextInput,
  SelectInput,
  SelectArrayInput,
  ArrayInput,
  SimpleFormIterator,
  ImageInput,
  BooleanInput,
  FileInput,
  FileField,
  FormDataConsumer
} from 'react-admin';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import RichTextInput from 'ra-input-rich-text';
import { change } from 'redux-form';
import { connect } from 'react-redux';

import {
  infraestructureChoices,
  topicChoices,
  eventTypeChoices
} from '~constants/fieldGroups';
import { DATE_FORMAT_PLACEHOLDER } from '~constants/dates';
import { normalizeDate } from '~utils/normalize';
import FieldGroup from '~components/FieldGroup';
import CustomImageField from '~components/CustomImageField';
import { isRequired, maxLength, isValidDate, minLength } from '~utils/inputValidations';
import { CountriesService } from '~config/services/countries.service';
import CustomAutocompleteSingleInput from '../../../components/CustomAutocompleteSingleInput';
import CustomAutocompleteInput from '../../../components/CustomAutocompleteInput';
import { SlugsService } from '~config/services/slugs.service';
import FileLinkAndNameField from '~components/FileLinkAndNameField';

import styles from './styles';
import { DESC_MAX_LENGTH } from './constants';
import {TopicsService} from "../../../../config/services/topics.service";
import ArticleTopicsInput from "../../../components/ArticleTopicsInput";

const toolbarOptions = [
  ['bold', 'italic', 'underline', 'link'],
  [{ 'list': 'bullet' }]
];

class EventForm extends Component {

  state = { 
    cuntries: [],
    slugs: [],
    topics: []
  };

  constructor() {
    super();

    const cs = new CountriesService();
    cs.get().then(r => {
      let data = r.data;
      data.sort((a, b) => (a.name > b.name) ? 1 : -1);
      this.setState({ countries: data });
    });

    const ts = new TopicsService();
    ts.get().then(r => {
      this.setState({ topics:r.data });
    });

    const ss = new SlugsService();
    ss.get_from_event().then(r => {
      this.setState({ slugs: r.data });
    });
  }

  validateForm = (values) => {
    let errors = {};
    if(values['slug'] && this.state.slugs.indexOf(values.slug) > -1) {
      errors.slug = 'Este slug ya se encuentra en uso';
    }
    if(!values['location'] || !values['location']['country'] ) {
      errors['location'] = {};
      errors['location']['country'] = 'Debe seleccionar un país';
    }
    if(!values['infrastructure'] || values['infrastructure'].length == 0) {
      errors.infrastructure = 'Debe seleccionar por lo menos un tipo de infraestructura'
    }
    return errors;
  }

  render() {
    return (
      <SimpleForm {...this.props} validate={this.validateForm}>
        {this.children}
        <FieldGroup label="fieldGroups.eventInformation" formClassName={this.props.classes.borderBottom}>
          <TextInput source="title" validate={isRequired} />
          <TextInput source="slug"/>
          <label style={styles.inputLabel}>Descripción corta (*)</label>
          <div style={styles.richContainer}>
            <RichTextInput 
              source="description"
              toolbar={toolbarOptions}
              validate={[isRequired, maxLength(DESC_MAX_LENGTH)]}
            />
          </div>
          <label style={styles.inputLabel}>Contenido (*)</label>
          <div style={styles.richContainer}>
            <RichTextInput 
              source="content"
              toolbar={toolbarOptions}
              validate={isRequired}
            />
          </div>
          <SelectInput source="eventType" choices={eventTypeChoices} validate={isRequired} />
          
          
          <FormDataConsumer>
            {({formData}) => (
              <CustomAutocompleteInput
                choices={infraestructureChoices}
                value={formData.infrastructure}
                change={this.props.change}
                title={'Tipos de Infraestructura (*)'}
                source={'infrastructure'}
                error={'Debe seleccionar por lo menos un tipo de infraestructura'}
                required={true}/>
            )}
          </FormDataConsumer>

          <FormDataConsumer>
            {({formData}) => (
                <ArticleTopicsInput
                    value={formData?.topics}
                    choices={this.state.topics}
                    change={this.props.change}
                    source={'topics'}/>
            )}
          </FormDataConsumer>

          <ArrayInput source="eventSessions" validate={isRequired}>
            <SimpleFormIterator>
              <TextInput
                source="date"
                label="resources.events.fields.sessionDate"
                normalize={normalizeDate}
                placeholder={DATE_FORMAT_PLACEHOLDER}
                validate={[isRequired, isValidDate]}
              />
              <TextInput
                source="startTime"
                label="resources.events.fields.sessionStartTime"
                type="time"
                InputLabelProps={{
                  shrink: true
                }}
              />
              <TextInput
                source="endTime"
                label="resources.events.fields.sessionEndTime"
                type="time"
                InputLabelProps={{
                  shrink: true
                }}
              />
            </SimpleFormIterator>
          </ArrayInput>
          <TextInput source="location.city" />

          <div>
            <FormDataConsumer>
              {({formData}) => (
                <CustomAutocompleteSingleInput
                  choices={this.state.countries}
                  value={formData?.location?.country}
                  change={this.props.change}
                  title={'País (*)'}
                  source={'location.country'}
                  required={true}/>
              )}
            </FormDataConsumer>
          </div>
          
          <ArrayInput source="images" validate={[isRequired, minLength(1, 'Debe ingresar por lo menos una foto')]}>
            <SimpleFormIterator>
              <ImageInput
                source="file"
                accept="image/*"
                placeholder={<p>{this.props.translate('fieldGroups.dropImage')}</p>}
                validate={isRequired}
                label="resources.events.fields.imagesFields.image"
              >
                <CustomImageField />
              </ImageInput>
              <TextInput source="name" label="common.image.epigraph" />
            </SimpleFormIterator>
          </ArrayInput>
        </FieldGroup>
        <FieldGroup label="fieldGroups.extraEventInformation" formClassName={this.props.classes.borderBottom}>
          <TextInput source="organizer" />
          <TextInput source="eventLink" />
          <ArrayInput source="documents">
            <SimpleFormIterator>
              <FileInput source="file" label="resources.events.fields.document" validate={isRequired}>
                <FileLinkAndNameField name="Ver documento"/>
              </FileInput>
              <TextInput source="name" label="resources.events.fields.documentName" validate={isRequired} />
            </SimpleFormIterator>
          </ArrayInput>
        </FieldGroup>
        <FieldGroup label="fieldGroups.contentConfiguration" formClassName={this.props.classes.borderBottom}>
          <BooleanInput source="starredAt" label="fieldGroups.starred" />
        </FieldGroup>
      </SimpleForm>
    );
  }

}

EventForm.propTypes = {
  translate: PropTypes.func.isRequired,
  classes: PropTypes.shape({
    borderBottom: PropTypes.string.isRequired
  })
};

const mapDispatchToProps = {change};

export default (withStyles(styles)(connect(state => ({}),mapDispatchToProps)(translate(EventForm))));
