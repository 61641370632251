const leftTrim = value => value.replace(/^\s+/, '');

const MAX_DAY = 31;
const MAX_MONTH = 12;

const DAY_START = 0;
const DAY_END = 2;
const MONTH_START = 2;
const MONTH_END = 4;
const YEAR_START = 4;
const YEAR_END = 8;

const formatNumber = (number, max) => (number > max ? max : number);

const getDay = onlyNums => formatNumber(onlyNums.slice(DAY_START, DAY_END), MAX_DAY);
const getMonth = onlyNums => formatNumber(onlyNums.slice(MONTH_START, MONTH_END), MAX_MONTH);
const getYear = onlyNums => onlyNums.slice(YEAR_START, YEAR_END);

export const normalizeDate = value => {
  let newValue = value;
  if (!newValue) {
    return newValue;
  }
  newValue = leftTrim(newValue);
  const onlyNums = newValue.replace(/[^\d]/g, '');
  if (onlyNums.length <= MONTH_START) {
    return getDay(onlyNums);
  }
  if (onlyNums.length <= YEAR_START) {
    return `${getDay(onlyNums)}-${getMonth(onlyNums)}`;
  }
  return `${getDay(onlyNums)}-${getMonth(onlyNums)}-${getYear(onlyNums)}`;
};
