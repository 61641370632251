import React, { Component } from 'react';
import { 
  Show, 
  TabbedShowLayout, 
  translate, 
  TextField, 
  BooleanField,
  UrlField,
  FormDataConsumer,
  Tab
} from 'react-admin';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import ReactHtmlParser from 'react-html-parser';

import FieldGroup from '~components/FieldGroup';
import ShowCustomActions from '~components/ShowCustomActions';
import styles from './styles';
import { AuditsService } from 'config/services/audits.service';
import AuditsDisplay from '../components/AuditsDisplay';
import './styles.scss';
import { financingChoices, subsectorChoices } from '../../../../constants/fieldGroups';

const VolverButton = (props) => (
  <>
    <Button style={styles.volverButton} onClick={(props.onVolverClick)} >
      <svg style={styles.chevron} viewBox="0 0 32 32" class="icon icon-chevron-left" viewBox="0 0 32 32">
        <path fill="#3f51b5" d="M14.19 16.005l7.869 7.868-2.129 2.129-9.996-9.997L19.937 6.002l2.127 2.129z"/>
      </svg>
      Volver
    </Button>
  </>
)

class ProjectShow extends Component {

  state = {
    auditData: {}
  }

  constructor(props) {
    super(props);
    let as = new AuditsService();
    as.get(props.id).then(ad => this.setState({auditData: ad.data}));
  }

  renderAuditsDisplay(data) {
    return (<AuditsDisplay data={data}/>)
  }

  onVolverClick = () => {
    this.props.history.push("/projects");
  }

  render() {
    return (

      <>

      <VolverButton onVolverClick={this.onVolverClick}/>
      
      <Show {...this.props} actions={<ShowCustomActions {...this.props} />}>
        <TabbedShowLayout>
            <Tab label="proyecto">
              <FieldGroup label="fieldGroups.projectInformation" formClassName={this.props.classes.borderBottom} show>
                <TextField source="title" />
                <TextField source="infrastructure" />
                <TextField source="status" />

                <label class="field-label">Ámbito</label>
                <FormDataConsumer>
                  {({formData}) => {
                    return (
                      <span class="field-value">
                        {formData.area.charAt(0) + formData.area.substring(1).toLowerCase()}
                      </span>
                    );
                  }}
                </FormDataConsumer>

                <br/>

                <label class="field-label">Subsector</label>
                <FormDataConsumer>
                  {({formData}) => {
                    return (
                      <span class="field-value">
                        {subsectorChoices.filter(f=>f.id==formData?.subsector)[0]?.name}
                      </span>
                    );
                  }}
                </FormDataConsumer>

                <TextField source="project_type" />

                <label class="field-label">Objetivos</label>
                <FormDataConsumer>
                  {({formData}) => {
                    return (
                      <span class="field-value">{ReactHtmlParser(formData?.objectives)}</span>
                    );
                  }}
                </FormDataConsumer>

                <br/>

                <label class="field-label">Descripción</label>
                <FormDataConsumer>
                  {({formData}) => {
                    return (
                      <span class="field-value">{ReactHtmlParser(formData?.content)}</span>
                    );
                  }}
                </FormDataConsumer>
                
                <br/>

                <label class="field-label">Tipo de financiamiento</label>
                <FormDataConsumer>
                  {({formData}) => {
                    return (
                      <span class="field-value">
                        {financingChoices.filter(f=>f.id==formData?.financing)[0]?.name}
                      </span>
                    );
                  }}
                </FormDataConsumer>

                <TextField source="estimated_end_date" />
                <BooleanField source="ambiental_license" />
                <UrlField source="image"/>
                <TextField source="image_name"/>
              </FieldGroup>
            </Tab>
            <Tab label="auditoría" path="auditoria">
              <FieldGroup label="fieldGroups.auditInformation" formClassName={this.props.classes.borderBottom} show>
                {this.state.auditData && this.state.auditData.length > 0 ?
                 this.state.auditData.map(this.renderAuditsDisplay) : null}
              </FieldGroup>
            </Tab>
        </TabbedShowLayout>
      </Show>

      </>

    );
  }
  
}

ProjectShow.propTypes = {
  classes: PropTypes.shape({
    borderBottom: PropTypes.string.isRequired
  })
};

export default withStyles(styles)(translate(ProjectShow));
