import React, { Component } from 'react';
import { REDUX_FORM_NAME, TextInput } from 'react-admin';
import { Multiselect } from 'multiselect-react-dropdown';
var accents = require('remove-accents');
import './styles.scss';

class CustomAutocompleteSingleInput extends Component {

    showError = false;
    rendered = false;

    multiselectStyle = { 
        chips: { 
            backgroundColor: "#FFF",
            color: "#1d1d1d",
            fontSize: '17px',
            marginBottom: '-11px',
            padding: '0px',
            display: 'flow-root',
        }, 
        searchBox: { 
            border: "none", 
            borderBottom: "1px solid #949494", 
            borderRadius: "0px"
        },
        option: {
            color: '#000'
        }
    }


    onChange = (value) => {
        let inputValue = null;
        if(value.length > 0) {
            inputValue = value[value.length-1].name;
            this.showError = false;
        } else {
            this.showError = this.props.required;
        }
        this.props.change(REDUX_FORM_NAME, this.props.source, inputValue);
    }

    format(str) {
        return accents.remove(str).toLowerCase();
    }

    render() {
        let selected = [];
        if(this.props.value && this.props.choices) {
            let choices = this.props.choices.filter(c=>this.format(c.name)==this.format(this.props.value));
            selected = [choices[0]];
        }

        if(!this.rendered && this.props.choices) {
            this.rendered = true;
        } else if (this.props.choices) {
            if(selected.length==0) {
                this.showError = this.props.required;
            } else {
                this.showError = false;
            }
        }

        return(

            this.props.choices ? 
            <>
                <br/>
                <div class="single-multiselect">
                    <Multiselect
                        options={this.props.choices}
                        onSelect={this.onChange}
                        onRemove={this.onChange}
                        displayValue="name"
                        selectedValues={selected}
                        placeholder={selected.length==0 ? this.props.title : ''}
                        style={this.multiselectStyle}
                        closeIcon=''/>
                </div>

                {this.showError ?
                    <>
                        <span class="autocomplete-form-error">Debe seleccionar país</span>
                    </>
                    :
                    <></>
                }                

                <div style={{display: 'none'}}>
                    <TextInput source={this.props.source} />
                </div>
            </>
            :
            <></>
        )
    }
}

export default CustomAutocompleteSingleInput;
