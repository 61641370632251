import { GET_LIST, GET_ONE, CREATE, UPDATE, DELETE } from 'react-admin';
import snakecase from 'lodash.snakecase';

import { RESOURCES, SEARCHS_SUMMARY } from '~constants/config';
import {
  formatCreateResource,
  parseResponse,
  parseResourceDetail,
  formatResponseData,
  formatShowProject,
  formatShowIirsaProject,
  formatProjectFormData,
  formatIirsaProjectFormData
} from '~utils/mappers';
import axios from 'axios';
import { getApiHeaders } from 'utils/helpers';
import { transformQueryParams, filterUnfinishedDateParams } from '~utils/object';

import api, { apiSetup } from '../api';
import { DELETE_MANY } from 'ra-core';
import { DocumentsService } from '~config/services/documents.service';
import { ImagesService } from '~config/services/images.service';
import { Document, Image } from '~components/GalleryItemsInput/classes';

apiSetup();

export const PUBLISH = 'publish';
export const DRAFT = 'draft';
export const SEARCHCSV = 'searchCSV';

export default (type, resource, params) => {
  switch (type) {
    case GET_LIST: {
      const shared_params = {
        ...filterUnfinishedDateParams(transformQueryParams(params.filter)),
        page: params.pagination.page,
        limit: params.pagination.perPage,
      };
      let getParams = {
        ...shared_params,
        [`order[${snakecase(params.sort.field)}]`]: params.sort.order
      }
      if(resource == RESOURCES.projects || resource == 'iirsaProjects') {
        getParams = {
          ...shared_params,
          sort: params.sort.field,
          order: params.sort.order
        };
      }
      return api
        .get(`/backoffice/${RESOURCES[resource]}`, getParams)
        .then(response => {
          if (response.ok) {
            if (resource === SEARCHS_SUMMARY) {
              response.data.page = response.data.page.map(item => ({
                ...item,
                id: `${item.date}${item.searchString}`
              }));
            } else {
              response.data.page = formatResponseData[resource](response.data.page);
            }
            return parseResponse(response);
          }
          throw response;
        });
    }
    case GET_ONE: {
      return api.get(`/backoffice/${RESOURCES[resource]}/${params.id}`).then(response => {
        if (response.ok) {
          let parser = parseResourceDetail[resource];
          if(resource == RESOURCES.projects && window.location.href.includes('/show')) {
            parser = formatShowProject;
          }
          if(resource == 'iirsaProjects' && window.location.href.includes('/show')) {
            parser = formatShowIirsaProject;
          }
          return { data: parser(response.data) };
        }
        throw response;
      });
    }
    case CREATE: {
      const api_url = `backoffice/${RESOURCES[resource]}`;
      if(resource == RESOURCES.projects) {
        const formData = createFormData(params.data);
        let baseUrl = process.env.REACT_APP_BASE_URL;
        let headers = getApiHeaders();
        let data;
        return axios.post(`${baseUrl}${api_url}`, formData, {headers: headers}).then(response => {
          if (response.statusText == 'OK') {
            data = response.data;
            return createItems(params.data.gallery_items, response.data.id, resource);
          }
          throw response;
        }).then(_ => {
          setTimeout(function(){ location.reload() }, 2000);
          return { data: data };
        });
      } else if (resource == 'iirsaProjects') {
        const formData = createIirsaProjectFormData(params.data);
        let baseUrl = process.env.REACT_APP_BASE_URL;
        let headers = getApiHeaders();
        let data;
        return axios.post(`${baseUrl}${api_url}`, formData, {headers: headers}).then(response => {
          if (response.statusText == 'OK') {
            data = response.data;
            return createItems(params.data.project_gallery_items, response.data.id, resource);
          }
          throw response;
        }).then(_ => {
          setTimeout(function(){ location.reload() }, 2000);
          return { data: {...data, id: data.code} };
        });
      } else if (resource == 'topics') {
        const creationParams = formatCreateResource[resource](params.data);
        return api.post(`/${api_url}`, creationParams);
      } else {
        const creationParams = formatCreateResource[resource](params.data);
        return api.post(`/${api_url}`, creationParams).then(response => {
          const docService = new DocumentsService();
          return docService.create(resource, params.data, response.data.id).then((r)=>{
            if (response.ok) {
              return { data: response.data };
            }
            throw response;
          });
        });
      }
    }
    case PUBLISH: {
      return api.patch(`/backoffice/${RESOURCES[resource]}/${params.id}/publish`);
    }
    case DRAFT: {
      return api.patch(`/backoffice/${RESOURCES[resource]}/${params.id}/draft`);
    }
    case SEARCHCSV: {
      return api.get('/backoffice/search_logs/summary.csv');
    }
    case DELETE: {
      return doDelete(resource, params.id);
    }
    case UPDATE: {
      const api_url = `backoffice/${RESOURCES[resource]}/${params.id}`;
      if(resource == RESOURCES.projects) {
        const formData = createFormData(params.data);
        let baseUrl = process.env.REACT_APP_BASE_URL;
        let headers = getApiHeaders();
        let data;
        return axios.put(`${baseUrl}${api_url}`, formData, {headers: headers}).then(response => {
          if (response.statusText == 'OK') {
            data = response.data;
            return createItems(params.data.gallery_items, response.data.id, resource);
          }
          throw response;
        }).then(_ => {
          return { data: data };
        });
      } else if (resource == 'iirsaProjects') {
        const formData = createIirsaProjectFormData(params.data);
        let baseUrl = process.env.REACT_APP_BASE_URL;
        let headers = getApiHeaders();
        let data;
        return axios.put(`${baseUrl}${api_url}`, formData, {headers: headers}).then(response => {
          if (response.statusText == 'OK') {
            data = response.data;
            return createItems(params.data.project_gallery_items, response.data.id, resource);
          }
          throw response;
        }).then(_ => {
          return { data: {...data, id: data.code} };
        });
      } else {
        const updateParams = formatCreateResource[resource](params.data);
        return api.put(`/${api_url}`, updateParams).then(response => {
          const docService = new DocumentsService();
          const promise = docService.create(resource, params.data, response.data.id);
          if (promise) {
            return promise.then((r) => {
              console.log('response ', response);
              if (response.ok) {
                return {data: response.data};
              }
              throw response;
            });
          } else {
            if (response.ok) {
              return {data: response.data};
            }
            throw response;
          }

        });
      }
    }
    case DELETE_MANY: {
      deleteMany(resource, params.ids[0], params.ids);
      return { data: [...params.ids] };
    }
    default:
      throw new Error('provider.invalidAction');
  }
};

function createFormData(params) {
  let formData = new FormData();
  let data = formatProjectFormData(params);
  Object.keys(data).forEach(key => formData.append(key, data[key]));
  return formData;
}

function createIirsaProjectFormData(params) {
  let formData = new FormData();
  let data = formatIirsaProjectFormData(params);
  Object.keys(data).forEach(key => formData.append(key, data[key]));
  return formData;
}

function createItems(items, id, resource) {
  let documents = items.filter(gi => gi instanceof Document);
  let images = items.filter(gi => gi instanceof Image);
  return uploadDocuments(id, documents, resource).then(_ => {
    return uploadImages(id, images, resource);
  });
}

async function uploadDocuments(id, documents, resource) {
  const docService = new DocumentsService();
  const currentDocs = new Array();
  for(let idx in documents) {
    let doc = documents[idx];
    if(!doc.id) {
      let response = await docService.create(resource,doc,id);
      currentDocs.push(response.data.id);
    } else {
      let response = await docService.update(doc, resource);
      currentDocs.push(response.data.id);
    }
  }
  return docService.deleteMany(currentDocs, id, resource === RESOURCES.projects ? 'Project' : 'iirsaProject');
}

async function uploadImages(id, images, resource) {
  const imgService = new ImagesService(resource);
  const currentImgs = new Array();
  for(let idx in images) {
    let img = images[idx]
    if(!img.id) {
      let response = await imgService.create(id, img);
      currentImgs.push(response.data.id)
    } else {
      let response = await imgService.update(img);
      currentImgs.push(response.data.id)
    }
  }
  return imgService.deleteMany(currentImgs, id);
}

function deleteMany(resource, id, idList) {
  let res = doDelete(resource, id);
  res.then(r => {
    let idx = idList.indexOf(id);
    if(idx < (idList.length-1)) {
      deleteMany(resource, idList[idx+1], idList);
    }
  });
}

function doDelete(resource, id) {
  return api.delete(`/backoffice/${RESOURCES[resource]}/${id}`).then(response => {
    if (response.ok) {
      return { data: { id: id } };
    }
    const message = response?.data?.error || response;
    throw message;
  });
}
