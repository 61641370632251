export const SECTIONS = [
  {
    name: 'sections',
    items: ['articles', 'events', 'projects', 'iirsaProjects']
  },
  {
    name: 'statistics',
    items: ['searchs', 'searchsSummary']
  },
  {
    name: 'settings',
    items: ['topics']
  }
];
