import React from 'react';
import PropTypes from 'prop-types';
import Chip from '@material-ui/core/Chip';
import { withStyles } from '@material-ui/core/styles';
import { translate } from 'react-admin';

import styles from './styles';

function TopicsField({ translate: t, record = {}, source, classes, fieldLabel }) {
  const topics = record[source];
  return (
    <>
      {fieldLabel ? <p className={classes.topicLabel}>{t(fieldLabel)}</p> : null}
      {!!topics &&
        topics.map(topic => (
          <Chip key={topic} className={classes.chip} label={topic} />
        ))}
    </>
  );
}

TopicsField.propTypes = {
  classes: PropTypes.shape({
    chip: PropTypes.string.isRequired,
    topicLabel: PropTypes.string.isRequired
  }).isRequired,
  fieldLabel: PropTypes.string.isRequired,
  source: PropTypes.string.isRequired,
  record: PropTypes.shape(),
  translate: PropTypes.func
};

export default withStyles(styles)(translate(TopicsField));
