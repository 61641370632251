import React, { Component } from 'react';
import styles from './styles';
import closeIcon from './assets/ic_general_close.svg';
import DynamicMap from '../DynamicMap';

class ModalMapPreview extends Component {

    state = {
        hidden: true
    }

    open = () => {
        this.setState({hidden: false});
    }

    close = () => {
        this.setState({hidden: true});
    }

    render() {

        return (
            <>
                <button type="button" onClick={this.open} style={{width: '100%'}}>Mostrar vista previa</button>
                <div style={this.state.hidden ? styles.hidden : styles.modal}>
                    <div style={styles.modalContainer}>
                        <div style={styles.modalHeader}>
                            <h3 style={styles.title}>Vista previa</h3>
                            <button style={styles.closeBtn} type="button" onClick={this.close}>
                                <img src={closeIcon} alt="" />
                            </button>
                        </div>
                        <div style={styles.modalBody}>
                            <DynamicMap
                                kml={this.props.formData.kml}
                                centerLat={this.props.formData.dynamic_map_lat}
                                centerLng={this.props.formData.dynamic_map_lng}
                                zoom={this.props.formData.dynamic_map_zoom}
                                infrastructure={this.props.formData.infrastructure}
                                dynamic={true}
                                modal={true}
                            />
                        </div>
                    </div>
                </div>
            </>
        );
        
    }

}

export default ModalMapPreview
