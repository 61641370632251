/* eslint-disable react/no-multi-comp */
import React, { Component, Fragment } from 'react';
import {
  List,
  TextField,
  translate,
  Filter,
  TextInput,
  SelectInput,
  Pagination,
  downloadCSV,
  BulkDeleteButton,
  AutocompleteArrayInput,
  BooleanInput
} from 'react-admin';
import { parse } from 'json2csv';

import CustomDatagrid from '~components/CustomDatagrid';
import { IirsaCountriesService } from '~config/services/iirsa_countries.service';

import {
  financingChoices,
  iirsaSectorChoices,
  iirsaStagesChoices,
  iirsaStatusChoices,
  studiesFunderChoices
} from '../../../../constants/fieldGroups';
import NestedSelectInput from '../components/NestedSelectInput';
import NumberBetweenInput from '../components/NumberBetweenInput';

class ProjectFilter extends Component {
  state = {
    countries: []
  };

  constructor() {
    super();
    const cs = new IirsaCountriesService();
    cs.get_la().then(r => this.setState({ countries: r.data }));
  }

  render() {
    return (
      <Filter {...this.props}>
        <TextInput source="code" label="Código" />
        <TextInput source="name" label="Nombre" />

        <NestedSelectInput
          label="Sector"
          source="sector"
          fstSource="id"
          sndSource="id_subsector"
          fstLabel="Sector"
          sndLabel="Subsector"
          fstChoices={iirsaSectorChoices}
          // eslint-disable-next-line react/jsx-no-bind
          getSndChoices={fstValue => fstValue.subsectors}
        />

        <SelectInput source="iirsa_funding_source" choices={studiesFunderChoices} label="Financiamiento" />
        <SelectInput source="iirsa_stage" choices={iirsaStagesChoices} label="Etapa" />
        <SelectInput source="iirsa_status" choices={iirsaStatusChoices} label="Estado" />
        <BooleanInput source="accompaniment" label="Acompañamiento" />

        <AutocompleteArrayInput
          label="Paises"
          source="iirsa_countries"
          choices={this.state.countries}
          sorteable={false}
        />

        <NumberBetweenInput source="iirsa_total_investment" label="Inversión Total" />
      </Filter>
    );
  }
}

const PostPagination = props => <Pagination rowsPerPageOptions={[50, 100]} {...props} />;

function formatProject(p) {
    delete p.starredAt;
    // delete p.kmlSimplified;
    delete p.dynamicMapZoom;
    delete p.staticMapZoom;
    delete p.dynamicCenterLatitude;
    delete p.dynamicCenterLongitude;
    delete p.dynamicCenter;
    delete p.staticCenter;
    delete p.projectType;
    delete p.sources;
    delete p.editable;
    delete p.createdDate;
    delete p.updatedDate;
    delete p.totalInvestment;
    delete p.galleryItems;
    delete p.link;

    p.Sector = p.iirsaSector.name;
    delete p.iirsaSector;

    p.Subsector = p.iirsaSubsector.name;
    delete p.iirsaSubsector;

    p.Etapa = p.iirsaStage.name;
    delete p.iirsaStage;

    p.Estado = p.iirsaStatus.name;
    delete p.iirsaStatus;

    p.Alcance = p.iirsaScope.name;
    delete p.iirsaScope;

    p.Tipo_de_Obra = p.iirsaProjectType ? p.iirsaProjectType.name : '';
    delete p.iirsaProjectType;

    p.Estado_de_los_Estudios = p.iirsaStudiesState ? p.iirsaStudiesState.name : '';
    delete p.iirsaStudiesState;

    p.Financiador_de_los_Estudios = p.iirsaStudiesFunder ? p.iirsaStudiesFunder.name : '';
    delete p.iirsaStudiesFunder;

    // let elements = p.iirsaProjectFunding;
    // if (elements && elements.length > 0) {
    //     for(let i = 0; i <  elements.length; i++) {
    //         const prefix = 'FINANCIAMIENTO_' + (i + 1).toString() + '_';
    //         p[prefix + 'MONTO'] = elements[i].amount;
    //         p[prefix + 'TIPO'] = elements[i].fundingType ? elements[i].fundingType.name : "";
    //         p[prefix + 'FINANCIADOR'] = elements[i].funder ? elements[i].funder.name : "";
    //         p[prefix + 'ESTADO'] = elements[i].fundingStatus ? elements[i].fundingStatus.name : "";
    //     }
    // }
    // delete p.iirsaProjectFunding;
    delete p.iirsaSegments;
    delete p.iirsaLink;
    delete p.iirsaCountries;
}

const exporter = proj => {
    proj.forEach(p => {
        formatProject(p);
    });
    const formattedCsv = parse(proj);
    downloadCSV(formattedCsv, 'Proyectos');
};

const BulkActions = props => (
  <Fragment>
    <BulkDeleteButton undoable={false} {...props} />
  </Fragment>
);

function IirsaProjectList(props) {
  return (
    <>
      <List
        {...props}
        bulkActionButtons={<BulkActions />}
        exporter={exporter}
        filters={<ProjectFilter />}
        pagination={<PostPagination />}
        perPage={50}
      >
        <CustomDatagrid rowClick="show">
          <TextField source="id" />
          <TextField source="name" label="Nombre" />
          <TextField source="countries" />
          <TextField source="createdDate" />
          <TextField source="updatedDate" />
          <TextField style={{ textAlign: 'right' }} source="totalInvestmentFormatted" />
        </CustomDatagrid>
      </List>
    </>
  );
}

export default translate(IirsaProjectList);
