import React, { Component } from 'react';
import { translate } from 'react-admin';
import { Doughnut, Bar } from 'react-chartjs-2';
import { Card, CardHeader, CardBody } from 'react-simple-card';

import { ProjectService } from '~config/services/project.service'
import styles from './styles.js';
import { infraestructureChoices, statusChoices } from '~constants/fieldGroups';

class ProjectsCharts extends Component {

  state = {
    countryChart: {},
    infrastructureChart: {},
    statusChart: {},
    total: 0,
    lastMonthTotal: 0
  }

  constructor(props) {
    super(props);

    const ps = new ProjectService();
    ps.get_chart_data().then(r => this.processChartData(r.data));
  }

  processChartData(data) {

    this.setState({
      countryChart: data ? this.formatAndOrderDataset(data.countryChart) : null,
      infrastructureChart: data ? this.formatDatasetWithLabels(data.infrastructureChart, infraestructureChoices) : null,
      statusChart: data ? this.formatDatasetWithLabels(data.statusChart, statusChoices) : null,
      total: data ? data.total : null,
      lastMonthTotal: data ? data.lastMonthTotal : null
    });

  }

  formatDataset(chartData) {
    if(chartData) {
      return {
        datasets: [{
          data: chartData.data,
          backgroundColor: this.generateColors(chartData.data.length),
        }],
        labels: chartData.labels
      }
    }
    return {};
  }

  formatAndOrderDataset(chartData) {
    if(chartData) {
      let countries = [];
      chartData.data.forEach( (d, idx) => {
        countries.push({
          data: d,
          label: chartData.labels[idx]
        });
      });
      countries = countries.sort((a, b) => { return b.data - a.data});
      let data = {
        data: countries.map(c=>c.data),
        labels: countries.map(c=>c.label)
      }
      return this.formatDataset(data);
    }
    return {};
  }

  formatDatasetWithLabels(chartData, choices) {
    let labels = [];
    let data = this.formatDataset(chartData);
    data.labels.forEach(l => {
      labels.push(choices.filter(ch => ch.id==l)[0]?.name);
    });
    data.labels = labels;
    return data;
  }

  generateColors(length) {
    let colors = []
    let letters = '0123456789ABCDEF';
    for(let i = 0; i<=length; i++) {
      let newColor = '#';
      for (let i = 0; i < 6; i++) {
        newColor += letters[Math.floor(Math.random() * 16)];
      }
      colors.push(newColor);
    }
    return colors;
  }

  render() {

    const options = {
      legend: { display: false }
    }

    const barOptions = {
      ...options,
      scales: {
        yAxes: [{
            ticks: { beginAtZero: true }
        }],
        xAxes: [{ display: false }]
      }
    }

    return (
      <>

      <h3 style={styles.biggerTitle}>
        Cantidad total de proyectos: {this.state.total}. Modificados el último mes: {this.state.lastMonthTotal}.
      </h3>

      <div>

        {this.state.countryChart && <div style={styles.col} >
          <h3 style={styles.biggerTitle}>Proyectos por país</h3>
          <Bar data={this.state.countryChart} options={barOptions} />
        </div>}

        {this.state.infrastructureChart && <div style={styles.col} >
          <h3 style={styles.title}>Proyectos por tipo de infraestructura</h3>
          <Doughnut data={this.state.infrastructureChart} options={options} />
        </div>}

        {this.state.statusChart && <div style={styles.col} >
          <h3 style={styles.biggerTitle}>Proyectos por etapa</h3>
          <Doughnut data={this.state.statusChart} options={options} />
        </div>}

      </div>
  
    </>
  
    );
  }
}

export default translate(ProjectsCharts);
