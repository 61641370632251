import React from 'react';
import { TextInput, translate } from 'react-admin';
import { PropTypes } from 'prop-types';
import { normalizeDate } from 'utils/normalize';

import { DATE_FORMAT_PLACEHOLDER } from '~constants/dates';

function DateRangeInput({ source, defaultValue = {} }) {
  return (
    <>
      <TextInput
        source={`${source}.startMoment`}
        label={`common.filters.${source}Start`}
        defaultValue={defaultValue.startMoment}
        normalize={normalizeDate}
        placeholder={DATE_FORMAT_PLACEHOLDER}
      />
      &nbsp;
      <TextInput
        source={`${source}.endMoment`}
        label={`common.filters.${source}End`}
        defaultValue={defaultValue.endMoment}
        normalize={normalizeDate}
        placeholder={DATE_FORMAT_PLACEHOLDER}
      />
    </>
  );
}

DateRangeInput.propTypes = {
  source: PropTypes.string.isRequired,
  defaultValue: PropTypes.shape({
    endMoment: PropTypes.instanceOf(Date),
    startMoment: PropTypes.instanceOf(Date)
  })
};

export default translate(DateRangeInput);
