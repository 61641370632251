import React from 'react';
import { Filter, TextInput, SelectInput, SelectArrayInput } from 'react-admin';
import DateBetweenInput from '../../Projects/components/DateBetweenInput';

import { countryChoices, sectionChoices } from '~constants/fieldGroups';

function SearchFilter(props) {
  return (
    <Filter {...props}>
      <TextInput source="searchString" />
      <SelectArrayInput source="country" label="resources.searchs.fields.country" choices={countryChoices} />
      <SelectInput source="section" choices={sectionChoices} />
      <DateBetweenInput source="createdDate"/>
    </Filter>
  );
}

export default SearchFilter;
