import React, { Component } from 'react';
import { AutocompleteArrayInput, REDUX_FORM_NAME } from 'react-admin';

class ArticleTopicsInput extends Component {

    newChoices = []

    setNewChoices(choices) {
        Object.getOwnPropertyNames(choices).forEach(i => {
            let c = choices[i];
            let names = this.props.choices.map(c=>c.name);
            names.push(...this.newChoices.map(c=>c.name))
            if((typeof c === 'string' || c instanceof String) && names.indexOf(c) == -1) {
                let choice = {
                    id: c,
                    name: c
                };
                this.newChoices.push(choice);
            }
        });
    }

    render() {
        let choices;
        if(this.props.choices) {
            choices = this.props.choices.filter(c=>c==c);
            choices = choices.map(c => { return { id: c.name, name: c.name} });
            choices.push(...this.newChoices);
            choices.sort(function(a, b) {
                var textA = a.id.toUpperCase();
                var textB = b.id.toUpperCase();
                return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
            });
        }
        if(this.props.value) {
            let value = this.props.value.filter((item, pos) => this.props.value.indexOf(item) == pos);
            if(value.length != this.props.value.length) {
                this.props.change(REDUX_FORM_NAME, this.props.source, value);
            }
        }
        return (
            <>
                <AutocompleteArrayInput 
                    choices={choices}
                    source={this.props.source}
                    onChange={(ch)=>{this.setNewChoices(ch)}}/>
            </>
        )
    }

}

export default ArticleTopicsInput;
