import { downloadCSV } from 'react-admin';
import { parse } from 'json2csv';
import { SearchLogsService } from '~config/services/search_logs.service';

const exporter = searchs => {
    const sls = new SearchLogsService();
    sls.get().then(r => {
        let data = r.data;
        let searchObjs = [];
        data.forEach(s => {
            searchObjs.push(getSearchObj(s));
        });
        removeEmptyParams(searchObjs);
        let formattedCsv = parse(searchObjs);
        downloadCSV(formattedCsv, 'Búsquedas');
    });
};

function getSearchObj(s) {
    let searchObj = {};

    searchObj['ID'] = s.id;
    searchObj['Fecha y hora'] = s.createdAt;
    searchObj['Termino'] = s.searchString;

    setCommaSeparatedField(searchObj,s.topic,'Tecnologia',topicChoices);
    setCommaSeparatedField(searchObj,s.financing,'Financiamiento',financingChoices);
    setArrayField(searchObj,s.location,'Pais',countriesChoices);
    setArrayField(searchObj,s.contentType,'Seccion',contentTypeChoices);
    setCommaSeparatedField(searchObj,s.infrastructure,'Infraestructura',infrastructureChoices);
    setCommaSeparatedField(searchObj,s.stage,'Etapa',stageChoices);
    setCommaSeparatedField(searchObj,s.type_filter,'Tipo de contenido',typeChoices);

    return searchObj;
}

function setArrayField(obj,value,name,choices) {
    let properties = Object.getOwnPropertyNames(choices);
    properties.sort()
    properties.forEach(c => {
        let prop = name + ' - ' + capitalize(choices[c]);
        if(value && value.indexOf(c) > -1) {
            obj[prop] = 'SI';
        } else {
            obj[prop] = '';
        }
    });
}

function setCommaSeparatedField(obj,value,name,choices=null) {
    if(value) {
        let arrayValue = value.split(',');
        setArrayField(obj,arrayValue,name,choices);
    }
}

function capitalize(s) {
    return s.charAt(0).toUpperCase() + s.slice(1)
}

function removeEmptyParams(searchObjs) {
    let obj = searchObjs[0];
    Object.getOwnPropertyNames(obj).forEach(p => {
        let borrar = searchObjs.filter(s=>s[p]!='').length == 0;
        if(borrar) {
            searchObjs.forEach(s => delete s[p]);
        }
    });
}

const topicChoices = {
    bim: 'BIM',
    blockchain: 'Blockchain',
    artificialintelligence: 'Inteligencia Artificial',
    iot: 'Internet de las Cosas',
    bigdata: 'Big Data'
};

const financingChoices = {
    public_project: 'Publico',
    private_project: 'Privado',
    mixed_project: 'Mixto'
};

const countriesChoices = {
    argentina: 'Argentina',
    bolivia: 'Bolivia',
    brasil: 'Brasil',
    chile: 'Chile',
    colombia: 'Colombia',
    ecuador: 'Ecuador',
    bahamas: 'Bahamas',
    barbados: 'Barbados',
    belice: 'Belice',
    costa_rica: 'Costa Rica',
    el_salvador: 'El Salvador',
    guatemala: 'Guatemala',
    guyana: 'Guyana',
    haiti: 'Haiti',
    honduras: 'Honduras',
    jamaica: 'Jamaica',
    mexico: 'Mexico',
    nicaragua: 'Nicaragua',
    panama: 'Panama',
    paraguay: 'Paraguay',
    peru: 'Peru',
    dominican_republic: 'Republica Dominicana',
    suriname: 'Suriname',
    trinidad_and_tobago: 'Trinidad y Tobago',
    uruguay: 'Uruguay',
    venezuela: 'Venezuela',
    africa: 'Africa',
    north_america: 'America del Norte',
    antartic: 'Antartida',
    asia: 'Asia',
    europe: 'Europa',
    oceania: 'Oceania',
    belize: 'Belize'
}

const contentTypeChoices = {
    Project: 'Proyectos',
    Article: 'Contenidos',
    Event: 'Eventos',
    Infographic: 'Infografia',
    Publishing: 'Publicacion',
    Video: 'Video',
};

const infrastructureChoices = {
    energy: 'Energia',
    transport: 'Transporte y logistica',
    boundary: 'Fronteras',
    telecommunications: 'Telecomunicaciones',
};

const stageChoices = {
    profiling: 'Perfil',
    pre_execution: 'Pre-ejecucion',
    execution: 'Ejecucion',
    finished: 'Concluido'
};

const typeChoices = {
    articles: 'Articulos',
    infographic: 'Infografias',
    publications: 'Publicaciones',
    videos: 'Videos'
};

export default exporter;
