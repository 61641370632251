import api, { apiSetup } from '../api';
var accents = require('remove-accents');

apiSetup();

export class CountriesService {

    BASE_URL = '/backoffice/countries';

    get() {
        return api.get(this.BASE_URL);
    }

    get_la() {
        return this.get().then(r => {
            let laCountries = [];
            r.data.forEach(c => {
                if(countriesAL.indexOf(this.format(c.name) > -1)){
                    laCountries.push(c);
                }
            });
            return {data: laCountries};
        });
    }

    format(str) {
        return accents.remove(str).toLowerCase();
    }

}

const countriesAL = [
    'argentina',
    'bolivia',
    'brasil',
    'chile',
    'colombia',
    'ecuador',
    'bahamas',
    'barbados',
    'belice',
    'costa rica',
    'el salvador',
    'guatemala',
    'guyana',
    'haiti',
    'honduras',
    'jamaica',
    'mexico',
    'nicaragua',
    'panama',
    'paraguay',
    'peru',
    'republica dominicana',
    'suriname',
    'trinidad y tobago',
    'uruguay',
    'venezuela',
]
