export const truncate = {
  truncate: {
    display: 'block',
    'text-overflow': 'ellipsis',
    'white-space': 'nowrap',
    overflow: 'hidden',
    'max-width': '180px',
    width: '100%'
  }
};
