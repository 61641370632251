import React from 'react';
import { Edit, DisabledInput } from 'react-admin';
import TopicForm from '../TopicForm';

function TopicEdit(props) {
    return (
      <Edit undoable={false} {...props}>
        <TopicForm redirect="list">
          <DisabledInput label="Id" source="id" />
        </TopicForm>
      </Edit>
    );
}

export default TopicEdit;
