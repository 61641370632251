import React from 'react';
import { Create } from 'react-admin';

import TopicForm from '../TopicForm';

function TopicCreate({ ...props }) {
  return (
    <Create {...props}>
      <TopicForm redirect="list" />
    </Create>
  );
}

export default TopicCreate;
