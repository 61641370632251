import api, { apiSetup } from '../api';

apiSetup();

export class AuditsService {

    BASE_URL = '/backoffice/audits';

    get(project_id) {
        return api.get(`${this.BASE_URL}/${project_id}`);
    }
}
