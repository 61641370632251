import React, {Component} from 'react';
import { Filter, SelectInput, AutocompleteArrayInput } from 'react-admin';

import {
  publicationStatusChoices,
  countryChoices,
  eventTypeChoices
} from '~constants/fieldGroups';
import DateRangeInput from '~components/DateRangeInput';
import {TopicsService} from "../../../../config/services/topics.service";

class EventFilter extends Component {

    state = {
        topicsChoices: []
    }

    constructor() {
        super();

        const ts = new TopicsService();
        ts.get().then(r => {
            this.setState({topicsChoices: r.data});
        })
    }

    render() {
        return (
            <Filter {...this.props}>
                <SelectInput
                    source="publicationStatus"
                    choices={publicationStatusChoices}
                    label="resources.articles.filters.publicationStatus"
                />
                <AutocompleteArrayInput
                    source="location"
                    label="resources.articles.fields.country"
                    choices={countryChoices}
                />
                <SelectInput source="type" choices={eventTypeChoices} label="resources.events.fields.eventType"/>
                <SelectInput source="backoffice_event_topic" choices={this.state.topicsChoices} sortable={false}
                             label="Tema"/>
                <DateRangeInput source="creationDateRange"/>
                <DateRangeInput source="durationDateRange"/>
            </Filter>
        );
    }
}

export default EventFilter;
