import api, { apiSetup } from '../api';

apiSetup();

export class SearchLogsService {

    BASE_URL = '/backoffice/search_logs/all_logs';

    get() {
        let params = window.location.href.split('?')[1];
        let url = this.BASE_URL;
        if(params) {
            url += '?' + params;
        }
        return api.get(url);
    }
}
