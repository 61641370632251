import React from 'react';
import Button from '@material-ui/core/Button';
import Toolbar from '@material-ui/core/Toolbar';
import { Edit, DisabledInput } from 'react-admin';

import styles from './styles';

import IirsaProjectForm from '../IirsaProjectForm';

const frontendURL = process.env.REACT_APP_FRONTEND_URL;

const IirsaProjectEditActions = props => (
  <Toolbar style={styles.toolbar}>
    <Button
      color="primary"
      onClick={() => {
        props.history.push(`/iirsaProjects/${props.id}/show`);
      }}
    >
      <svg style={styles.mostrarSvg} focusable="false" viewBox="0 0 24 24" aria-hidden="true">
        <g>
          <path d="M12 4.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5zM12 17c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5zm0-8c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3z"></path>
        </g>
      </svg>
      Mostrar
    </Button>
  </Toolbar>
);

function IirsaProjectEdit(props) {
  return (
    <Edit
      undoable={false}
      actions={<IirsaProjectEditActions id={props.id} history={props.history} />}
      {...props}
    >
      <IirsaProjectForm>
        <DisabledInput label="Id" source="id" />
      </IirsaProjectForm>
    </Edit>
  );
}

export default IirsaProjectEdit;
