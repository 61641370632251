/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { Show, TabbedShowLayout, translate, TextField, FormDataConsumer, Tab, EditButton } from 'react-admin';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import ReactHtmlParser from 'react-html-parser';
import CardActions from '@material-ui/core/CardActions';

import FieldGroup from '~components/FieldGroup';

import AuditsDisplay from '../components/AuditsDisplay';

import styles from './styles';

import './styles.scss';

const VolverButton = props => (
  <>
    <Button style={styles.volverButton} onClick={props.onVolverClick}>
      <svg style={styles.chevron} viewBox="0 0 32 32" className="icon icon-chevron-left" viewBox="0 0 32 32">
        <path
          fill="#3f51b5"
          d="M14.19 16.005l7.869 7.868-2.129 2.129-9.996-9.997L19.937 6.002l2.127 2.129z"
        />
      </svg>
      Volver
    </Button>
  </>
);

// eslint-disable-next-line react/no-multi-comp
const ShowProjectButton = props => (
  <Button style={styles.showButton} onClick={props.onShowProjectClick}>
    <svg style={styles.mostrarSvg} focusable="false" viewBox="0 0 24 24" aria-hidden="true">
      <g>
        <path d="M12 4.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5zM12 17c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5zm0-8c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3z" />
      </g>
    </svg>
    Mostrar
  </Button>
);

// eslint-disable-next-line react/no-multi-comp
const ILAT_FE_URL = process.env.REACT_APP_ILAT_FE_URL;

const ShowActions = ({ basePath, data }) => (
  <CardActions style={styles.cardActionStyle}>
    <EditButton basePath={basePath} record={data} />
    <ShowProjectButton
      onShowProjectClick={() => {
        const url = `${ILAT_FE_URL}portfolio/#/projects/${data.code}/show`;
        window.open(url, '_blank');
      }}
    />
  </CardActions>
);

// eslint-disable-next-line react/no-multi-comp
class IirsaProjectShow extends Component {
  // eslint-disable-next-line class-methods-use-this
  renderAuditsDisplay(data) {
    return <AuditsDisplay data={data} />;
  }

  handleVolverClick = () => {
    // eslint-disable-next-line react/prop-types
    this.props.history.push('/iirsaProjects');
  };

  render() {
    return (
      <>
        <VolverButton onVolverClick={this.handleVolverClick} />
        <Show actions={<ShowActions />} {...this.props}>
          <TabbedShowLayout>
            <Tab label="proyecto">
              <FieldGroup
                label="fieldGroups.projectInformation"
                formClassName={this.props.classes.borderBottom}
                show
              >
                <TextField source="code" label="Código" />
                <TextField source="name" label="Nombre" />
                <TextField source="iirsa_sector.name" label="Sector" />
                <TextField source="iirsa_subsector.name" label="Subsector" />
                <br />

                <label className="field-label">Objetivos</label>
                <FormDataConsumer>
                  {({ formData }) => <span className="field-value">{ReactHtmlParser(formData?.goal)}</span>}
                </FormDataConsumer>
                <br />

                <label className="field-label">Descripción</label>
                <FormDataConsumer>
                  {({ formData }) => (
                    <span className="field-value">{ReactHtmlParser(formData?.description)}</span>
                  )}
                </FormDataConsumer>

                <TextField source="iirsa_stage.name" label="Estapa" />
                <TextField source="countries" />
                <TextField source="iirsa_project_type.name" label="Tipo de proyecto" />
                <TextField source="iirsa_scope.name" label="Ámbito" />
              </FieldGroup>
            </Tab>
          </TabbedShowLayout>
        </Show>
      </>
    );
  }
}

IirsaProjectShow.propTypes = {
  classes: PropTypes.shape({
    borderBottom: PropTypes.string.isRequired
  })
};

export default withStyles(styles)(translate(IirsaProjectShow));
