import React from 'react';
import {
  Show,
  SimpleShowLayout,
  translate,
  TextField,
  ImageField,
  RichTextField,
  ArrayField,
  SingleFieldList,
  FormDataConsumer,
  UrlField
} from 'react-admin';
import { withStyles } from '@material-ui/core/styles';
import LocationField from 'app/components/LocationField';
import PropTypes from 'prop-types';

import { textArticleTypes, articleTypes } from '~constants/fieldGroups';
import FieldGroup from '~components/FieldGroup';
import TranslatedTextField from '~components/TranslatedTextField';
import ShowCustomActions from '~components/ShowCustomActions';
import TopicsField from '~components/TopicsField';
import StarredTextField from '~components/StarredTextField';
import CustomDateField from '~components/CustomDateField';
import InfrastructureField from '~components/InfrastructureField';

import styles from './styles';

function ArticleShow({ classes, ...props }) {
  return (
    <Show {...props} actions={<ShowCustomActions {...props} />}>
      <SimpleShowLayout>
        <FieldGroup label="fieldGroups.contentInformation" formClassName={classes.borderBottom} show>
          <TextField source="title" />
          <TextField source="description" />
          <InfrastructureField source="infrastructure"/>
          <ArrayField source="locations">
            <SingleFieldList linkType={false}>
              <LocationField />
            </SingleFieldList>
          </ArrayField>
          <TopicsField fieldLabel="fieldGroups.topics" source="topics" />
          <ImageField source="image.file" label="resources.articles.fields.image" />
          <TextField className={classes.epigraph} source="epigraph" label="common.image.epigraph" />
          <TextField source="videoUrl" />
        </FieldGroup>
        <FieldGroup formClassName={classes.borderBottom} label="fieldGroups.mainContent" show>
          <TranslatedTextField translatePrefix="resources.articles.types" source="articleType" addLabel />
          <FormDataConsumer>
            {({ formData, ...rest }) =>
              textArticleTypes.includes(formData.articleType) && (
                <RichTextField className="ra-rich-text-field" source="content" {...rest} />
              )
            }
          </FormDataConsumer>
          <FormDataConsumer>
            {({ formData, ...rest }) =>
              formData.articleType === articleTypes.video && <UrlField source="content" {...rest} />
            }
          </FormDataConsumer>
          <FormDataConsumer>
            {({ formData, ...rest }) =>
              formData.articleType === articleTypes.infographic && (
                <ImageField source="content" title="title" {...rest} />
              )
            }
          </FormDataConsumer>
        </FieldGroup>
        <FieldGroup label="fieldGroups.authorInfo" show>
          <TextField source="author.firstName" />
          <TextField source="author.lastName" />
          <TextField source="author.email" />
          <TextField source="author.institution" />
          <TextField source="author.role" />
        </FieldGroup>
        <FieldGroup label="fieldGroups.additionalInformation" show>
          <TranslatedTextField source="sortBy" translatePrefix="resources.articles.sortBy" addLabel/>
          <CustomDateField source="materialDate" label="resources.articles.fields.materialDate" show />
          <TextField source="originalSource" />
          <TextField source="sourceUrl" />
        </FieldGroup>
        <FieldGroup label="fieldGroups.contentConfiguration" formClassName={classes.borderBottom} show>
          <TranslatedTextField source="publicationStatus" translatePrefix="common" addLabel />
          <StarredTextField />
        </FieldGroup>
      </SimpleShowLayout>
    </Show>
  );
}

ArticleShow.propTypes = {
  classes: PropTypes.shape({
    borderBottom: PropTypes.string.isRequired,
    epigraph: PropTypes.string.isRequired
  })
};

export default withStyles(styles)(translate(ArticleShow));
