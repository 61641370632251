import snakecase from 'lodash.snakecase';

function isDict(v) {
  return typeof v === 'object' && v !== null && !(v instanceof Array) && !(v instanceof Date);
}

export function snakecaseObject(obj) {
  const snakecaseObj = {};
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      if (isDict(obj[key])) {
        snakecaseObj[snakecase(key)] = snakecaseObject(obj[key]);
      } else if (obj[key] instanceof Array) {
        snakecaseObj[snakecase(key)] = obj[key].map(e => snakecaseObject(e));
      } else {
        snakecaseObj[snakecase(key)] = obj[key];
      }
    }
  }
  return snakecaseObj;
}
