import { white, silver, codGray } from './colors';

export const styles = {
  root: {
    color: silver,
    fontSize: '18px',
    fontWeight: 'normal',
    fontFamily: 'Archivo',
    height: 'auto',
    lineHeight: '20px',
    whiteSpace: 'normal',
    padding: '13.5px 16px 13.5px 58px'
  },
  active: {
    color: white,
    backgroundColor: codGray,
    fontWeight: 'bold'
  }
};
