import React from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-admin';
import Typography from '@material-ui/core/Typography';

function StarredTextField({ translate: t, record }) {
  return (
    <Typography component="span" body1="body1">
      {record.starredAt ? t('common.starred') : t('common.notStarred')}
    </Typography>
  );
}

StarredTextField.propTypes = {
  translate: PropTypes.func.isRequired,
  record: PropTypes.shape({
    starredAt: PropTypes.bool
  })
};

export default translate(StarredTextField);
