import React from 'react';
import { List, TextField } from 'react-admin';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import { truncate } from '~constants/styles';
import { defaultListProps } from '~constants/config';
import CustomDatagrid from '~components/CustomDatagrid';
import StarredField from '~components/StarredField';
import ActionsField from '~components/ActionsField';
import TranslatedTextField from '~components/TranslatedTextField';
import CustomDateField from '~components/CustomDateField';
import ListField from '~components/ListField';
import TextTopicsField from '~components/TextTopicsField';

import ArticleFilter from '../ArticleFilter';

import styles from './styles.module.scss';

import {infraestructureChoices} from '~constants/fieldGroups';

function ArticleList({ ...props }) {
  function renderLocation(location) {
    return location.city ? `${location.city}, ${location.country}` : location.country;
  }

  function renderInfrastructure(inf) {
    return infraestructureChoices.filter(c=>c.id==inf)[0]?.name;
  }

  return (
    <List {...defaultListProps} filters={<ArticleFilter />} {...props}>
      <CustomDatagrid rowClick="show">
        <StarredField />
        <TextField source="id" />
        <TranslatedTextField className={styles.column} translatePrefix="common" source="publicationStatus" />
        <TextField className={styles.title} source="title" />
        <TextField className={styles.column} source="authorName" />
        <TranslatedTextField
          className={styles.column}
          translatePrefix="resources.articles.types"
          source="articleType"
        />
        <TextTopicsField className={styles.column} source="topics" sortable={false} />
        <ListField
          className={styles.column}
          source="locations"
          sortable={false}
          renderItem={renderLocation}
        />
        <ListField
          className={styles.column}
          translatePrefix="common.contentTypes"
          source="infrastructure"
          sortable={false}
          renderItem={renderInfrastructure}
        />
        <CustomDateField className={styles.column} source="createdAt" />
        <ActionsField />
      </CustomDatagrid>
    </List>
  );
}

ArticleList.propTypes = {
  classes: PropTypes.shape({
    truncate: PropTypes.string.isRequired
  }).isRequired
};

export default withStyles(truncate)(ArticleList);
