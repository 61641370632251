import React from 'react';
import { translate } from 'react-admin';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import moment from 'moment';

import { DATE_TIME_FORMAT } from '~constants/dates';

import styles from './styles';

function EventDateField({ translate: t, record = {}, classes, source, label }) {
  const dates = record[source];
  if (!dates) {
    return <Typography>-</Typography>;
  }
  const eventDates = dates.map(session => {
    const eventStart = moment(`${session.date} ${session.startTime}`, DATE_TIME_FORMAT).format(
      DATE_TIME_FORMAT
    );
    const eventTime = `${eventStart} - ${session.endTime}`;
    return <Typography key={session}>{eventTime}</Typography>;
  });

  return (
    <>
      <Typography classes={{ root: `${classes.eventsRoot}` }}> {t(label)} </Typography>
      {eventDates}
    </>
  );
}

EventDateField.propTypes = {
  classes: PropTypes.shape({
    borderBottom: PropTypes.string.isRequired,
    eventsRoot: PropTypes.string.isRequired,
    infoContainer: PropTypes.string.isRequired
  }),
  label: PropTypes.string,
  record: PropTypes.shape({
    date: PropTypes.string.isRequired,
    endTime: PropTypes.string.isRequired,
    startTime: PropTypes.string.isRequired
  }),
  source: PropTypes.string,
  translate: PropTypes.func
};

export default withStyles(styles)(translate(EventDateField));
