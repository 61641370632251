import React from 'react';
import { connect } from 'react-redux';
import { userLogout as userLogoutAction } from 'ra-core';
import { translate } from 'react-admin';
import Button from '@material-ui/core/Button';
import ExitIcon from '@material-ui/icons/PowerSettingsNew';

import styles from './styles.module.scss';

function LogoutButton ({ translate: t, userLogout, ...rest }) { // eslint-disable-line
  return (
    <button className={styles.logout} type="button">
      <Button onClick={userLogout} size="small" {...rest}>
        <ExitIcon /> {t('ra.auth.logout')}
      </Button>
    </button>
  );
}

const mapDispatchToProps = dispatch => ({
  userLogout: () => dispatch(userLogoutAction())
});

export default connect(
  undefined,
  mapDispatchToProps
)(translate(LogoutButton));
