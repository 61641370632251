import React, {Component} from 'react';
import { Filter, TextInput, SelectInput, AutocompleteArrayInput } from 'react-admin';

import {
  publicationStatusChoices,
  contentTypeChoices,
  infraestructureTypeChoices
} from '~constants/fieldGroups';
import DateRangeInput from '~components/DateRangeInput';
import { CountriesService } from '~config/services/countries.service';
import {TopicsService} from "../../../../config/services/topics.service";

class ArticleFilter extends Component {

  state = {
      countries: [],
      topicsChoices: []
  }
  
  constructor() {
    super();
    const cs = new CountriesService();
    cs.get_la().then(r => this.setState({countries: r.data}));

    const ts = new TopicsService();
    ts.get().then(r => {
        this.setState({topicsChoices: r.data});
    })
  }
  
  render() {

    return (
      <Filter {...this.props}>
        <SelectInput
          source="publicationStatus"
          choices={publicationStatusChoices}
          label="resources.articles.filters.publicationStatus"
        />
        <TextInput source="title" label="resources.articles.filters.title" />
        <TextInput source="author_name" label="resources.articles.filters.author" />
        <SelectInput source="backoffice_article_topic" choices={this.state.topicsChoices} sortable={false} label="Tema"/>
        <AutocompleteArrayInput label="Paises" source="countries" choices={this.state.countries} sortable={false} />
        <SelectInput source="type" choices={contentTypeChoices} label="resources.articles.filters.type" />
        <SelectInput
          source="infrastructure"
          choices={infraestructureTypeChoices}
          label="resources.articles.filters.infrastructure"
        />
        <DateRangeInput source="creationDateRange" />
      </Filter>
    );

  }
  
}

export default ArticleFilter;
