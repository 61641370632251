import React from 'react';
import { Create } from 'react-admin';

import ProjectForm from '../ProjectForm';

function ProjectCreate(props) {
  return (
    <Create {...props}>
      <ProjectForm />
    </Create>
  );
}

export default ProjectCreate;
