import React from 'react';
import PropTypes from 'prop-types';
import { List, TextField, translate, ArrayField, SingleFieldList } from 'react-admin';
import { withStyles } from '@material-ui/core/styles';

import { defaultListProps } from '~constants/config';
import { truncate } from '~constants/styles';
import StarredField from '~components/StarredField';
import TranslatedTextField from '~components/TranslatedTextField';
import LocationField from '~components/LocationField';
import TextTopicsField from '~components/TextTopicsField';
import ActionsField from '~components/ActionsField';
import CustomDateField from '~components/CustomDateField';
import CustomDatagrid from '~components/CustomDatagrid';

import EventFilter from '../EventFilter';

import styles from './styles.module.scss';

function EventList({ ...props }) {
  return (
    <List {...defaultListProps} filters={<EventFilter />} {...props}>
      <CustomDatagrid rowClick="show">
        <StarredField />
        <TranslatedTextField translatePrefix="common" source="publicationStatus" />
        <TextField source="id" />
        <TextField className={styles.title} source="title" />
        <TranslatedTextField
          className={styles.column}
          translatePrefix="resources.events.types"
          source="eventType"
          sortable={false}
        />
        <TextTopicsField className={styles.column} source="topics" sortable={false} />
        <LocationField
          className={styles.column}
          label="resources.events.fields.locationTitle"
          source="location"
          sortBy="countries"
        />
        <ArrayField className={styles.column} source="eventSessions" sortBy="startMoment">
          <SingleFieldList linkType={false}>
            <CustomDateField source="startMoment" />
          </SingleFieldList>
        </ArrayField>
        <CustomDateField source="createdAt" />
        <ActionsField />
      </CustomDatagrid>
    </List>
  );
}

EventList.propTypes = {
  classes: PropTypes.shape({
    truncate: PropTypes.string.isRequired
  }).isRequired
};

export default withStyles(truncate)(translate(EventList));
