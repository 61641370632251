import React from 'react';
import PropTypes from 'prop-types';
import { translate } from 'ra-core';
import { FunctionField } from 'react-admin';

function TranslatedTextField({ record, className, source, translatePrefix, translate: t }) {
  function render() {
    return t(`${translatePrefix}.${record[source]}`);
  }
  return record[source] ? <FunctionField className={className} render={render} /> : null;
}

TranslatedTextField.propTypes = {
  source: PropTypes.string.isRequired,
  translate: PropTypes.func.isRequired,
  translatePrefix: PropTypes.string.isRequired,
  className: PropTypes.string,
  record: PropTypes.shape({
    status: PropTypes.string
  })
};

export default translate(TranslatedTextField);
