import React, { Component } from 'react';
import { 
  SimpleForm, 
  TextInput, 
  translate, 
  SelectInput, 
  FileInput, 
  NumberInput, 
  BooleanInput, 
  ArrayInput,
  SimpleFormIterator,
  FormDataConsumer,
  LongTextInput,
  Confirm
} from 'react-admin';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { connect } from "react-redux";
import { change, submit } from "redux-form";
import Button from '@material-ui/core/Button';
import { withRouter } from "react-router-dom";
import RichTextInput from 'ra-input-rich-text';

import { 
  infraestructureTypeChoices, 
  statusChoices, 
  financingChoices, 
  bankChoices, 
  areaTypeChoices,
  subsectorChoices 
} from '~constants/fieldGroups';
import { isRequired, isValidDate } from '~utils/inputValidations';
import FieldGroup from '~components/FieldGroup';
import FileLinkField from '~components/FileLinkField';
import FileLinkAndNameField from '~components/FileLinkAndNameField';
import { normalizeDate } from '~utils/normalize';
import { DATE_FORMAT_PLACEHOLDER } from '~constants/dates';
import { SourcesService } from '~config/services/sources.service';
import { ProjectService } from '~config/services/project.service';
import { CountriesService } from '~config/services/countries.service';
import styles from './styles';
import DynamicMap from '../components/DynamicMap';
import StaticMapPreview from '../components/StaticMapPreview';
import ModalMapPreview from '../components/ModalMapPreview';
import CustomAutocompleteInput from '../../../components/CustomAutocompleteInput';
import GalleryItemsInput from '~components/GalleryItemsInput';
import { SlugsService } from '~config/services/slugs.service';
import './styles.scss';

const VolverButton = (props) => (
    <>
      <Button style={styles.volverButton} onClick={props.onVolverClick} >
        <svg style={styles.chevron} viewBox="0 0 32 32" class="icon icon-chevron-left" viewBox="0 0 32 32">
          <path fill="#3f51b5" d="M14.19 16.005l7.869 7.868-2.129 2.129-9.996-9.997L19.937 6.002l2.127 2.129z"/>
        </svg>
        Volver
      </Button>
      <Confirm
          isOpen={props.showConfirm}
          title="¿Desea salir del formulario?"
          content="Los cambios realizados se perderán"
          confirm="Si"
          confirmColor="primary"
          cancel="Cancelar"
          onConfirm={props.onVolverConfirm}
          onClose={props.onVolverCancel}
      />
    </>
);

const toolbarOptions = [
  ['bold', 'italic', 'underline', 'link'],
  [{ 'list': 'bullet' }]
];

class ProjectForm extends Component {

  state = { 
    sources: [],
    projects: [],
    cuntries: [],
    slugs: [],
    showConfirm: false
  };

  firstForm = null;
  hasChanged = false;

  constructor() {
    super();
    const ss = new SourcesService();
    ss.get().then(r => this.setState({sources: r.data}));

    const ps = new ProjectService();
    ps.get_choices().then(r => this.setState({ projects: r.data }));

    const cs = new CountriesService();
    cs.get_la().then(r => {
      let data = r.data;
      data.sort((a, b) => (a.name > b.name) ? 1 : -1);
      this.setState({ countries: data })
    });

    const slgS = new SlugsService();
    slgS.get_from_project().then(r => {
      this.setState({ slugs: r.data });
    });
  }

  validateProjectForm = (values) => {
    const errors = {};
    if(!values.project_countries || values.project_countries.length == 0) {
      errors.project_countries = ['Debe seleccionar al menos un país']
    }
    if(values['slug'] && this.state.slugs?.indexOf(values.slug) > -1) {
      errors.slug = 'Este slug ya se encuentra en uso';
    }
    if(values['image'] && values['image'].rawFile && values['image'].rawFile.size > 15000000) {
      errors.image = 'El tamaño de la imagen no puede ser mayor a 15MB';
    }
    return errors
  };

  onVolverClick = () => {
    if(this.hasChanged) {
      this.setState({showConfirm: true});
    } else {
      this.props.history.push("/projects");
    }
  } 

  onVolverConfirm = () => {
    this.props.history.push("/projects");
  }

  onVolverCancel = () => {
    this.setState({showConfirm: false});
  }

  render() {

    return (
      <>
        <VolverButton 
          showConfirm={this.state.showConfirm} 
          onVolverClick={this.onVolverClick}
          onVolverConfirm={this.onVolverConfirm}
          onVolverCancel={this.onVolverCancel}
          />
        <SimpleForm validate={this.validateProjectForm} {...this.props} >
          
          {this.children}

          <FormDataConsumer>
            {(data) => {
              if(!this.firstForm) {
                this.firstForm = data;
              } else {
                if(!(JSON.stringify(data) === JSON.stringify(this.firstForm))) {
                  this.hasChanged = true;
                }
              }
            }}
          </FormDataConsumer>

          <FieldGroup 
            label="fieldGroups.projectInformation" 
            formClassName={this.props.classes.borderBottom} 
            titleColumn={false}
            flex={false}>

              <TextInput source="title" validate={isRequired} className={"colInput"}/>
              <TextInput source="slug" className={"colInput"}/>
              
              <SelectInput 
                source="infrastructure" 
                choices={infraestructureTypeChoices} 
                validate={isRequired} 
                className={"colInput"}/>

              <SelectInput 
                source="status" 
                choices={statusChoices} 
                validate={isRequired} 
                className={"colInput"} />
              
              <SelectInput
                source="area"
                choices={areaTypeChoices}
                validate={isRequired} 
                className={"colInput"}/>

              <div className={"colInputContainer"}>
                <label style={styles.inputLabel}>Objetivos (*)</label>
                <RichTextInput 
                  source="objectives" 
                  toolbar={toolbarOptions}
                  validate={isRequired}/>
              </div>

              <div className={"colInputContainer"}>
                <label style={styles.inputLabel}>Descripción (*)</label>
                <RichTextInput 
                  source="content" 
                  toolbar={toolbarOptions}
                  validate={isRequired}/>
              </div>

              <div className={"colInputContainer"}>
                <label style={styles.inputLabel}>Situación</label>
                <RichTextInput 
                  source="situation" 
                  toolbar={toolbarOptions}/>
              </div>

              <SelectInput 
                source="subsector" 
                choices={subsectorChoices} 
                validate={isRequired} 
                className={"colInput"}/>

              <SelectInput 
                source="financing" 
                choices={financingChoices} 
                validate={isRequired} 
                className={"colInput"}/>

              <SelectInput source="bank" choices={bankChoices} className={"colInput"}/>
            
              <TextInput
                source="estimated_end_date"
                normalize={normalizeDate}
                placeholder={DATE_FORMAT_PLACEHOLDER}
                validate={[isValidDate]}
                className={"colInput"}/>

              <div className={"colInputContainer"}>
                <FormDataConsumer>
                  {({formData}) => (
                    <CustomAutocompleteInput
                      choices={this.state.countries}
                      value={formData?.project_countries}
                      change={this.props.change}
                      title={'Paises (*)'}
                      source={'project_countries'}
                      required={true}/>
                  )}
                </FormDataConsumer>
              </div>
              
              <div className={"colInputContainer"}>
                <FormDataConsumer>
                  {({formData}) => 
                    <CustomAutocompleteInput
                      choices={this.state.projects}
                      value={formData?.projects}
                      change={this.props.change}
                      title={'Proyectos relacionados'}
                      source={'projects'}
                      className={"colInput"}
                    />
                  }
                </FormDataConsumer>
              </div>

              <div className={"colInputContainer"}>
                <FormDataConsumer>
                    {({formData}) => 
                      <FileInput label={"Imagen (*)"} source="image" validate={isRequired} className={"colInput"}>
                        <FileLinkAndNameField name={formData?.image_name} />
                      </FileInput>  
                    }
                </FormDataConsumer>
              </div>
              <BooleanInput source="ambiental_license" className={"colInputContainer"}/>

              <TextInput source="url" className={"colInput"}/>


          </FieldGroup>

          <FieldGroup 
            label="fieldGroups.dynamicMap" 
            formClassName={this.props.classes.borderBottom}
            titleColumn={false}>
    
            <FileInput source="kml" accept=".kml">
              <FileLinkField />
            </FileInput>
    
            <NumberInput source="dynamic_map_lat" defaultValue={1}/>
            <NumberInput source="dynamic_map_lng" defaultValue={1}/>
            <NumberInput source="dynamic_map_zoom" defaultValue={1}/>

            <div style={{margin: 'auto'}}>
              <FormDataConsumer>
                {({ formData }) =>
                  formData?.kml ?
                  <>
                    <h3>Ajustar mapa</h3>
                    <DynamicMap
                      kml={formData.kml}
                      centerLat={formData.dynamic_map_lat}
                      centerLng={formData.dynamic_map_lng}
                      zoom={formData.dynamic_map_zoom}
                      change={this.props.change}
                      dynamic={true}
                      infrastructure={formData.infrastructure}
                    />
                  </>
                  :
                  <></>
                }
              </FormDataConsumer>
            </div>

            <br/>

            <div style={styles.dynamicPreviewBtn}>
              <FormDataConsumer>
                {({ formData }) =>
                  formData?.kml ?
                  <>
                    <ModalMapPreview formData={formData}/>
                  </>
                  :
                  <></>
                }
              </FormDataConsumer>
            </div>
    
          </FieldGroup> 

          <FieldGroup 
            label="fieldGroups.staticMap" 
            formClassName={this.props.classes.borderBottom}
            titleColumn={false}
            flex={false}>

            <FileInput source="kml_simplified" accept=".kml">
              <FileLinkField />
            </FileInput>
    
            <NumberInput source="static_map_lat" defaultValue={1} style={styles.full}/>
            <NumberInput source="static_map_lng" defaultValue={1} style={styles.full}/>
            <NumberInput source="static_map_zoom" defaultValue={1} style={styles.full}/>

            <div className={"colInputContainer"}>
              <FormDataConsumer>
                {({ formData }) =>
                  formData?.kml_simplified ?
                  <>
                    <h3 style={styles.staticTitle}>Ajustar mapa</h3>
                    
                    <DynamicMap
                      kml={formData.kml_simplified}
                      centerLat={formData.static_map_lat}
                      centerLng={formData.static_map_lng}
                      zoom={formData.static_map_zoom}
                      change={this.props.change}
                      dynamic={false}
                      infrastructure={formData.infrastructure}
                    />
                  </>
                  :
                  <></>
                }
              </FormDataConsumer>
            </div>

            <div style={styles.staticPreview}>
              <FormDataConsumer>
                {({ formData, ...rest }) =>
                  formData?.kml_simplified ?
                  <>
                    <h3>Vista previa</h3>
                    <StaticMapPreview
                      kml={formData.kml_simplified}
                      centerLat={formData.static_map_lat}
                      centerLng={formData.static_map_lng}
                      zoom={formData.static_map_zoom}
                    />
                  </>
                  :
                  <></>
                }
              </FormDataConsumer>
            </div>

          </FieldGroup>

          <FieldGroup label="fieldGroups.sources" formClassName={this.props.classes.borderBottom} >
            <ArrayInput source="sources">
              <SimpleFormIterator>
                <TextInput source="institution" label="resources.projects.fields.institution" validate={isRequired} />
                <TextInput source="amount" label="resources.projects.fields.amount" validate={isRequired} />
                <NumberInput source="id" formClassName={this.props.classes.hiddenField}/>
              </SimpleFormIterator>
            </ArrayInput>

          </FieldGroup>

          <FieldGroup  label="fieldGroups.owners"  formClassName={this.props.classes.borderBottom}>

            <ArrayInput source="owners">
              <SimpleFormIterator>
                <TextInput source="first_name" label="resources.projects.fields.first_name" />
                <TextInput source="last_name" label="resources.projects.fields.last_name" />
                <TextInput source="email" label="resources.projects.fields.email" validate={isRequired} />
                <TextInput source="role" label="resources.projects.fields.role" validate={isRequired} />
                <TextInput source="institution" label="resources.projects.fields.institution" validate={isRequired} />
                <TextInput source="phone" label="resources.projects.fields.phone" />
                <NumberInput source="id" formClassName={this.props.classes.hiddenField}/>
              </SimpleFormIterator>
            </ArrayInput>
          </FieldGroup>

          <FieldGroup label="fieldGroups.projectSections" formClassName={this.props.classes.borderBottom}>
            <ArrayInput source="sections">
              <SimpleFormIterator>
                <TextInput source="name" label="resources.projects.fields.name"/>
                <LongTextInput source="description" label="resources.projects.fields.description"/>
                <SelectInput source="status" choices={statusChoices} label="resources.projects.fields.status"/>
                <NumberInput source="investment" label="resources.projects.fields.investment"/>
                <NumberInput source="id" formClassName={this.props.classes.hiddenField}/>
              </SimpleFormIterator>
            </ArrayInput>
            
          </FieldGroup>

          <FieldGroup label="fieldGroups.galleryItems" formClassName={this.props.classes.borderBottom}>
            <FormDataConsumer>
              {({formData}) => 
                <GalleryItemsInput
                  value={formData?.project_gallery_items}
                  change={this.props.change}/>
              }
            </FormDataConsumer>
          </FieldGroup>
          
        </SimpleForm>
      </>
    );
  }
}

const mapDispatchToProps = {change, submit};

ProjectForm.propTypes = {
  classes: PropTypes.shape({
    borderBottom: PropTypes.string.isRequired,
    hiddenField: PropTypes.string.isRequired,
    formCol: PropTypes.string.isRequired
  })
};

export default withRouter(withStyles(styles)(connect(state => ({}),mapDispatchToProps)(translate(ProjectForm))));
