import React, { Component } from 'react';
import Collapse from "@kunukn/react-collapse";
import styles from './styles';
import titles from './titles';
import { parseAudit } from '~utils/mappers';

class AuditsDisplay extends Component {

    state = {
        open: false
    };

    data;
    currentList;
    currentListTitle;

    toggle = () => {
        this.setState(prevState => ({open: !prevState.open }));
    };

    renderData = (key) => {
        if(this.data[key] && titles[key]) {
            if(key == 'staticCenter' || key == 'dynamicCenter') {
                return this.renderCenter(key, this.data[key])
            } else if(key == 'image') {
                return this.renderSimpleData(key, this.data[key].file);
            } else if(Array.isArray(this.data[key])) {
                return this.renderArray(key, this.data[key]);
            } else if(key != 'id' && key !='updatedAt') {
                return this.renderSimpleData(key, this.data[key]);
            }
        } else {
            return (<></>)
        }
    }

    renderSimpleData = (key, value) => {
        let title = titles[key];
        let isUrl = value?.toString().includes('https');
        return (
            <>
                <span style={styles.dataTitle}>{title}</span>
                <br/>
                {isUrl ? 
                    <a style={styles.dataValue} href={value} target="_blank">{value}</a>
                    :
                    <span style={styles.dataValue}>{value}</span> 
                }
                <br/>
                <br/>
            </>
        )
    }

    renderCenter = (key, value) => {
        let titleLat = 'Latitud mapa dinámico';
        let titleLng = 'Longitud mapa dinámico';
        if(key.includes('static')) {
            titleLat = 'Latitud mapa estático';
            titleLng = 'Longitud mapa estático';
        }
        return (
            <>
                <span style={styles.dataTitle}>{titleLat}</span>
                <br/>
                <span style={styles.dataValue}>{value.latitude}</span>
                <br/>
                <br/>

                <span style={styles.dataTitle}>{titleLng}</span>
                <br/>
                <span style={styles.dataValue}>{value.longitude}</span>
                <br/>
                <br/>
            </>
        )
    }

    renderArray = (key, value) => {
        if(value.length) {
            let title = titles[key];
            this.currentListTitle = title;
            this.currentList = value;
            return (
                <>
                    <h2>{title}</h2>
                    {value.map(this.renderNewDisplay)}
                    <br/>
                </>
            )
        } else {
            return(<></>);
        }
    }

    renderNewDisplay = (data) => {
        if(typeof data === 'object' && data !== null) {
            let title = this.currentListTitle;
            let idx = this.currentList.indexOf(data) + 1;
            return (<AuditsDisplay data={{data: data}} title={title} idx={idx}/>)
        } else {
            return (<>test</>)
        }
    }

    formatDateTime = (datetime) => {
        let date = datetime.split('T')[0];
        let time = datetime.split('T')[1].split('.')[0];
        return `${date} ${time}`;
    }

    renderUser = () => {
        let usr = this.props.data.user;
        return (
            <>
                <span style={styles.userLabel}>Nombre: </span> 
                <span style={styles.userData}>{usr.name}</span>
                <br/>
                <span style={styles.userLabel}>Email: </span> 
                <span style={styles.userData}>{usr.email}</span>
                <br/>
                <span style={styles.userLabel}>Phone: </span> 
                <span style={styles.userData}>{usr.phone}</span>
                <br/>
            </>
        );
    }
    
    render() {
        this.data = parseAudit(this.props.data.data)
        return (
            <>
            <div onClick={() => this.toggle()} style={styles.header}>
                <span style={styles.openBtn}>
                    <svg style={this.state.open ? styles.iconDown : null } viewBox="6 0 12 24">
                        <polygon points="8 0 6 1.8 14.4 12 6 22.2 8 24 18 12" />
                    </svg>
                </span>
                <div style={styles.dateTitle}>
                    {this.props.title ?
                        `${this.props.title} - ${this.props.idx}`
                        :
                        this.formatDateTime(this.props.data.createdDate) 
                    }   
                </div>
            </div>

            <Collapse
                isOpen={this.state.open}
                transition="height 800ms cubic-bezier(0.4, 0, 0.2, 1)"
                render={(state) => (
                    <div style={styles.content}>
                        {this.props.data.user ? 
                            <>
                                <h2>Usuario</h2>
                                {this.renderUser()}
                            </>
                            : 
                            <></>
                        }
                        {!this.props.title ? <h2>Cambios</h2> : <></>}
                        {Object.keys(this.data).map(this.renderData)}
                    </div>
                )}
            />
            </>
        );
    }

}

export default AuditsDisplay;
