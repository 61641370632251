import { tuna } from '~utils/colors';

const HEADER_COLOR = '#F2F2F2';
const ROW_EVEN_COLOR = '#FFF';
const ROW_ODD_COLOR = '#F7F7F7';

const styles = {
  headerCell: {
    color: tuna,
    backgroundColor: HEADER_COLOR,
    fontWeight: 700,
    textTransform: 'uppercase'
  },
  rowEven: {
    backgroundColor: ROW_EVEN_COLOR
  },
  rowOdd: {
    backgroundColor: ROW_ODD_COLOR
  }
};

export default styles;
