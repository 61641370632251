import React from 'react';
import { EditButton, DeleteWithConfirmButton } from 'react-admin';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { compose, defaultProps } from 'recompose';

import styles from './styles';

function ActionsField({ classes, basePath, record, resource }) {
  const commonProps = { basePath, resource, record };
  return (
    <div className={classes.container}>
      {record.editable && (
        <>
          <DeleteWithConfirmButton classes={{ deleteButton: classes.button }} label="" {...commonProps} />
          <EditButton className={classes.button} label="" {...commonProps} />
        </>
      )}
    </div>
  );
}

ActionsField.propTypes = {
  basePath: PropTypes.string.isRequired,
  classes: PropTypes.shape({
    button: PropTypes.string.isRequired,
    container: PropTypes.string.isRequired
  }).isRequired,
  resource: PropTypes.string.isRequired,
  record: PropTypes.object // eslint-disable-line react/forbid-prop-types
};

export default compose(
  defaultProps({ label: 'common.actions' }),
  withStyles(styles)
)(ActionsField);
