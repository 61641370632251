import { ALTO } from '~constants/colors';

const styles = {
  borderBottom: {
    borderBottom: `2px solid ${ALTO}`,
    marginBottom: '50px',
    paddingBottom: '50px'
  },
  inputLabel: {
    color: '#7b7b7b',
    fontSize: '13px',
    marginTop: '13px',
    display: 'block'
  },
  richContainer: {
    maxWidth: '650px'
  }
};

export default styles;
