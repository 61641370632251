import { createMuiTheme } from '@material-ui/core/styles';

import { SILVER_CHALICE } from '../constants/colors';

export const appTheme = createMuiTheme({
  palette: {
    secondary: {
      main: SILVER_CHALICE
    }
  },
  typography: {
    fontFamily: ['Archivo', 'Arial', 'Helvetica', 'sans-serif'].join(',')
  }
});
