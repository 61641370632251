import { BLACK, MINE_SHAFT, SILVER, WHITE } from '~constants/colors';

const styles = {
  loginContainer: {
    display: 'flex',
    'justify-content': 'space-around'
  },
  formContainer: {
    display: 'flex',
    'justify-content': 'center',
    'flex-direction': 'column',
    'font-family': 'Archivo',
    margin: '0 10%'
  },
  formDisplay: {
    display: 'flex',
    'justify-content': 'center',
    'flex-direction': 'column'
  },
  formLogo: {
    padding: '40px'
  },
  inputInfo: {
    'font-size': '14px',
    'font-weight': 500,
    'line-height': '16px',
    'margin-top': '20px',
    'text-align': 'left',
    color: MINE_SHAFT
  },
  input: {
    border: `solid 1px ${SILVER}`,
    background: WHITE,
    'border-radius': '10px',
    'box-sizing': 'border-box',
    padding: '7px',
    height: '46px',
    width: '260px'
  },
  submitInput: {
    'text-align': 'center',
    'border-radius': '10px',
    background: BLACK,
    'border-style': 'none',
    color: WHITE,
    'font-size': '16px',
    'font-weight': 600,
    'margin-top': '48px',
    padding: '7px',
    height: '46px',
    width: '260px'
  },
  submitText: {
    padding: '15px 0'
  },
  senderaLogo: {
    height: '100vh'
  }
};

export default styles;
