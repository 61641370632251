import React, { Component } from 'react';
import {
  SimpleForm,
  TextInput,
  translate,
  SelectInput,
  FileInput,
  NumberInput,
  BooleanInput,
  ArrayInput,
  SimpleFormIterator,
  FormDataConsumer,
  LongTextInput,
  Confirm,
  SelectArrayInput
} from 'react-admin';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { change, submit } from 'redux-form';
import Button from '@material-ui/core/Button';
import { withRouter } from 'react-router-dom';
import RichTextInput from 'ra-input-rich-text';

import {
  iirsaStatusChoices,
  iirsaSectorChoices,
  projectScopeChoices,
  iirsaStagesChoices,
  portfolioChoices,
  projectTypeChoices,
  studiesStateChoices,
  studiesFunderChoices,
  iirsaFundingTypesChoices,
  iirsaFundingStatusChoices
} from '~constants/fieldGroups';
import { isRequired } from '~utils/inputValidations';
import FieldGroup from '~components/FieldGroup';
import FileLinkField from '~components/FileLinkField';
import GalleryItemsInput from '~components/GalleryItemsInput';
import { SourcesService } from '~config/services/sources.service';
import { ProjectService } from '~config/services/project.service';
import { IirsaCountriesService } from '~config/services/iirsa_countries.service';
import { SlugsService } from '~config/services/slugs.service';

import DynamicMap from '../components/DynamicMap';
import ModalMapPreview from '../components/ModalMapPreview';
import './styles.scss';
import NestedSelectInput from '../components/NestedSelectInput';

import styles from './styles';

const VolverButton = props => (
  <>
    <Button style={styles.volverButton} onClick={props.onVolverClick}>
      <svg style={styles.chevron} viewBox="0 0 32 32" class="icon icon-chevron-left">
        <path
          fill="#3f51b5"
          d="M14.19 16.005l7.869 7.868-2.129 2.129-9.996-9.997L19.937 6.002l2.127 2.129z"
        />
      </svg>
      Volver
    </Button>
    <Confirm
      isOpen={props.showConfirm}
      title="¿Desea salir del formulario?"
      content="Los cambios realizados se perderán"
      confirm="Si"
      confirmColor="primary"
      cancel="Cancelar"
      onConfirm={props.onVolverConfirm}
      onClose={props.onVolverCancel}
    />
  </>
);

const toolbarOptions = [['bold', 'italic', 'underline', 'link'], [{ list: 'bullet' }]];

class IirsaProjectForm extends Component {
  state = {
    sources: [],
    projects: [],
    cuntries: [],
    slugs: [],
    showConfirm: false
  };

  firstForm = null;
  hasChanged = false;

  constructor() {
    super();
    const ss = new SourcesService();
    ss.get().then(r => this.setState({ sources: r.data }));

    const ps = new ProjectService();
    ps.get_choices().then(r => this.setState({ projects: r.data }));

    const cs = new IirsaCountriesService();
    cs.get_la().then(r => {
      let data = r.data;
      data.sort((a, b) => (a.name > b.name ? 1 : -1));
      this.setState({ countries: data });
    });

    const slgS = new SlugsService();
    slgS.get_from_project().then(r => {
      this.setState({ slugs: r.data });
    });
  }

  validateProjectForm = values => {
    const errors = {};
    if (!values.iirsa_countries || values.iirsa_countries.length == 0) {
      errors.iirsa_countries = ['Debe seleccionar al menos un país'];
    }
    if (values['slug'] && this.state.slugs?.indexOf(values.slug) > -1) {
      errors.slug = 'Este slug ya se encuentra en uso';
    }
    if (values['image'] && values['image'].rawFile && values['image'].rawFile.size > 15000000) {
      errors.image = 'El tamaño de la imagen no puede ser mayor a 15MB';
    }
    return errors;
  };

  onVolverClick = () => {
    if (this.hasChanged) {
      this.setState({ showConfirm: true });
    } else {
      this.props.history.push('/projects');
    }
  };

  onVolverConfirm = () => {
    this.props.history.push('/iirsaProjects');
  };

  onVolverCancel = () => {
    this.setState({ showConfirm: false });
  };

  isCreateForm() {
    return this.props.location?.pathname.endsWith('/create');
  }

  render() {
    return (
      <>
        <VolverButton
          showConfirm={this.state.showConfirm}
          onVolverClick={this.onVolverClick}
          onVolverConfirm={this.onVolverConfirm}
          onVolverCancel={this.onVolverCancel}
        />
        <SimpleForm validate={this.validateProjectForm} {...this.props}>
          {this.children}

          <FormDataConsumer>
            {data => {
              if (!this.firstForm) {
                this.firstForm = data;
              } else {
                if (!(JSON.stringify(data) === JSON.stringify(this.firstForm))) {
                  this.hasChanged = true;
                }
              }
            }}
          </FormDataConsumer>

          <FieldGroup
            label="fieldGroups.projectInformation"
            formClassName={this.props.classes.borderBottom}
            titleColumn={false}
            flex={false}
          >
            <TextInput source="name" validate={isRequired} className={'colInput'} />
            {this.isCreateForm() ?
              <TextInput source="code" validate={isRequired} className={'colInput'} /> :
              <TextInput source="code" className={'colInput'} disabled/>
            }
          </FieldGroup>

          <FieldGroup
            label="fieldGroups.scope"
            formClassName={this.props.classes.borderBottom}
            titleColumn={false}
            flex={false}
          >
            <div className={'colInputContainer'}>
              <label style={styles.inputLabel}>Objetivos (*)</label>
              <RichTextInput source="goal" toolbar={toolbarOptions} validate={isRequired} />
            </div>
            <div className={'colInputContainer'}>
              <label style={styles.inputLabel}>Descripción (*)</label>
              <RichTextInput source="description" toolbar={toolbarOptions} validate={isRequired} />
            </div>
          </FieldGroup>

          <FieldGroup
            label="fieldGroups.ilat"
            formClassName={this.props.classes.borderBottom}
            titleColumn={false}
            flex={false}
          >
            <BooleanInput source="is_portfolio" className={'colInputContainer'} />
            <BooleanInput source="accompaniment" className={'colInputContainer'} />
            <BooleanInput source="is_bid" className={'colInputContainer'} />
            <BooleanInput source="is_caf" className={'colInputContainer'} />
            <BooleanInput source="is_fonplata" className={'colInputContainer'} />
            <BooleanInput source="is_anchor" className={'colInputContainer'} />
          </FieldGroup>

          <FieldGroup
            label="fieldGroups.general"
            formClassName={this.props.classes.borderBottom}
            titleColumn={false}
            flex={false}
          >
            <div>
              <FormDataConsumer>
                {({ formData }) => (
                  <SelectArrayInput
                    source="iirsa_countries"
                    label={'Paises (*)'}
                    value={formData?.iirsa_countries}
                    choices={this.state.countries}
                    validate={isRequired}
                    className={'colInput'}
                  />
                )}
              </FormDataConsumer>
            </div>

            <SelectInput
              source="iirsa_scope"
              choices={projectScopeChoices}
              validate={isRequired}
              className={'colInput'}
            />

            <NestedSelectInput
              label="Sector"
              source="iirsa_sector"
              fstSource="id"
              sndSource="iirsa_subsector_id"
              fstLabel="Sector (*)"
              sndLabel="Subsector (*)"
              fstChoices={iirsaSectorChoices}
              // eslint-disable-next-line react/jsx-no-bind
              getSndChoices={fstValue => fstValue.subsectors}
              validate={isRequired}
            />

            <SelectInput
              source="iirsa_project_type"
              choices={projectTypeChoices}
              validate={isRequired}
              className={'colInput'}
            />
          </FieldGroup>

          <FieldGroup
            label="fieldGroups.projectStatus"
            formClassName={this.props.classes.borderBottom}
            titleColumn={false}
            flex={false}
          >
            <SelectInput
              source="iirsa_stage"
              choices={iirsaStagesChoices}
              validate={isRequired}
              className={'colInput'}
            />

            <SelectInput
              source="iirsa_status"
              choices={iirsaStatusChoices}
              className={'colInput'}
            />

            <BooleanInput source="has_ambiental_license" className={'colInputContainer'} />

            <SelectInput
              source="iirsa_studies_state"
              choices={studiesStateChoices}
              className={'colInput'}
            />

            <BooleanInput source="studies_finished" className={'colInputContainer'} />

            <NumberInput source="studies_amount" className={'colInput'} />

            <SelectInput
              source="iirsa_studies_funder"
              choices={studiesFunderChoices}
              className={'colInput'}
            />

            <div className={'colInputContainer'}>
              <label style={styles.inputLabel}>Comentarios sobre el estado</label>
              <RichTextInput source="status_comments" toolbar={toolbarOptions} />
            </div>

            <div className={'colInputContainer'}>
              <label style={styles.inputLabel}>Comentarios sobre el estado de los estudios</label>
              <RichTextInput source="studies_status_comments" toolbar={toolbarOptions} />
            </div>

            <div className={'colInputContainer'}>
              <label style={styles.inputLabel}>Comentarios sobre el financiamiento de los estudios</label>
              <RichTextInput source="studies_funding_comments" toolbar={toolbarOptions} />
            </div>

          </FieldGroup>

          <FieldGroup
            label="fieldGroups.projectFunding"
            formClassName={this.props.classes.borderBottom}
            titleColumn={false}
            flex={false}
          >
            <ArrayInput source="iirsa_project_funding">
              <SimpleFormIterator>
                <NumberInput source="amount" label="resources.iirsaProjects.fields.amount" validate={isRequired} />
                <SelectInput
                  source="funder"
                  choices={studiesFunderChoices}
                  label="resources.iirsaProjects.fields.iirsa_studies_funder"
                />
                <SelectInput
                  source="funding_status"
                  choices={iirsaFundingStatusChoices}
                  label="resources.iirsaProjects.fields.iirsa_funding_status"
                />
                <SelectInput
                  source="funding_type"
                  choices={iirsaFundingTypesChoices}
                  label="resources.iirsaProjects.fields.iirsa_funding_type"
                />
              </SimpleFormIterator>
            </ArrayInput>

            <div className={'colInputContainer'}>
              <label style={styles.inputLabel}>Comentarios sobre el financiamiento</label>
              <RichTextInput source="funding_comments" toolbar={toolbarOptions} />
            </div>
          </FieldGroup>
          
          <FieldGroup
            label="fieldGroups.dynamicMap" 
            formClassName={this.props.classes.borderBottom}
            titleColumn={false}>
    
            <FileInput source="kml" accept=".kml">
              <FileLinkField />
            </FileInput>
    
            <NumberInput source="dynamic_center_latitude" defaultValue={1}/>
            <NumberInput source="dynamic_center_longitude" defaultValue={1}/>
            <NumberInput source="dynamic_map_zoom" defaultValue={1}/>

            <div style={{margin: 'auto'}}>
              <FormDataConsumer>
                {({ formData }) =>
                    (formData && formData.kml) ?
                  <>
                    <h3>Ajustar mapa</h3>
                    <DynamicMap
                      kml={formData.kml}
                      centerLat={formData.dynamic_center_latitude}
                      centerLng={formData.dynamic_center_longitude}
                      zoom={formData.dynamic_map_zoom}
                      change={this.props.change}
                      dynamic={true}
                    />
                  </>
                  :
                  <></>
                }
              </FormDataConsumer>
            </div>
          </FieldGroup>

          <FieldGroup
            label="fieldGroups.staticMap" 
            formClassName={this.props.classes.borderBottom}
            titleColumn={false}>
    
            <FileInput source="kml_simplified" accept=".kml">
              <FileLinkField />
            </FileInput>
    
            <NumberInput source="static_center_latitude" defaultValue={1}/>
            <NumberInput source="static_center_longitude" defaultValue={1}/>
            <NumberInput source="static_map_zoom" defaultValue={1}/>

            <div style={{margin: 'auto'}}>
              <FormDataConsumer>
                {({ formData }) =>
                    (formData && formData.kml_simplified) ?
                  <>
                    <h3>Ajustar mapa</h3>
                    <DynamicMap
                      kml={formData.kml_simplified}
                      centerLat={formData.static_center_latitude}
                      centerLng={formData.static_center_longitude}
                      zoom={formData.static_map_zoom}
                      change={this.props.change}
                      dynamic={false}
                    />
                  </>
                  :
                  <></>
                }
              </FormDataConsumer>
            </div>
          </FieldGroup>

          {/* <FieldGroup
            label="fieldGroups.dynamicMap"
            formClassName={this.props.classes.borderBottom}
            titleColumn={false}
          >
            <FileInput source="kml" accept=".kml">
              <FileLinkField />
            </FileInput>

            <NumberInput source="dynamic_map_latitude" defaultValue={1} />
            <NumberInput source="dynamic_map_longitude" defaultValue={1} />
            <NumberInput source="dynamic_map_zoom" defaultValue={1} />

            <div style={{ margin: 'auto' }}>
              <FormDataConsumer>
                {({ formData }) =>
                  formData && formData.kml ? (
                    <>
                      <h3>Ajustar mapa</h3>
                      <DynamicMap
                        kml={formData.kml}
                        centerLat={formData.dynamic_map_latitude}
                        centerLng={formData.dynamic_map_longitude}
                        zoom={formData.dynamic_map_zoom}
                        change={this.props.change}
                        dynamic={true}
                      />
                    </>
                  ) : (
                    <></>
                  )
                }
              </FormDataConsumer>
            </div>

            <br />

            <div style={styles.dynamicPreviewBtn}>
              <FormDataConsumer>
                {({ formData }) =>
                  formData && formData.kml ? (
                    <>
                      <ModalMapPreview formData={formData} />
                    </>
                  ) : (
                    <></>
                  )
                }
              </FormDataConsumer>
            </div>
          </FieldGroup> */}

          <FieldGroup
            label="fieldGroups.projectSections"
            formClassName={this.props.classes.borderBottom}
            titleColumn={false}
            flex={false}
          >
            <ArrayInput source="iirsa_segments">
              <SimpleFormIterator>
                <TextInput source="segment" label="resources.iirsaProjects.fields.name" validate={isRequired} />
                <LongTextInput source="description" label="resources.projects.fields.description" />
                <SelectInput
                  source="iirsa_stage"
                  choices={iirsaStagesChoices}
                  label="resources.iirsaProjects.fields.stage"
                />
                <NumberInput source="investment" label="resources.projects.fields.investment" />
                <NumberInput source="segment_number" formClassName={this.props.classes.hiddenField} />
              </SimpleFormIterator>
            </ArrayInput>
          </FieldGroup>

          <FieldGroup label="fieldGroups.galleryItems" formClassName={this.props.classes.borderBottom}>
            <FormDataConsumer>
              {({formData}) => {
                return (<GalleryItemsInput
                  value={formData.project_gallery_items}
                  change={this.props.change}/>);
              }}
            </FormDataConsumer>
          </FieldGroup>
        </SimpleForm>
      </>
    );
  }
}

const mapDispatchToProps = { change, submit };

IirsaProjectForm.propTypes = {
  classes: PropTypes.shape({
    borderBottom: PropTypes.string.isRequired,
    hiddenField: PropTypes.string.isRequired,
    formCol: PropTypes.string.isRequired
  })
};

export default withRouter(
  withStyles(styles)(
    connect(
      state => ({}),
      mapDispatchToProps
    )(translate(IirsaProjectForm))
  )
);
