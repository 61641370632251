import { RESOURCES } from 'constants/config';

import axios from 'axios';
import { getApiHeaders } from 'utils/helpers';

import { apiSetup } from '../api';

apiSetup();

export class ImagesService {
  IMAGES_BASE_URL = `${process.env.REACT_APP_BASE_URL}backoffice/images`;

  PROJECT_ID_FIELD = 'project_id';

  API_HEADERS = getApiHeaders();

  constructor(reource) {
    if (reource === 'iirsaProjects') {
      this.IMAGES_BASE_URL = `${process.env.REACT_APP_BASE_URL}backoffice/iirsa_images`;
      this.PROJECT_ID_FIELD = 'iirsa_project_id';
    }
  }

  create(id, data) {
    const formData = this.createFormData(data, id);
    return axios.post(`${this.IMAGES_BASE_URL}/`, formData, { headers: this.API_HEADERS });
  }

  update(data) {
    const formData = this.createFormData(data);
    return axios.put(`${this.IMAGES_BASE_URL}/${data.id}`, formData, { headers: this.API_HEADERS });
  }

  deleteMany(array, id) {
    const formData = new FormData();
    formData.append(this.PROJECT_ID_FIELD, id);
    formData.append('ids', array);
    return axios.post(`${this.IMAGES_BASE_URL}/delete_many/`, formData, {
      headers: this.API_HEADERS
    });
  }

  // eslint-disable-next-line class-methods-use-this
  createFormData(data, id = null) {
    const formData = new FormData();
    formData.append('file', data.file);
    formData.append('order', data.order);
    if (id) {
      formData.append(this.PROJECT_ID_FIELD, id);
    }
    if (data.model_id) {
      formData.append('id', data.model_id);
    }
    return formData;
  }
}
