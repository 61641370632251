import React from 'react';
import { Sidebar } from 'react-admin';

import styles from './styles.module.scss';

function CustomSidebar(props) {
  return <Sidebar className={styles.container} {...props} size={200} />;
}

export default CustomSidebar;
