/* eslint-disable react/jsx-no-bind */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { translate, userLogin, Notification } from 'react-admin';
import { withStyles } from '@material-ui/core/styles';

// eslint-disable-next-line import/extensions
import login from '~assets/login.jpg';
import logo from '~assets/logo.svg';

import styles from './styles';

class Login extends Component {
  state = {
    username: '',
    password: ''
  };

  handleChange = ({ target }) => {
    const { name, value } = target;
    this.setState({ [name]: value });
  };

  handleSubmit = e => {
    e.preventDefault();
    const { username, password } = this.state;
    this.props.userLogin({ username, password });
  };

  render() {
    const { classes, translate: t } = this.props;

    return (
      <>
        <div className={classes.loginContainer}>
          <div className={classes.formContainer}>
            <img src={logo} alt="senderaLogo" className={classes.formLogo} />
            <div className="formInfoContainer">
              <form className={classes.formDisplay} onSubmit={this.handleSubmit}>
                <p className={classes.inputInfo}>{t('login.Username')}</p>
                <input type="text" name="username" className={classes.input} onChange={this.handleChange} />
                <p className={classes.inputInfo}>{t('login.Password')}</p>
                <input
                  type="password"
                  name="password"
                  className={classes.input}
                  onChange={this.handleChange}
                />
                <button type="submit" className={classes.submitInput} onSubmit={this.handleSubmit}>
                  {t('login.Continue')}
                </button>
              </form>
            </div>
          </div>
          <img src={login} alt="login" className={classes.senderaLogo} />
        </div>
        <Notification />
      </>
    );
  }
}

Login.propTypes = {
  translate: PropTypes.func.isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  userLogin: PropTypes.func.isRequired,
  classes: PropTypes.shape({
    formContainer: PropTypes.string.isRequired,
    formDisplay: PropTypes.string.isRequired,
    formLogo: PropTypes.string.isRequired,
    input: PropTypes.string.isRequired,
    inputInfo: PropTypes.string.isRequired,
    loginContainer: PropTypes.string.isRequired,
    senderaLogo: PropTypes.string.isRequired,
    submitInput: PropTypes.string.isRequired
  })
};

export default withStyles(styles)(
  connect(
    undefined,
    { userLogin }
  )(translate(Login))
);
