import React from 'react';
import { 
  List, 
  TextField, 
  translate, 
  ArrayField, 
  SingleFieldList, 
  ChipField,
  SelectInput
} from 'react-admin';

import { defaultListProps } from '~constants/config';
import { StringToLabelObject } from '~components/StringToLabelObject';
import TranslatedChipField from '~components/TranslatedChipField';
import CustomDatagrid from '~components/CustomDatagrid';

import SearchFilter from '../SearchFilter';
import exporter from './exporter';

function SearchList(props) {
  return (
    <List {...defaultListProps} filters={<SearchFilter />} {...props} exporter={exporter}>
      <CustomDatagrid rowClick="edit">
        <TextField sortBy="created_at" source="date" />
        <TextField source="searchString" />
        <ArrayField source="contentType" sortable={false}>
          <SingleFieldList>
            <StringToLabelObject>
              <TranslatedChipField translatePrefix="common.sections" source="label" />
            </StringToLabelObject>
          </SingleFieldList>
        </ArrayField>
        <ArrayField source="location" sortable={false}>
          <SingleFieldList>
            <StringToLabelObject>
              <ChipField source="label" />
            </StringToLabelObject>
          </SingleFieldList>
        </ArrayField>
        <TextField source="investment" />
        <TextField source="infrastructure" />
        <TextField source="financing" />
        <TextField source="stage" />
        <TextField source="topic" />
        <TextField source="type_filter" />
      </CustomDatagrid>
    </List>
  );
}

export default translate(SearchList);
