import React from 'react';
import { Filter, TextInput, DateInput } from 'react-admin';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import { truncate } from '~constants/styles';

function SearchSummaryFilter({ classes, ...props }) {
  return (
    <Filter {...props}>
      <TextInput source="searchString" className={classes.truncate} />
      <DateInput source="date" />
    </Filter>
  );
}

SearchSummaryFilter.propTypes = {
  classes: PropTypes.shape({
    truncate: PropTypes.string.isRequired
  }).isRequired
};

export default withStyles(truncate)(SearchSummaryFilter);
