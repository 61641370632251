import { AUTH_LOGIN, AUTH_LOGOUT, AUTH_CHECK, AUTH_ERROR, AUTH_GET_PERMISSIONS } from 'react-admin';

import { FORBIDDEN, UNAUTHORIZED } from '~constants/errors';
import { requiredHeaders } from '~constants/config';
import { userLogin } from '~services/AuthServices';
import { setAuthHeaders, checkAuthHeaders, storeUserData, clearUserData } from '~utils/helpers';

export const ADMINISTRATOR = 'administrator';

function authProvider(type, params) {
  if (type === AUTH_LOGIN) {
    const { username, password } = params;
    return userLogin({ username, password }).then(response => {
      if (!response.ok) {
        throw Error('auth.credentialsError');
      }
      const {
        headers,
        data: { data }
      } = response;
      setAuthHeaders(headers);
      storeUserData(data);
      return Promise.resolve();
    });
  }
  if (type === AUTH_LOGOUT) {
    requiredHeaders.forEach(header => localStorage.removeItem(header));
    clearUserData();
    return Promise.resolve();
  }
  if (type === AUTH_ERROR) {
    const { status } = params;
    if (status === FORBIDDEN || status === UNAUTHORIZED) {
      requiredHeaders.forEach(header => localStorage.removeItem(header));
      return Promise.reject(Error());
    }
    return Promise.resolve();
  }
  if (type === AUTH_CHECK) {
    return checkAuthHeaders() ? Promise.resolve() : Promise.reject(Error());
  }
  if (type === AUTH_GET_PERMISSIONS) {
    const userType = localStorage.getItem('userType');
    return userType ? Promise.resolve(userType) : Promise.reject(Error());
  }
  return Promise.resolve();
}

export default authProvider;
