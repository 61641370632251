import React from 'react';
import { Layout } from 'react-admin';

import CustomAppBar from '../CustomAppBar';
import CustomSidebar from '../CustomSidebar';
import CustomMenu from '../CustomMenu';

import styles from './styles.module.scss'; // eslint-disable-line no-unused-vars

function CustomLayout(props) {
  return <Layout {...props} menu={CustomMenu} sidebar={CustomSidebar} appBar={CustomAppBar} />;
}

export default CustomLayout;
