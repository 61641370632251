import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import { SECTIONS } from './constants';
import Section from './components/Section';

class CustomMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: ''
    };
  }

  select = section => {
    this.setState({
      active: section
    });
  };

  render() {
    return (
      <>
        {SECTIONS.map(section => (
          <Section
            key={section.name}
            action={this.select}
            active={this.state.active === section.name}
            sectionName={section.name}
            items={section.items}
          />
        ))}
      </>
    );
  }
}

export default withRouter(CustomMenu);
