import React from 'react';
import PropTypes from 'prop-types';
import { translate } from 'ra-core';

function FileLinkField({ record, translate: t }) {
  if (!record) {
    return null;
  }

  return record.rawFile ? (
    <span>{record.rawFile.name}</span>
  ) : (
    <a href={record} target="_blank" rel="noopener noreferrer">
      {getFileName(record)}
    </a>
  );
}

function getFileName(url) {
  if(url) {
    return decodeURIComponent(url).split('/').pop()
  }
  return '';
}

FileLinkField.propTypes = {
  translate: PropTypes.func.isRequired,
  record: PropTypes.string
};

export default translate(FileLinkField);
