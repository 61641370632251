import React from 'react';
import { Show, SimpleShowLayout, translate, TextField, RichTextField } from 'react-admin';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import FieldGroup from '~components/FieldGroup';
import TranslatedTextField from '~components/TranslatedTextField';
import ShowCustomActions from '~components/ShowCustomActions';
import TopicsField from '~components/TopicsField';
import EventDateField from '~components/EventDateField';
import ImageList from '~components/ImageList';
import StarredTextField from '~components/StarredTextField';

import styles from './styles';

function EventShow({ classes, ...props }) {
  return (
    <Show {...props} actions={<ShowCustomActions {...props} />}>
      <SimpleShowLayout>
        <FieldGroup label="fieldGroups.eventInformation" formClassName={classes.borderBottom} show>
          <TextField source="title" />
          <TranslatedTextField source="eventType" translatePrefix="common.eventTypes" addLabel/>
          <TranslatedTextField source="infrastructure" translatePrefix="common.contentTypes" addLabel />
          <TextField source="location.country" />
          <TextField source="location.city" />
          <TopicsField fieldLabel="fieldGroups.topics" source="topics" />
          <RichTextField source="description" />
          <EventDateField source="eventSessions" label="fieldGroups.eventSessions" />
          <ImageList source="images" />
        </FieldGroup>
        <FieldGroup label="fieldGroups.contentConfiguration" formClassName={classes.borderBottom} show>
          <TranslatedTextField source="publicationStatus" translatePrefix="common" addLabel />
          <StarredTextField />
        </FieldGroup>
      </SimpleShowLayout>
    </Show>
  );
}

EventShow.propTypes = {
  classes: PropTypes.shape({
    borderBottom: PropTypes.string.isRequired
  })
};

export default withStyles(styles)(translate(EventShow));
