import React from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-admin';

import ListField from '~components/ListField';

function TextTopicsField({ className, translate: t, ...props }) {
  function renderTopic(topic) {
    return topic;
  }
  return <ListField className={className} renderItem={renderTopic} {...props} />;
}

TextTopicsField.propTypes = {
  translate: PropTypes.func.isRequired,
  className: PropTypes.string
};

export default translate(TextTopicsField);
