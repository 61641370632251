export const articleTypes = {
  article: 'article',
  infographic: 'infographic',
  notes: 'notes',
  news: 'news',
  publishing: 'publishing',
  video: 'video'
};

export const contentTypeChoices = [
  { id: articleTypes.article, name: 'resources.articles.types.article' },
  { id: articleTypes.infographic, name: 'resources.articles.types.infographic' },
  { id: articleTypes.publishing, name: 'resources.articles.types.publishing' },
  { id: articleTypes.video, name: 'resources.articles.types.video' }
];

export const textArticleTypes = [articleTypes.article, articleTypes.publishing];

export const publicationStatusChoices = [
  { id: 'draft', name: 'common.draft' },
  { id: 'published', name: 'common.published' }
];

export const sectionChoices = [
  { id: 'event', name: 'common.sections.event' },
  { id: 'project', name: 'common.sections.project' },
  { id: 'all', name: 'common.sections.all' },
  { id: 'article', name: 'common.sections.article' }
];

export const topicChoices = [
  { id: 'bim', name: 'common.topics.bim' },
  { id: 'blockchain', name: 'common.topics.blockchain' },
  { id: 'artificialintelligence', name: 'common.topics.artificialintelligence' },
  { id: 'iot', name: 'common.topics.iot' },
  { id: 'bigdata', name: 'common.topics.bigdata' }
];

export const infraestructureTypeChoices = [
  { id: 'energy', name: 'common.contentTypes.energy' },
  { id: 'transport', name: 'common.contentTypes.transport' },
  { id: 'boundary', name: 'common.contentTypes.boundary' },
  { id: 'telecommunications', name: 'common.contentTypes.telecommunications' }
];

export const areaTypeChoices = [
  { id: 'NACIONAL', name: 'common.areaTypes.nacional' },
  { id: 'BINACIONAL', name: 'common.areaTypes.binacional' }
];

export const bankChoices = [
  { id: 'bid', name: 'BID' },
  { id: 'caf', name: 'CAF' },
  { id: 'fonplata', name: 'FONPLATA' },
  { id: 'bid-caf', name: 'BID-CAF' },
  { id: 'bid-fonplata', name: 'BID-FONPLATA' },
  { id: 'caf-fonplata', name: 'CAF-FONPLATA' },
  { id: 'bid-caf-fonplata', name: 'BID-CAF-FONPLATA' }
];

export const eventTypeChoices = [
  { id: 'workshop', name: 'resources.events.types.workshop' },
  { id: 'meeting', name: 'resources.events.types.meeting' },
  { id: 'conference', name: 'resources.events.types.conference' },
  { id: 'training', name: 'resources.events.types.training' },
  { id: 'seminar', name: 'resources.events.types.seminar' }
];

export const countryChoices = [
  { id: 'Argentina', name: 'Argentina' },
  { id: 'Bahamas', name: 'Bahamas' },
  { id: 'Barbados', name: 'Barbados' },
  { id: 'Belice', name: 'Belice' },
  { id: 'Bolivia', name: 'Bolivia' },
  { id: 'Brasil', name: 'Brasil' },
  { id: 'Chile', name: 'Chile' },
  { id: 'Colombia', name: 'Colombia' },
  { id: 'Costa Rica', name: 'Costa Rica' },
  { id: 'Ecuador', name: 'Ecuador' },
  { id: 'El Salvador', name: 'El Salvador' },
  { id: 'Guatemala', name: 'Guatemala' },
  { id: 'Guyana', name: 'Guyana' },
  { id: 'Haití', name: 'Haití' },
  { id: 'Honduras', name: 'Honduras' },
  { id: 'Jamaica', name: 'Jamaica' },
  { id: 'México', name: 'México' },
  { id: 'Nicaragua', name: 'Nicaragua' },
  { id: 'Panamá', name: 'Panamá' },
  { id: 'Paraguay', name: 'Paraguay' },
  { id: 'Perú', name: 'Perú' },
  { id: 'República Dominicana', name: 'República Dominicana' },
  { id: 'Suriname', name: 'Suriname' },
  { id: 'Trinidad y Tobago', name: 'Trinidad y Tobago' },
  { id: 'Uruguay', name: 'Uruguay' },
  { id: 'Venezuela', name: 'Venezuela' },
  { id: 'África', name: 'África' },
  { id: 'América del Norte', name: 'América del Norte' },
  { id: 'Antártida', name: 'Antártida' },
  { id: 'Asia', name: 'Asia' },
  { id: 'Europa', name: 'Europa' },
  { id: 'Oceanía', name: 'Oceanía' },
  { id: 'On Line', name: 'On Line' }
];

export const statusChoices = [
  { id: 'profiling', name: 'Perfil' },
  { id: 'pre_execution', name: 'Pre-ejecución' },
  { id: 'execution', name: 'Ejecución' },
  { id: 'finished', name: 'Concluido' }
];

export const financingChoices = [
  { id: 'public_project', name: 'Público' },
  { id: 'private_project', name: 'Privado' },
  { id: 'mixed_project', name: 'Mixto' }
];

export const infraestructureChoices = [
  { id: 'energy', name: 'Energía' },
  { id: 'transport', name: 'Transporte y logística' },
  { id: 'boundary', name: 'Fronteras' },
  { id: 'telecommunications', name: 'Telecomunicaciones' }
];

export const subsectorChoices = [
  { id: 'CARRETERO', name: 'Carretero' },
  { id: 'FLUVIAL', name: 'Fluvial' },
  { id: 'AÉREO', name: 'Aéreo' },
  { id: 'PASOS DE FRONTERA', name: 'Pasos de frontera' },
  { id: 'FERROVIARIO', name: 'Ferroviario' },
  { id: 'INTERCONEXION ENERGÉTICA', name: 'Interconexión energética' },
  { id: 'VIALIDAD', name: 'Vialidad' },
  { id: 'MULTIMODAL', name: 'Multimodal' },
  { id: 'GENERACIÓN ENERGÉTICA', name: 'Generación energética' }
];

export const sortByChoices = [
  { id: 'created_at', name: 'Fecha de creación' },
  { id: 'updated_at', name: 'Fecha de última modificación' },
  { id: 'material_date', name: 'Fecha material' }
];

export const iirsaStatusChoices = [{ id: 1, name: 'CON AVANCES' }, { id: 2, name: 'SIN AVANCES' }];

export const iirsaInfraestructureChoices = [
  { id: 'energy', name: 'Energía' },
  { id: 'transport', name: 'Transporte y logística' },
  { id: 'boundary', name: 'Fronteras' },
  { id: 'telecommunications', name: 'Telecomunicaciones' }
];

export const iirsaSectorChoices = [
  {
    id: 1,
    name: 'Transporte',
    subsectors: [
      { id: 1, name: 'Aéreo' },
      { id: 2, name: 'Carretero' },
      { id: 3, name: 'Ferroviario' },
      { id: 4, name: 'Fluvial' },
      { id: 5, name: 'Pasos de Frontera' },
      { id: 6, name: 'Marítimo' },
      { id: 7, name: 'Multimodal' }
    ]
  },
  {
    id: 2,
    name: 'Energía',
    subsectors: [{ id: 8, name: 'Interconexión Energética' }, { id: 9, name: 'Generación Energética' }]
  },
  { id: 3, name: 'Comunicaciones', subsectors: [{ id: 10, name: 'Interconexión de comunicaciones' }] }
];

export const iirsaFundingSourcesChoices = [
  { id: 16, name: 'A DEFINIR' },
  { id: 22, name: 'A DEFINIR - PUBLICO' },
  { id: 23, name: 'A DEFINIR - PRIVADO' },
  { id: 1, name: 'BID' },
  { id: 13, name: 'BINACIONAL' },
  { id: 2, name: 'CAF' },
  { id: 3, name: 'FONPLATA' },
  { id: 24, name: 'GOBIERNO CHINO' },
  { id: 4, name: 'GRUPO BANCO MUNDIAL' },
  { id: 15, name: 'PRIVADOS / CORPORATIVO' },
  { id: 8, name: 'TESORO NACIONAL' },
  { id: 5, name: 'UNION EUROPEA' },
  { id: 18, name: 'VARIOS' },
  { id: 19, name: 'VARIOS - PUBLICO' },
  { id: 21, name: 'VARIOS - PRIVADO' },
  { id: 11, name: 'TESORO PROVINCIAL' }
];

export const iirsaStagesChoices = [
  { id: 1, name: 'PERFIL' },
  { id: 2, name: 'PRE-EJECUCIÓN' },
  { id: 3, name: 'EJECUCIÓN' },
  { id: 4, name: 'CONCLUIDO' }
];

// export const portfolioChoices = [
//   { id: undefined, name: 'Ninguno' },
//   { id: 1, name: '1' },
//   { id: 2, name: '2' },
//   { id: 3, name: '3' }
// ];

export const projectTypeChoices = [
  { id: 37, name: 'Adecuación de interconexiones energéticas' },
  { id: 15, name: 'Adecuación de puertos existentes fluviales (Ampliación)' },
  { id: 17, name: 'Adecuación/Ampliación infraestructura  existente  en centros de control fronterizo' },
  { id: 4, name: 'Ampliación de capacidad de la carretera' },
  { id: 20, name: 'Ampliación de la infraestructura terrestre de puertos marítimos' },
  { id: 1, name: 'Ampliación/ Adecuación de Aeropuertos' },
  { id: 30, name: 'Carboeléctrica' },
  { id: 12, name: 'Circunvalación ferroviaria' },
  { id: 10, name: 'Construcción de ferrovías' },
  { id: 8, name: 'Circunvalación vial (by-pass) y accesos a ciudades' },
  { id: 26, name: 'Construcción de nuevas interconexiones energéticas' },
  { id: 14, name: 'Construcción de nuevos puertos fluviales' },
  { id: 23, name: 'Estaciones de transferencia' },
  { id: 33, name: 'Fibra óptica' },
  { id: 38, name: 'Generación con energía nuclear' },
  { id: 28, name: 'Hidroeléctricas (nuevas y adecuación) - microcentrales' },
  { id: 16, name: 'Infraestructura para implantación de centros de control fronterizo' },
  { id: 36, name: 'Mantenimiento de carreteras' },
  { id: 13, name: 'Mejoramiento de la navegabilidad fluvial' },
  { id: 2, name: 'Nuevos aeropuertos' },
  { id: 19, name: 'Nuevos puertos marítimos' },
  { id: 32, name: 'Otras infraestructuras energéticas' },
  { id: 6, name: 'Pavimentación (obra nueva)' },
  { id: 7, name: 'Puentes (nuevos y rehabilitación)' },
  { id: 5, name: 'Rehabilitación de calzada y estructuras' },
  { id: 11, name: 'Rehabilitación de ferrovías' },
  { id: 39, name: 'Termoeléctrica' },
  { id: 25, name: 'Transporte multimodal' },
  { id: 9, name: 'Túneles (nuevos y rehabilitación)' }
];

export const projectScopeChoices = [
  { id: 1, name: 'NACIONAL' },
  { id: 2, name: 'BINACIONAL' },
  { id: 3, name: 'TRINACIONAL' },
  { id: 4, name: 'MULTINACIONAL' }
];

export const studiesStateChoices = [
  { id: 1, name: 'no iniciado' },
  { id: 2, name: 'en trámite' },
  { id: 3, name: 'aprobado' }
];

export const studiesFunderChoices = [
  { id: 16, name: 'A DEFINIR' },
  { id: 23, name: 'A DEFINIR - PRIVADO' },
  { id: 22, name: 'A DEFINIR - PUBLICO' },
  { id: 1, name: 'BID' },
  { id: 13, name: 'BINACIONAL' },
  { id: 2, name: 'CAF' },
  { id: 3, name: 'FONPLATA' },
  { id: 4, name: 'GRUPO BANCO MUNDIAL' },
  { id: 24, name: 'Gobierno Chino' },
  { id: 7, name: 'JBIC' },
  { id: 15, name: 'PRIVADOS / CORPORATIVO' },
  { id: 8, name: 'TESORO NACIONAL' },
  { id: 11, name: 'TESORO PROVINCIAL' },
  { id: 5, name: 'UNION EUROPEA' },
  { id: 18, name: 'VARIOS' },
  { id: 21, name: 'VARIOS - PRIVADO' },
  { id: 19, name: 'VARIOS - PUBLICO' }
];

export const iirsaFundingTypesChoices = [
  { id: 1, name: 'PÚBLICO' },
  { id: 2, name: 'PRIVADO' },
  { id: 3, name: 'PÚBLICO/PRIVADO' }
];

export const iirsaFundingStatusChoices = [
  { id: 2, name: 'no iniciado' },
  { id: 4, name: 'en trámite' },
  { id: 3, name: 'aprobado' },
  { id: 5, name: 'Adjudicado' },
  { id: 1, name: 'en desembolso' },
  { id: 6, name: 'completado' }
];
