import { create } from 'apisauce';
import { setApiHeaders } from 'utils/helpers';

import { serializer, deserializer } from './serializer';

const baseURL = process.env.REACT_APP_BASE_URL;

if (baseURL === 'http://wolox.com') {
  console.warn('API baseURL has not been properly initialized'); // eslint-disable-line no-console
}

const STATUS_CODES = {
  unauthorized: 401
};

const api = create({
  baseURL,
  timeout: 15000
});

export const apiSetup = () => {
  setApiHeaders();

  api.addMonitor(response => {
    if (response.problem === 'NETWORK_ERROR') {
      // TODO: These callbacks should only be called if no other callback was asigned for the response.
    }
  });

  api.addResponseTransform(response => {
    if (response.data && !response.data['kml_simplified']) {
      response.data = deserializer.serialize(response.data);
    }
  });

  api.addRequestTransform(request => {
    if (request.data) {
      request.data = serializer.serialize(request.data);
    }
  });
};

export default api;
