import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

import styles from './styles';

function ImageList({ classes, record = {}, source }) {
  const images = record[source];
  return images.map(image => (
    <>
      <img key={image.id} src={image.file} alt={image.name} className={classes.imageList} />
      <Typography> {image.name} </Typography>
    </>
  ));
}

ImageList.PropTypes = {
  classes: PropTypes.shape({
    borderBottom: PropTypes.string.isRequired
  }),
  source: PropTypes.string.isRequired,
  record: PropTypes.arrayOf(
    PropTypes.shape({
      file: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired
    })
  )
};

export default withStyles(styles)(ImageList);
