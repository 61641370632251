import React from 'react';
import { Create } from 'react-admin';

import EventForm from '../EventForm';

function EventCreate(props) {
  return (
    <Create {...props}>
      <EventForm />
    </Create>
  );
}

export default EventCreate;
