import React from 'react';
import { Admin, Resource } from 'react-admin';

import { i18nProvider } from '~config/i18n';
import authProvider from '~config/providers/authProvider';
import dataProvider from '~config/providers/dataProvider';
import addImageUpload from '~config/providers/addImageUpload';
import ArticleList from '~screens/Articles/ArticleList';
import ArticleCreate from '~screens/Articles/ArticleCreate';
import ArticleEdit from '~screens/Articles/ArticleEdit';
import EventList from '~screens/Events/EventList';
import EventCreate from '~screens/Events/EventCreate';
import EventEdit from '~screens/Events/EventEdit';
import SearchList from '~screens/Searchs/SearchList';
import SearchSummaryList from '~screens/SearchSummary/SearchSummaryList';
import CustomLayout from '~components/CustomLayout';
import ProjectList from '~screens/Projects/ProjectList';
import ProjectCreate from '~screens/Projects/ProjectCreate';
import ProjectEdit from '~screens/Projects/ProjectEdit';
import ProjectShow from '~screens/Projects/ProjectShow';
import IirsaProjectList from '~screens/IirsaProjects/IirsaProjectList';
import IirsaProjectCreate from '~screens/IirsaProjects/IirsaProjectCreate';
import IirsaProjectEdit from '~screens/IirsaProjects/IirsaProjectEdit';
import IirsaProjectShow from '~screens/IirsaProjects/IirsaProjectShow';
import TopicList from '~screens/Topics/TopicList';
import TopicEdit from '~screens/Topics/TopicEdit';
import TopicCreate from '~screens/Topics/TopicCreate';

import '../scss/quillOverrides.scss';
import '../scss/typography.scss';

import styles from './styles.module.scss'; // eslint-disable-line no-unused-vars
import { appTheme } from './styles';
import ArticleShow from './screens/Articles/ArticleShow';
import EventShow from './screens/Events/EventShow';
import Login from './screens/Login';

function App() {
  return (
    <Admin
      theme={appTheme}
      locale="es"
      i18nProvider={i18nProvider}
      dataProvider={addImageUpload(dataProvider)}
      authProvider={authProvider}
      appLayout={CustomLayout}
      loginPage={Login}
    >
      <Resource
        name="articles"
        show={ArticleShow}
        list={ArticleList}
        create={ArticleCreate}
        edit={ArticleEdit}
      />
      <Resource name="events" show={EventShow} list={EventList} create={EventCreate} edit={EventEdit} />
      <Resource name="searchs" list={SearchList} />
      <Resource name="searchsSummary" list={SearchSummaryList} />

      <Resource
        name="projects"
        show={ProjectShow}
        list={ProjectList}
        create={ProjectCreate}
        edit={ProjectEdit}
      />
      <Resource
        name="iirsaProjects"
        show={IirsaProjectShow}
        list={IirsaProjectList}
        create={IirsaProjectCreate}
        edit={IirsaProjectEdit}
      />
      <Resource name="topics" list={TopicList} edit={TopicEdit} create={TopicCreate} />
    </Admin>
  );
}

export default App;
