import React from 'react';
import { List, TextField, translate } from 'react-admin';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import dataProvider, { SEARCHCSV } from '~config/providers/dataProvider';
import { defaultListProps } from '~constants/config';
import { truncate } from '~constants/styles';
import CustomDatagrid from '~components/CustomDatagrid';

import SearchSummaryFilter from '../SearchSummaryFilter';

import { CSV_FILENAME } from './constants';
import { download } from './utils';

const countField = 'count';
const sortConfig = { field: countField, order: 'DESC' };

const exporter = () => dataProvider(SEARCHCSV).then(data => download(CSV_FILENAME, data.data));

function SearchSummaryList({ classes, ...props }) {
  return (
    <List
      {...defaultListProps}
      bulkActions={false}
      exporter={exporter}
      filters={<SearchSummaryFilter />}
      sort={sortConfig}
      {...props}
    >
      <CustomDatagrid rowClick="edit">
        <TextField source="date" />
        <TextField source="searchString" className={classes.truncate} />
        <TextField source={countField} />
      </CustomDatagrid>
    </List>
  );
}

SearchSummaryList.propTypes = {
  classes: PropTypes.shape({
    truncate: PropTypes.string.isRequired
  }).isRequired
};

export default withStyles(truncate)(translate(SearchSummaryList));
