import { GRAY } from '~constants/colors';

const styles = {
  topicLabel: {
    'font-size': '12px',
    'line-height': 1,
    color: GRAY
  },
  chip: {
    margin: '5px'
  }
};

export default styles;
