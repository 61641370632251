import api, { apiSetup } from '../api';

apiSetup();

export class SlugsService {

    BASE_URL = '/backoffice/slugs';

    get_from_article() {
        return this.get_slug('article');
    }

    get_from_project() {
        return this.get_slug('project');
    }

    get_from_event() {
        return this.get_slug('event');
    }

    get_slug(model) {
        let params = '';
        let id = this.get_id(model);
        if (id) {
            params = '?id=' + id;
        }
        return api.get(this.BASE_URL + '/from_' + model + params);
    }

    get_id(model) {
        let split = location.href.split(model+'s/');
        if(split.length > 0 && split[1] != 'create') {
            return split[1]
        }
        return null;
    }

}
